import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ClientSBannerControllerService,
  ResponseModelListSBannerDTO,
  SystemBannerControllerService,
} from '@soctrip/angular-banner-service';
import { environment } from '@env/environment';

import { ActivatedRoute } from '@angular/router';

export const BannerModules = {
  hero_banner: "HOTEL_HERO_BANNER",
  square_banner: "HOTEL_BECOME_AGENCY",
  retangle_banner: "HOTEL_SLIDE_BANNER"
};

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})

export class BannerComponent implements OnInit {
  @Input() code: string;
  @Input() iframeClass: string;
  @Input() imgClass: string;
  @Input() country: string;
  @Input() imageDefault = '';
  @Output() hiddenBanner: EventEmitter<any> = new EventEmitter();
  @Output() getDataBanner:  EventEmitter<any> = new EventEmitter();
  bannerSvgExtention = false;
  advertisementImage = '';
  advertisementImageUrl = '';
  dataBanner: ResponseModelListSBannerDTO;

  private clientBannerService = inject(ClientSBannerControllerService);
  private systemBannerService = inject(SystemBannerControllerService);
  private route = inject(ActivatedRoute);

  ngOnInit(): void {
   this.getBannerInfo()
  }  

  ngOnChanges(changes: SimpleChanges) {
    if (changes['country'] && changes['country'].currentValue) {
      this.getBannerInfo()
    }
  }

  getBannerInfo(){
    this.route.queryParamMap.subscribe((queryParams) => {
      const bannerId = queryParams.get('preview_banner');
      const targetCode = queryParams.get('banner_code');
      if(!this.country) return
    
      if (bannerId && targetCode?.toUpperCase() === this.code.toUpperCase()) {
        this.getPreviewBannerById(bannerId); // get Preview banner by Id
      } else {
        this.getOnGoingBanner(); //get banner on going
      }      
    });
  }

  getOnGoingBanner() {
    this.clientBannerService.getBannerByCode(this.code, this.country).subscribe({
      next: (data) => {
        if (data.success) { 
          this.advertisementImageUrl = data.data && data.data[0]?.url ? data?.data[0]?.url : '';
          const media = data.data && data.data[0]?.medias ? data.data[0].medias[0] : undefined;
          if (media) {
            this.getDataBanner?.emit(data.data)
            if (media.extension === '.svg') {
              this.bannerSvgExtention = true;
              if (media && media.id) {
                this.advertisementImage = `${environment.baseURL}/storage/files/download/${media.id}.svg`;
              }
            } else {
              this.advertisementImage = `${environment.baseURL}/storage/files/web/${media.id}.webp`;
            }
          } else {
            this.advertisementImage = this.imageDefault;
            this.hiddenBanner?.emit(true);
          }
        } else {
          this.advertisementImage = this.imageDefault;
          this.hiddenBanner?.emit(true);
        }
      },
      error: (err) => {
        console.log(err);
        this.advertisementImage = this.imageDefault;
        this.hiddenBanner?.emit(true);
      },
    });
  }

  getPreviewBannerById(bannerID: string) {
    this.systemBannerService.getBannerById(bannerID).subscribe({
      next: (data) => {
        if (data.success) {
          const media = data.data?.medias?.[0];
          if (media && media.extension === '.svg') {
            this.bannerSvgExtention = true;
            if (media.id) {
              this.advertisementImage = `${environment.baseURL}/storage/files/download/${media.id}.svg`;
            }
          } else if (media && media.id) {
            this.advertisementImage = `${environment.baseURL}/storage/files/web/${media.id}.webp`;
          }
        }
      },
      error: (err) => {
        console.log(err);
        this.hiddenBanner?.emit(true);
      },
    });
  }

  replaceWithDefaultImage(event: any): void {
    event.target.src = 'assets/images/home/banner/bannerLandingPage.webp';
  }
  
  openInNewTab(url: string): void {
    if (url) {
      window.location.href = url;
    }
  }
}
