import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bytesConversion'
})
export class BytesConversionPipe implements PipeTransform {
  units = ['B', 'kB', 'MB', 'GB', 'TB'];

  transform(value: number, precision?: any): any {
    return this.convertToBytes(value, this.units, isNaN(precision) ? 1 : precision);
  }

  convertToBytes(bytes: number, unit: string[], precision?: any) {
    if (bytes === undefined || isNaN(bytes) || !isFinite(bytes) || bytes === 0) {
      return '0 MB';
    }

    const number = Math.floor(Math.log(bytes) / Math.log(1024));
    return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) + ' ' + unit[number];
  }
}
