import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-star',
  templateUrl: './star.component.html',
  styleUrls: ['./star.component.scss'],
})
export class StarComponent {
  @Input() count: number;
  @Input() starStyle: string;
  @Input() iconStyle: string;

  // Render hafl star
  getStarCount(star: number): number {
    return Math.floor(star);
  }

  hasHalfStar(star: number): boolean {
    return star % 1 !== 0;
  }
}