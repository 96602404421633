<div class="lg:flex !mt-2 bg-palette-base-white rounded-lg border">
  <div class="!p-6 !pb-0 lg:!w-[31.55%]">
    <span class="font-inter text-lg font-semibold text-palette-gray-900">{{
      "OTA.LOCATION.LOCATION" | translate
      }}</span>
    <p class="font-inter text-sm font-normal text-palette-gray-700 pr-3">
      {{ "OTA.LOCATION.FILL_THE_PROPERTY" | translate }}
    </p>
  </div>
  <div class="!p-6 lg:!w-[68.45%]">
    <span class="font-inter text-sm font-semibold text-palette-gray-700">
      {{ "OTA.LOCATION.PIN_THE_LOCATION" | translate }}
    </span>
    <div id="map" class="mt-2 rounded-lg">
      <app-soctrip-map [centerLat]="38.56964280859044" [centerLng]="-121.48545892147494" [zoom]="15">
        <div body class="disabled-approve ota-location-side-bar w-[274px]" [class.active]="isToggleSearch">
          <div class="absolute left-3 top-3 w-full">
            <span class="p-input-icon-left width-inherit">
              <i class="pi pi-search text-xl leading-none text-palette-gray-700"></i>
              <input class="location-search !h-[44px]" type="text" placeholder="Search" pInputText
                [ngClass]="{'is-disable': otaLocationForm.disabled}"
                [(ngModel)]="searchString" (ngModelChange)="searchModelChanged.next($event)"
                [ngModelOptions]="{ standalone: true }"
              />
            </span>
          </div>
        </div>
      </app-soctrip-map>
    </div>
    <div class="disabled-approve ota-location-form">
      <form [formGroup]="otaLocationForm">
        <div class="ota-custom m-auto gap-4 xl:grid xl:grid-cols-2 !my-3">
          <div class="flex-column my-1 flex gap-2">
            <label htmlFor="city" class="font-inter text-sm font-medium text-palette-gray-700">{{
              "OTA.LOCATION.COUNTRY_REGION" |
              translate }}
            </label>
            <p-dropdown formControlName="country" [options]="countries" [placeholder]="
                'OTA.LOCATION.SELECT_A_COUNTRY_REGION' | translate
              " optionLabel="country_name" (onChange)="handleCountryDropdown($event)" [filter]="true"
              [autoDisplayFirst]="false"></p-dropdown>
          </div>
          <div class="flex-column my-1 flex gap-2">
            <label htmlFor="city" class="font-inter text-sm font-medium text-palette-gray-700">{{
              "OTA.LOCATION.PROVINCE_STATE" |
              translate }}</label>
            <p-dropdown formControlName="province" [options]="cities"
              [placeholder]="'OTA.LOCATION.SELECT_A_PROVINCE_STATE' | translate" optionLabel="city_name"
              (onClick)="getCountries()" (onClick)="validFields()" (onChange)="handleCityDropdown($event)" [filter]="true"></p-dropdown>
          </div>
        </div>
        <div class="m-auto gap-4 xl:grid xl:grid-cols-2 !my-3">
          <div class="flex-column my-1 flex gap-2">
            <label htmlFor="city" class="font-inter text-sm font-medium text-palette-gray-700">{{
              "OTA.LOCATION.CITY_DISTRICT" |
              translate }}</label>
            <p-dropdown formControlName="city" [options]="districts"
              [placeholder]=" 'OTA.LOCATION.SELECT_A_CITY_DISTRICT' | translate" optionLabel="district_name"
              (onClick)="validFields()" (onChange)="handleDistrictDropdown($event)" [filter]="true"></p-dropdown>
          </div>
          <div class="custom-input flex-column my-1 flex gap-2">
            <label htmlFor="zipCode" class="font-inter text-sm font-medium text-palette-gray-700">{{
              "OTA.LOCATION.ZIP_CODE" |
              translate }}</label>
            <input class="hover:!border-palette-blue-300" [maxlength]="64"
              [ngClass]="{'is-disable': otaLocationForm.disabled}"
              placeholder="{{ 'OTA.LOCATION.ENTER_ZIP_CODE' | translate }}" pInputText formControlName="zipCode" />
          </div>
        </div>
        <div class="custom-input flex-column flex gap-2 !my-3">
          <label htmlFor="propertyAddress" class="font-inter text-sm font-medium text-palette-gray-700">{{
            "OTA.LOCATION.PROPERTY_ADDRESS" | translate }}
          </label>
          <input class="hover:!border-palette-blue-300"
            [ngClass]="{'is-disable': otaLocationForm.disabled}"
            placeholder="{{ 'OTA.LOCATION.ENTER_PROPERTY_ADDRESS' | translate }}" pInputText [maxlength]="256"
            formControlName="propertyAddress" />
        </div>
        <div class="flex-column flex gap-2 !my-3">
          <div class="flex flex-col !w-full gap-2 distance-field">
            <label for="distance" class="form-title">{{ "OTA.LOCATION.DISTANCE_TO_CITY_CENTER" | translate }}</label>
            <div class="flex items-center">
              <p-inputNumber class="hover:!border-palette-blue-300 flex-1" formControlName="distance"
                [ngClass]="{'is-disable': otaLocationForm.disabled}"
                [useGrouping]="false" mode="decimal" [maxlength]="10" [minFractionDigits]="1" [maxFractionDigits]="1"
                placeholder="0"></p-inputNumber>
              <p-dropdown [options]="distanceUnitList" optionLabel="name" formControlName="distanceUnit">
              </p-dropdown>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<p-overlayPanel styleClass="mt-0 w-[420px]" [dismissable]="true" #searchLocationOverlay>
  <span *ngIf="isLoading; then loading; else locationsCalled"></span>
  <ng-template #loading>
    <div class="progress-spinner flex w-full justify-center">
      <p-progressSpinner animationDuration="3s" [style]="{ width: '20px', height: '20px' }"></p-progressSpinner>
    </div>
  </ng-template>
  <ng-template #locationsCalled>
    <span *ngIf="locations.length; then hadLocations; else locationEmpty"></span>
    <ng-template #locationEmpty>
      <div class="flex flex-col items-center p-3">
        <i
          class="sctr-icon-search-lg !h-8 !w-8 rounded-[50%] bg-palette-blue-100 text-center text-lg !leading-8 text-palette-blue-600"></i>
        <div class="mt-2 flex justify-center text-base font-semibold text-palette-gray-900">
          {{ "SEARCH_RESULT.NO_RESULTS_FOUND" | translate }}
        </div>
      </div>
    </ng-template>
    <ng-template #hadLocations>
      <div *ngFor="let location of locations"
        class="flex cursor-pointer items-center px-1 py-2 hover:rounded-lg hover:bg-slate-100 hover:opacity-80"
        (click)="handleClickLocation(location)">
        <div class="flex items-center justify-center">
          <i class="sctr-icon-marker-pin-01 rounded-full bg-palette-blue-100 p-2.5 text-palette-blue-600"></i>
        </div>
        <div class="ml-2 truncate">
          <span class="font-inter text-xs font-medium text-palette-gray-900"
            [innerHTML]="getSafeHtml(location.main_text)"></span>
          <p class="truncate font-inter text-xs font-normal text-palette-gray-700">
            {{ location.addr }}
          </p>
        </div>
      </div>
    </ng-template>
  </ng-template>
</p-overlayPanel>