import { ApplicationRef, ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { MapMarkerPopupComponent } from '@modules/hotel-booking/components/search/map-marker-popup/map-marker-popup.component';
import { DetailHotel } from '@modules/hotel-booking/models/view-details';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MapSearchService {
  private _marker = new BehaviorSubject<DetailHotel>({} as any);
  public marker$ = this._marker.asObservable();

  constructor(
    private injector: Injector,
    private applicationRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) { }

  setMarker(data: any) {
    this._marker.next(data)
  }

  getMarker() {
    return this.marker$;
  }

  returnPopUpHTML(popUpComponent: any): HTMLElement {
    const popup = document.createElement('div');
    const factory =
      this.componentFactoryResolver.resolveComponentFactory(MapMarkerPopupComponent);
    const popupComponentRef = factory.create(this.injector, [], popup);
    // Attach to the view so that the change detector knows to run
    this.applicationRef.attachView(popupComponentRef.hostView);
    return popup;
  }
}
