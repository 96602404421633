import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  private _propertyHeader: ReplaySubject<IPropertyHeader> = new ReplaySubject();
  private propertyHeader$: Observable<IPropertyHeader> = this._propertyHeader.asObservable();
  constructor() { }
  setPropertyHeader(data: IPropertyHeader) {
    this._propertyHeader.next(data);
  }
  getPropertyHeader() {
    return this.propertyHeader$;
  }
}

interface IPropertyHeader {
  isShow?: boolean,
  isFixed?: boolean,
  isChangeColor?: boolean,
  isWhite?: boolean,
}