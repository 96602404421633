import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent {
  @Input() item: string | number
  @Input() type?: string

  constructor() {
    if(typeof(this.item) === 'number') {
      this.item = '+' + this.item;
    }
  }
}