import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root',
  })
  export class ReservationSearchService {

    key = 'reservationSearchResult';

    setItemStorage(value: any): void {
      localStorage.setItem(this.key, JSON.stringify(value));
    }

    getItemStorage(): any {
      const storedData = localStorage.getItem(this.key);
      return storedData ? JSON.parse(storedData) : null;
    }
  }