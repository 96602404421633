import { Component, EventEmitter, Output } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'popup-become-agency',
  templateUrl: './popup-become-agency.component.html',
  styleUrls: ['./popup-become-agency.component.scss'],
})
export class PopupBecomeAgencyComponent {
  @Output() setShowPopup = new EventEmitter();

  isLogin: boolean | null = false;
  loginSubscription: Subscription;
  pipe = new DatePipe('en-US');
  
  constructor(private router: Router) {}

  closePopup(): void {
    this.setShowPopup.emit(false);
    document.body.style.overflow = 'auto';
  }

  stopPropagationEvent(event: Event): void {
    event.stopPropagation();
  }

  onSignup() {
    this.setShowPopup.emit(false);
    this.router.navigate(['/ota/become-an-agency']);
  }
}
