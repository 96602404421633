export enum SoctripIcons {
  ALERT_CIRCLE = 'sctr-icon-alert-circle',
  ALERT_HEXAGON = 'sctr-icon-alert-hexagon',
  ALERT_OCTAGON = 'sctr-icon-alert-octagon',
  ALERT_SQUARE = 'sctr-icon-alert-square',
  ALERT_TRIANGLE = 'sctr-icon-alert-triangle',
  ANNOUNCEMENT_01 = 'sctr-icon-announcement-01',
  ANNOUNCEMENT_02 = 'sctr-icon-announcement-02',
  ANNOUNCEMENT_03 = 'sctr-icon-announcement-03',
  BELL_01 = 'sctr-icon-bell-01',
  BELL_02 = 'sctr-icon-bell-02',
  BELL_03 = 'sctr-icon-bell-03',
  BELL_04 = 'sctr-icon-bell-04',
  BELL_MINUS = 'sctr-icon-bell-minus',
  BELL_OFF_01 = 'sctr-icon-bell-off-01',
  BELL_OFF_02 = 'sctr-icon-bell-off-02',
  BELL_OFF_03 = 'sctr-icon-bell-off-03',
  BELL_PLUS = 'sctr-icon-bell-plus',
  BELL_RINGING_01 = 'sctr-icon-bell-ringing-01',
  BELL_RINGING_02 = 'sctr-icon-bell-ringing-02',
  BELL_RINGING_03 = 'sctr-icon-bell-ringing-03',
  BELL_RINGING_04 = 'sctr-icon-bell-ringing-04',
  NOTIFICATION_BOX = 'sctr-icon-notification-box',
  NOTIFICATION_MESSAGE = 'sctr-icon-notification-message',
  NOTIFICATION_TEXT = 'sctr-icon-notification-text',
  THUMBS_DOWN = 'sctr-icon-thumbs-down',
  THUMBS_UP = 'sctr-icon-thumbs-up',
  ARROW_BLOCK_DOWN = 'sctr-icon-arrow-block-down',
  ARROW_BLOCK_LEFT = 'sctr-icon-arrow-block-left',
  ARROW_BLOCK_RIGHT = 'sctr-icon-arrow-block-right',
  ARROW_BLOCK_UP = 'sctr-icon-arrow-block-up',
  ARROW_CIRCLE_BROKEN_DOWN = 'sctr-icon-arrow-circle-broken-down',
  ARROW_CIRCLE_BROKEN_DOWN_LEFT = 'sctr-icon-arrow-circle-broken-down-left',
  ARROW_CIRCLE_BROKEN_DOWN_RIGHT = 'sctr-icon-arrow-circle-broken-down-right',
  ARROW_CIRCLE_BROKEN_LEFT = 'sctr-icon-arrow-circle-broken-left',
  ARROW_CIRCLE_BROKEN_RIGHT = 'sctr-icon-arrow-circle-broken-right',
  ARROW_CIRCLE_BROKEN_UP = 'sctr-icon-arrow-circle-broken-up',
  ARROW_CIRCLE_BROKEN_UP_LEFT = 'sctr-icon-arrow-circle-broken-up-left',
  ARROW_CIRCLE_BROKEN_UP_RIGHT = 'sctr-icon-arrow-circle-broken-up-right',
  ARROW_CIRCLE_DOWN = 'sctr-icon-arrow-circle-down',
  ARROW_CIRCLE_DOWN_LEFT = 'sctr-icon-arrow-circle-down-left',
  ARROW_CIRCLE_DOWN_RIGHT = 'sctr-icon-arrow-circle-down-right',
  ARROW_CIRCLE_LEFT = 'sctr-icon-arrow-circle-left',
  ARROW_CIRCLE_RIGHT = 'sctr-icon-arrow-circle-right',
  ARROW_CIRCLE_UP = 'sctr-icon-arrow-circle-up',
  ARROW_CIRCLE_UP_LEFT = 'sctr-icon-arrow-circle-up-left',
  ARROW_CIRCLE_UP_RIGHT = 'sctr-icon-arrow-circle-up-right',
  ARROW_DOWN = 'sctr-icon-arrow-down',
  ARROW_DOWN_LEFT = 'sctr-icon-arrow-down-left',
  ARROW_DOWN_RIGHT = 'sctr-icon-arrow-down-right',
  ARROW_LEFT = 'sctr-icon-arrow-left',
  ARROW_NARROW_DOWN = 'sctr-icon-arrow-narrow-down',
  ARROW_NARROW_DOWN_LEFT = 'sctr-icon-arrow-narrow-down-left',
  ARROW_NARROW_DOWN_RIGHT = 'sctr-icon-arrow-narrow-down-right',
  ARROW_NARROW_LEFT = 'sctr-icon-arrow-narrow-left',
  ARROW_NARROW_RIGHT = 'sctr-icon-arrow-narrow-right',
  ARROW_NARROW_UP = 'sctr-icon-arrow-narrow-up',
  ARROW_NARROW_UP_LEFT = 'sctr-icon-arrow-narrow-up-left',
  ARROW_NARROW_UP_RIGHT = 'sctr-icon-arrow-narrow-up-right',
  ARROW_RIGHT = 'sctr-icon-arrow-right',
  ARROW_SQUARE_DOWN = 'sctr-icon-arrow-square-down',
  ARROW_SQUARE_DOWN_LEFT = 'sctr-icon-arrow-square-down-left',
  ARROW_SQUARE_DOWN_RIGHT = 'sctr-icon-arrow-square-down-right',
  ARROW_SQUARE_LEFT = 'sctr-icon-arrow-square-left',
  ARROW_SQUARE_RIGHT = 'sctr-icon-arrow-square-right',
  ARROW_SQUARE_UP = 'sctr-icon-arrow-square-up',
  ARROW_SQUARE_UP_LEFT = 'sctr-icon-arrow-square-up-left',
  ARROW_SQUARE_UP_RIGHT = 'sctr-icon-arrow-square-up-right',
  ARROW_UP = 'sctr-icon-arrow-up',
  ARROW_UP_LEFT = 'sctr-icon-arrow-up-left',
  ARROW_UP_RIGHT = 'sctr-icon-arrow-up-right',
  ARROWS_DOWN = 'sctr-icon-arrows-down',
  ARROWS_LEFT = 'sctr-icon-arrows-left',
  ARROWS_RIGHT = 'sctr-icon-arrows-right',
  ARROWS_TRIANGLE = 'sctr-icon-arrows-triangle',
  ARROWS_UP = 'sctr-icon-arrows-up',
  CHEVRON_DOWN = 'sctr-icon-chevron-down',
  CHEVRON_DOWN_DOUBLE = 'sctr-icon-chevron-down-double',
  CHEVRON_LEFT = 'sctr-icon-chevron-left',
  CHEVRON_LEFT_DOUBLE = 'sctr-icon-chevron-left-double',
  CHEVRON_RIGHT = 'sctr-icon-chevron-right',
  CHEVRON_RIGHT_DOUBLE = 'sctr-icon-chevron-right-double',
  CHEVRON_SELECTOR_HORIZONTAL = 'sctr-icon-chevron-selector-horizontal',
  CHEVRON_SELECTOR_VERTICAL = 'sctr-icon-chevron-selector-vertical',
  CHEVRON_UP = 'sctr-icon-chevron-up',
  CHEVRON_UP_DOUBLE = 'sctr-icon-chevron-up-double',
  CORNER_DOWN_LEFT = 'sctr-icon-corner-down-left',
  CORNER_DOWN_RIGHT = 'sctr-icon-corner-down-right',
  CORNER_LEFT_DOWN = 'sctr-icon-corner-left-down',
  CORNER_LEFT_UP = 'sctr-icon-corner-left-up',
  CORNER_RIGHT_DOWN = 'sctr-icon-corner-right-down',
  CORNER_RIGHT_UP = 'sctr-icon-corner-right-up',
  CORNER_UP_LEFT = 'sctr-icon-corner-up-left',
  CORNER_UP_RIGHT = 'sctr-icon-corner-up-right',
  EXPAND_01 = 'sctr-icon-expand-01',
  EXPAND_02 = 'sctr-icon-expand-02',
  EXPAND_03 = 'sctr-icon-expand-03',
  EXPAND_04 = 'sctr-icon-expand-04',
  EXPAND_05 = 'sctr-icon-expand-05',
  EXPAND_06 = 'sctr-icon-expand-06',
  FLIP_BACKWARD = 'sctr-icon-flip-backward',
  FLIP_FORWARD = 'sctr-icon-flip-forward',
  INFINITY = 'sctr-icon-infinity',
  REFRESH_CCW_01 = 'sctr-icon-refresh-ccw-01',
  REFRESH_CCW_02 = 'sctr-icon-refresh-ccw-02',
  REFRESH_CCW_03 = 'sctr-icon-refresh-ccw-03',
  REFRESH_CCW_04 = 'sctr-icon-refresh-ccw-04',
  REFRESH_CCW_05 = 'sctr-icon-refresh-ccw-05',
  REFRESH_CW_01 = 'sctr-icon-refresh-cw-01',
  REFRESH_CW_02 = 'sctr-icon-refresh-cw-02',
  REFRESH_CW_03 = 'sctr-icon-refresh-cw-03',
  REFRESH_CW_04 = 'sctr-icon-refresh-cw-04',
  REFRESH_CW_05 = 'sctr-icon-refresh-cw-05',
  REVERSE_LEFT = 'sctr-icon-reverse-left',
  REVERSE_RIGHT = 'sctr-icon-reverse-right',
  SWITCH_HORIZONTAL_01 = 'sctr-icon-switch-horizontal-01',
  SWITCH_HORIZONTAL_02 = 'sctr-icon-switch-horizontal-02',
  SWITCH_VERTICAL_01 = 'sctr-icon-switch-vertical-01',
  SWITCH_VERTICAL_02 = 'sctr-icon-switch-vertical-02',
  BAR_CHART_01 = 'sctr-icon-bar-chart-01',
  BAR_CHART_02 = 'sctr-icon-bar-chart-02',
  BAR_CHART_03 = 'sctr-icon-bar-chart-03',
  BAR_CHART_04 = 'sctr-icon-bar-chart-04',
  BAR_CHART_05 = 'sctr-icon-bar-chart-05',
  BAR_CHART_06 = 'sctr-icon-bar-chart-06',
  BAR_CHART_07 = 'sctr-icon-bar-chart-07',
  BAR_CHART_08 = 'sctr-icon-bar-chart-08',
  BAR_CHART_09 = 'sctr-icon-bar-chart-09',
  BAR_CHART_10 = 'sctr-icon-bar-chart-10',
  BAR_CHART_11 = 'sctr-icon-bar-chart-11',
  BAR_CHART_12 = 'sctr-icon-bar-chart-12',
  BAR_CHART_CIRCLE_01 = 'sctr-icon-bar-chart-circle-01',
  BAR_CHART_CIRCLE_02 = 'sctr-icon-bar-chart-circle-02',
  BAR_CHART_CIRCLE_03 = 'sctr-icon-bar-chart-circle-03',
  BAR_CHART_SQUARE_01 = 'sctr-icon-bar-chart-square-01',
  BAR_CHART_SQUARE_02 = 'sctr-icon-bar-chart-square-02',
  BAR_CHART_SQUARE_03 = 'sctr-icon-bar-chart-square-03',
  BAR_CHART_SQUARE_DOWN = 'sctr-icon-bar-chart-square-down',
  BAR_CHART_SQUARE_MINUS = 'sctr-icon-bar-chart-square-minus',
  BAR_CHART_SQUARE_PLUS = 'sctr-icon-bar-chart-square-plus',
  BAR_CHART_SQUARE_UP = 'sctr-icon-bar-chart-square-up',
  BAR_LINE_CHART = 'sctr-icon-bar-line-chart',
  CHART_BREAKOUT_CIRCLE = 'sctr-icon-chart-breakout-circle',
  CHART_BREAKOUT_SQUARE = 'sctr-icon-chart-breakout-square',
  HORIZONTAL_BAR_CHART_01 = 'sctr-icon-horizontal-bar-chart-01',
  HORIZONTAL_BAR_CHART_02 = 'sctr-icon-horizontal-bar-chart-02',
  HORIZONTAL_BAR_CHART_03 = 'sctr-icon-horizontal-bar-chart-03',
  LINE_CHART_DOWN_01 = 'sctr-icon-line-chart-down-01',
  LINE_CHART_DOWN_02 = 'sctr-icon-line-chart-down-02',
  LINE_CHART_DOWN_03 = 'sctr-icon-line-chart-down-03',
  LINE_CHART_DOWN_04 = 'sctr-icon-line-chart-down-04',
  LINE_CHART_DOWN_05 = 'sctr-icon-line-chart-down-05',
  LINE_CHART_UP_01 = 'sctr-icon-line-chart-up-01',
  LINE_CHART_UP_02 = 'sctr-icon-line-chart-up-02',
  LINE_CHART_UP_03 = 'sctr-icon-line-chart-up-03',
  LINE_CHART_UP_04 = 'sctr-icon-line-chart-up-04',
  LINE_CHART_UP_05 = 'sctr-icon-line-chart-up-05',
  PIE_CHART_01 = 'sctr-icon-pie-chart-01',
  PIE_CHART_02 = 'sctr-icon-pie-chart-02',
  PIE_CHART_03 = 'sctr-icon-pie-chart-03',
  PIE_CHART_04 = 'sctr-icon-pie-chart-04',
  PRESENTATION_CHART_01 = 'sctr-icon-presentation-chart-01',
  PRESENTATION_CHART_02 = 'sctr-icon-presentation-chart-02',
  PRESENTATION_CHART_03 = 'sctr-icon-presentation-chart-03',
  TREND_DOWN_01 = 'sctr-icon-trend-down-01',
  TREND_DOWN_02 = 'sctr-icon-trend-down-02',
  TREND_UP_01 = 'sctr-icon-trend-up-01',
  TREND_UP_02 = 'sctr-icon-trend-up-02',
  ANNOTATION = 'sctr-icon-annotation',
  ANNOTATION_ALERT = 'sctr-icon-annotation-alert',
  ANNOTATION_CHECK = 'sctr-icon-annotation-check',
  ANNOTATION_DOTS = 'sctr-icon-annotation-dots',
  ANNOTATION_HEART = 'sctr-icon-annotation-heart',
  ANNOTATION_INFO = 'sctr-icon-annotation-info',
  ANNOTATION_PLUS = 'sctr-icon-annotation-plus',
  ANNOTATION_QUESTION = 'sctr-icon-annotation-question',
  ANNOTATION_X = 'sctr-icon-annotation-x',
  INBOX_01 = 'sctr-icon-inbox-01',
  INBOX_02 = 'sctr-icon-inbox-02',
  MAIL_01 = 'sctr-icon-mail-01',
  MAIL_02 = 'sctr-icon-mail-02',
  MAIL_03 = 'sctr-icon-mail-03',
  MAIL_04 = 'sctr-icon-mail-04',
  MAIL_05 = 'sctr-icon-mail-05',
  MESSAGE_ALERT_CIRCLE = 'sctr-icon-message-alert-circle',
  MESSAGE_ALERT_SQUARE = 'sctr-icon-message-alert-square',
  MESSAGE_CHAT_CIRCLE = 'sctr-icon-message-chat-circle',
  MESSAGE_CHAT_SQUARE = 'sctr-icon-message-chat-square',
  MESSAGE_CHECK_CIRCLE = 'sctr-icon-message-check-circle',
  MESSAGE_CHECK_SQUARE = 'sctr-icon-message-check-square',
  MESSAGE_CIRCLE_01 = 'sctr-icon-message-circle-01',
  MESSAGE_CIRCLE_02 = 'sctr-icon-message-circle-02',
  MESSAGE_DOTS_CIRCLE = 'sctr-icon-message-dots-circle',
  MESSAGE_DOTS_SQUARE = 'sctr-icon-message-dots-square',
  MESSAGE_HEART_CIRCLE = 'sctr-icon-message-heart-circle',
  MESSAGE_HEART_SQUARE = 'sctr-icon-message-heart-square',
  MESSAGE_NOTIFICATION_CIRCLE = 'sctr-icon-message-notification-circle',
  MESSAGE_NOTIFICATION_SQUARE = 'sctr-icon-message-notification-square',
  MESSAGE_PLUS_CIRCLE = 'sctr-icon-message-plus-circle',
  MESSAGE_PLUS_SQUARE = 'sctr-icon-message-plus-square',
  MESSAGE_QUESTION_CIRCLE = 'sctr-icon-message-question-circle',
  MESSAGE_QUESTION_SQUARE = 'sctr-icon-message-question-square',
  MESSAGE_SMILE_CIRCLE = 'sctr-icon-message-smile-circle',
  MESSAGE_SMILE_SQUARE = 'sctr-icon-message-smile-square',
  MESSAGE_SQUARE_01 = 'sctr-icon-message-square-01',
  MESSAGE_SQUARE_02 = 'sctr-icon-message-square-02',
  MESSAGE_TEXT_CIRCLE_01 = 'sctr-icon-message-text-circle-01',
  MESSAGE_TEXT_CIRCLE_02 = 'sctr-icon-message-text-circle-02',
  MESSAGE_TEXT_SQUARE_01 = 'sctr-icon-message-text-square-01',
  MESSAGE_TEXT_SQUARE_02 = 'sctr-icon-message-text-square-02',
  MESSAGE_X_CIRCLE = 'sctr-icon-message-x-circle',
  MESSAGE_X_SQUARE = 'sctr-icon-message-x-square',
  PHONE = 'sctr-icon-phone',
  PHONE_CALL_01 = 'sctr-icon-phone-call-01',
  PHONE_CALL_02 = 'sctr-icon-phone-call-02',
  PHONE_HANG_UP = 'sctr-icon-phone-hang-up',
  PHONE_INCOMING_01 = 'sctr-icon-phone-incoming-01',
  PHONE_INCOMING_02 = 'sctr-icon-phone-incoming-02',
  PHONE_OUTGOING_01 = 'sctr-icon-phone-outgoing-01',
  PHONE_OUTGOING_02 = 'sctr-icon-phone-outgoing-02',
  PHONE_PAUSE = 'sctr-icon-phone-pause',
  PHONE_PLUS = 'sctr-icon-phone-plus',
  PHONE_X = 'sctr-icon-phone-x',
  SEND_01 = 'sctr-icon-send-01',
  SEND_02 = 'sctr-icon-send-02',
  SEND_03 = 'sctr-icon-send-03',
  BRACKETS = 'sctr-icon-brackets',
  BRACKETS_CHECK = 'sctr-icon-brackets-check',
  BRACKETS_ELLIPSES = 'sctr-icon-brackets-ellipses',
  BRACKETS_MINUS = 'sctr-icon-brackets-minus',
  BRACKETS_PLUS = 'sctr-icon-brackets-plus',
  BRACKETS_SLASH = 'sctr-icon-brackets-slash',
  BRACKETS_X = 'sctr-icon-brackets-x',
  BROWSER = 'sctr-icon-browser',
  CODE_01 = 'sctr-icon-code-01',
  CODE_02 = 'sctr-icon-code-02',
  CODE_BROWSER = 'sctr-icon-code-browser',
  CODE_CIRCLE_01 = 'sctr-icon-code-circle-01',
  CODE_CIRCLE_02 = 'sctr-icon-code-circle-02',
  CODE_CIRCLE_03 = 'sctr-icon-code-circle-03',
  CODE_SQUARE_01 = 'sctr-icon-code-square-01',
  CODE_SQUARE_02 = 'sctr-icon-code-square-02',
  CODEPEN = 'sctr-icon-codepen',
  CONTAINER = 'sctr-icon-container',
  CPU_CHIP_01 = 'sctr-icon-cpu-chip-01',
  CPU_CHIP_02 = 'sctr-icon-cpu-chip-02',
  DATA = 'sctr-icon-data',
  DATABASE_01 = 'sctr-icon-database-01',
  DATABASE_02 = 'sctr-icon-database-02',
  DATABASE_03 = 'sctr-icon-database-03',
  DATAFLOW_01 = 'sctr-icon-dataflow-01',
  DATAFLOW_02 = 'sctr-icon-dataflow-02',
  DATAFLOW_03 = 'sctr-icon-dataflow-03',
  DATAFLOW_04 = 'sctr-icon-dataflow-04',
  FILE_CODE_01 = 'sctr-icon-file-code-01',
  FILE_CODE_02 = 'sctr-icon-file-code-02',
  FOLDER_CODE = 'sctr-icon-folder-code',
  GIT_BRANCH_01 = 'sctr-icon-git-branch-01',
  GIT_BRANCH_02 = 'sctr-icon-git-branch-02',
  GIT_COMMIT = 'sctr-icon-git-commit',
  GIT_MERGE = 'sctr-icon-git-merge',
  GIT_PULL_REQUEST = 'sctr-icon-git-pull-request',
  PACKAGE = 'sctr-icon-package',
  PACKAGE_MINUS = 'sctr-icon-package-minus',
  PACKAGE_CHECK = 'sctr-icon-package-check',
  PACKAGE_PLUS = 'sctr-icon-package-plus',
  PACKAGE_SEARCH = 'sctr-icon-package-search',
  PACKAGE_X = 'sctr-icon-package-x',
  PUZZLE_PIECE_01 = 'sctr-icon-puzzle-piece-01',
  PUZZLE_PIECE_02 = 'sctr-icon-puzzle-piece-02',
  QR_CODE_01 = 'sctr-icon-qr-code-01',
  QR_CODE_02 = 'sctr-icon-qr-code-02',
  SERVER_01 = 'sctr-icon-server-01',
  SERVER_02 = 'sctr-icon-server-02',
  SERVER_03 = 'sctr-icon-server-03',
  SERVER_04 = 'sctr-icon-server-04',
  SERVER_05 = 'sctr-icon-server-05',
  SERVER_06 = 'sctr-icon-server-06',
  TERMINAL = 'sctr-icon-terminal',
  TERMINAL_BROWSER = 'sctr-icon-terminal-browser',
  TERMINAL_CIRCLE = 'sctr-icon-terminal-circle',
  TERMINAL_SQUARE = 'sctr-icon-terminal-square',
  VARIABLE = 'sctr-icon-variable',
  ALIGN_CENTER = 'sctr-icon-align-center',
  ALIGN_JUSTIFY = 'sctr-icon-align-justify',
  ALIGN_LEFT = 'sctr-icon-align-left',
  ALIGN_RIGHT = 'sctr-icon-align-right',
  ATTACHMENT_01 = 'sctr-icon-attachment-01',
  ATTACHMENT_02 = 'sctr-icon-attachment-02',
  BEZIER_CURVE_01 = 'sctr-icon-bezier-curve-01',
  BEZIER_CURVE_02 = 'sctr-icon-bezier-curve-02',
  BOLD_01 = 'sctr-icon-bold-01',
  BOLD_02 = 'sctr-icon-bold-02',
  BOLD_SQUARE = 'sctr-icon-bold-square',
  BRUSH_01 = 'sctr-icon-brush-01',
  BRUSH_02 = 'sctr-icon-brush-02',
  BRUSH_03 = 'sctr-icon-brush-03',
  CIRCLE_CUT = 'sctr-icon-circle-cut',
  CODE_SNIPPET_01 = 'sctr-icon-code-snippet-01',
  CODE_SNIPPET_02 = 'sctr-icon-code-snippet-02',
  COLORS = 'sctr-icon-colors',
  COMMAND = 'sctr-icon-command',
  CONTRAST_01 = 'sctr-icon-contrast-01',
  CONTRAST_02 = 'sctr-icon-contrast-02',
  CONTRAST_03 = 'sctr-icon-contrast-03',
  CROP_01 = 'sctr-icon-crop-01',
  CURSOR_01 = 'sctr-icon-cursor-01',
  CURSOR_02 = 'sctr-icon-cursor-02',
  CURSOR_03 = 'sctr-icon-cursor-03',
  CURSOR_04 = 'sctr-icon-cursor-04',
  CURSOR_BOX = 'sctr-icon-cursor-box',
  CURSOR_CLICK_01 = 'sctr-icon-cursor-click-01',
  CURSOR_CLICK_02 = 'sctr-icon-cursor-click-02',
  DELETE = 'sctr-icon-delete',
  DOTPOINTS_01 = 'sctr-icon-dotpoints-01',
  DOTPOINTS_02 = 'sctr-icon-dotpoints-02',
  DROP = 'sctr-icon-drop',
  DROPPER = 'sctr-icon-dropper',
  ERASER = 'sctr-icon-eraser',
  FEATHER = 'sctr-icon-feather',
  FIGMA = 'sctr-icon-figma',
  FRAMER = 'sctr-icon-framer',
  HAND = 'sctr-icon-hand',
  HEADING_01 = 'sctr-icon-heading-01',
  HEADING_02 = 'sctr-icon-heading-02',
  HEADING_SQUARE = 'sctr-icon-heading-square',
  IMAGE_INDENT_LEFT = 'sctr-icon-image-indent-left',
  IMAGE_INDENT_RIGHT = 'sctr-icon-image-indent-right',
  ITALIC_01 = 'sctr-icon-italic-01',
  ITALIC_02 = 'sctr-icon-italic-02',
  ITALIC_SQUARE = 'sctr-icon-italic-square',
  LEFT_INDENT_01 = 'sctr-icon-left-indent-01',
  LEFT_INDENT_02 = 'sctr-icon-left-indent-02',
  LETTER_SPACING_02 = 'sctr-icon-letter-spacing-02',
  LINE_HEIGHT = 'sctr-icon-line-height',
  MAGIC_WAND_01 = 'sctr-icon-magic-wand-01',
  MAGIC_WAND_02 = 'sctr-icon-magic-wand-02',
  MOVE = 'sctr-icon-move',
  PAINT = 'sctr-icon-paint',
  PAINT_POUR = 'sctr-icon-paint-pour',
  PALETTE = 'sctr-icon-palette',
  PARAGRAPH_SPACING = 'sctr-icon-paragraph-spacing',
  PARAGRAPH_WRAP = 'sctr-icon-paragraph-wrap',
  PEN_TOOL_01 = 'sctr-icon-pen-tool-01',
  PEN_TOOL_02 = 'sctr-icon-pen-tool-02',
  PEN_TOOL_MINUS = 'sctr-icon-pen-tool-minus',
  PEN_TOOL_PLUS = 'sctr-icon-pen-tool-plus',
  PENCIL_01 = 'sctr-icon-pencil-01',
  PENCIL_02 = 'sctr-icon-pencil-02',
  PENCIL_LINE = 'sctr-icon-pencil-line',
  PERSPECTIVE_01 = 'sctr-icon-perspective-01',
  PERSPECTIVE_02 = 'sctr-icon-perspective-02',
  PILCROW_01 = 'sctr-icon-pilcrow-01',
  PILCROW_02 = 'sctr-icon-pilcrow-02',
  PILCROW_SQUARE = 'sctr-icon-pilcrow-square',
  REFLECT_01 = 'sctr-icon-reflect-01',
  REFLECT_02 = 'sctr-icon-reflect-02',
  RIGHT_INDENT_01 = 'sctr-icon-right-indent-01',
  RIGHT_INDENT_02 = 'sctr-icon-right-indent-02',
  ROLLER_BRUSH = 'sctr-icon-roller-brush',
  SCALE_01 = 'sctr-icon-scale-01',
  SCALE_02 = 'sctr-icon-scale-02',
  SCALE_03 = 'sctr-icon-scale-03',
  SCISSORS_01 = 'sctr-icon-scissors-01',
  SCISSORS_02 = 'sctr-icon-scissors-02',
  SCISSORS_CUT_01 = 'sctr-icon-scissors-cut-01',
  SCISSORS_CUT_02 = 'sctr-icon-scissors-cut-02',
  SKEW = 'sctr-icon-skew',
  STRIKETHROUGH_01 = 'sctr-icon-strikethrough-01',
  STRIKETHROUGH_02 = 'sctr-icon-strikethrough-02',
  STRIKETHROUGH_SQUARE = 'sctr-icon-strikethrough-square',
  SUBSCRIPT = 'sctr-icon-subscript',
  TEXT_INPUT = 'sctr-icon-text-input',
  TRANSFORM = 'sctr-icon-transform',
  TYPE_01 = 'sctr-icon-type-01',
  TYPE_02 = 'sctr-icon-type-02',
  TYPE_SQUARE = 'sctr-icon-type-square',
  TYPE_STRIKETHROUGH_01 = 'sctr-icon-type-strikethrough-01',
  TYPE_STRIKETHROUGH_02 = 'sctr-icon-type-strikethrough-02',
  UNDERLINE_01 = 'sctr-icon-underline-01',
  UNDERLINE_02 = 'sctr-icon-underline-02',
  UNDERLINE_SQUARE = 'sctr-icon-underline-square',
  ZOOM_IN = 'sctr-icon-zoom-in',
  ZOOM_OUT = 'sctr-icon-zoom-out',
  LETTER_SPACING_01 = 'sctr-icon-letter-spacing-01',
  CROP_02 = 'sctr-icon-crop-02',
  BEZIER_CURVE_03 = 'sctr-icon-bezier-curve-03',
  ATOM_01 = 'sctr-icon-atom-01',
  ATOM_02 = 'sctr-icon-atom-02',
  AWARD_01 = 'sctr-icon-award-01',
  AWARD_02 = 'sctr-icon-award-02',
  AWARD_03 = 'sctr-icon-award-03',
  AWARD_04 = 'sctr-icon-award-04',
  AWARD_05 = 'sctr-icon-award-05',
  BACKPACK = 'sctr-icon-backpack',
  BEAKER_01 = 'sctr-icon-beaker-01',
  BEAKER_02 = 'sctr-icon-beaker-02',
  BOOK_CLOSED = 'sctr-icon-book-closed',
  BOOK_OPEN_01 = 'sctr-icon-book-open-01',
  BOOK_OPEN_02 = 'sctr-icon-book-open-02',
  BRIEFCASE_01 = 'sctr-icon-briefcase-01',
  BRIEFCASE_02 = 'sctr-icon-briefcase-02',
  CALCULATOR = 'sctr-icon-calculator',
  CERTIFICATE_01 = 'sctr-icon-certificate-01',
  CERTIFICATE_02 = 'sctr-icon-certificate-02',
  COMPASS = 'sctr-icon-compass',
  GLASSES_01 = 'sctr-icon-glasses-01',
  GLASSES_02 = 'sctr-icon-glasses-02',
  GLOBE_01 = 'sctr-icon-globe-01',
  GLOBE_02 = 'sctr-icon-globe-02',
  GRADUATION_HAT_01 = 'sctr-icon-graduation-hat-01',
  GRADUATION_HAT_02 = 'sctr-icon-graduation-hat-02',
  MICROSCOPE = 'sctr-icon-microscope',
  RULER = 'sctr-icon-ruler',
  STAND = 'sctr-icon-stand',
  TELESCOPE = 'sctr-icon-telescope',
  TROPHY_01 = 'sctr-icon-trophy-01',
  TROPHY_02 = 'sctr-icon-trophy-02',
  FILE_01 = 'sctr-icon-file-01',
  FILE_02 = 'sctr-icon-file-02',
  FILE_03 = 'sctr-icon-file-03',
  FILE_04 = 'sctr-icon-file-04',
  FILE_05 = 'sctr-icon-file-05',
  FILE_06 = 'sctr-icon-file-06',
  FILE_07 = 'sctr-icon-file-07',
  FILE_ATTACHMENT_01 = 'sctr-icon-file-attachment-01',
  FILE_ATTACHMENT_02 = 'sctr-icon-file-attachment-02',
  FILE_ATTACHMENT_03 = 'sctr-icon-file-attachment-03',
  FILE_ATTACHMENT_04 = 'sctr-icon-file-attachment-04',
  FILE_ATTACHMENT_05 = 'sctr-icon-file-attachment-05',
  FILE_CHECK_01 = 'sctr-icon-file-check-01',
  FILE_CHECK_02 = 'sctr-icon-file-check-02',
  FILE_CHECK_03 = 'sctr-icon-file-check-03',
  FILE_DOWNLOAD_01 = 'sctr-icon-file-download-01',
  FILE_DOWNLOAD_02 = 'sctr-icon-file-download-02',
  FILE_DOWNLOAD_03 = 'sctr-icon-file-download-03',
  FILE_HEART_01 = 'sctr-icon-file-heart-01',
  FILE_HEART_02 = 'sctr-icon-file-heart-02',
  FILE_HEART_03 = 'sctr-icon-file-heart-03',
  FILE_MINUS_01 = 'sctr-icon-file-minus-01',
  FILE_MINUS_02 = 'sctr-icon-file-minus-02',
  FILE_MINUS_03 = 'sctr-icon-file-minus-03',
  FILE_PLUS_01 = 'sctr-icon-file-plus-01',
  FILE_PLUS_02 = 'sctr-icon-file-plus-02',
  FILE_PLUS_03 = 'sctr-icon-file-plus-03',
  FILE_QUESTION_01 = 'sctr-icon-file-question-01',
  FILE_QUESTION_02 = 'sctr-icon-file-question-02',
  FILE_QUESTION_03 = 'sctr-icon-file-question-03',
  FILE_X_01 = 'sctr-icon-file-x-01',
  FILE_X_02 = 'sctr-icon-file-x-02',
  FILE_X_03 = 'sctr-icon-file-x-03',
  FILE_SEARCH_01 = 'sctr-icon-file-search-01',
  FILE_SEARCH_02 = 'sctr-icon-file-search-02',
  FILE_SEARCH_03 = 'sctr-icon-file-search-03',
  FOLDER = 'sctr-icon-folder',
  FOLDER_CHECK = 'sctr-icon-folder-check',
  FOLDER_DOWNLOAD = 'sctr-icon-folder-download',
  FOLDER_LOCK = 'sctr-icon-folder-lock',
  FOLDER_MINUS = 'sctr-icon-folder-minus',
  FOLDER_PLUS = 'sctr-icon-folder-plus',
  FOLDER_QUESTION = 'sctr-icon-folder-question',
  FOLDER_SEARCH = 'sctr-icon-folder-search',
  FOLDER_CLOSED = 'sctr-icon-folder-closed',
  FOLDER_X = 'sctr-icon-folder-x',
  PAPERCLIP = 'sctr-icon-paperclip',
  CLIPBOARD = 'sctr-icon-clipboard',
  CLIPBOARD_ATTACHMENT = 'sctr-icon-clipboard-attachment',
  CLIPBOARD_DOWNLOAD = 'sctr-icon-clipboard-download',
  CLIPBOARD_CHECK = 'sctr-icon-clipboard-check',
  CLIPBOARD_X = 'sctr-icon-clipboard-x',
  CLIPBOARD_PLUS = 'sctr-icon-clipboard-plus',
  CLIPBOARD_MINUS = 'sctr-icon-clipboard-minus',
  STICKER_SQUARE = 'sctr-icon-sticker-square',
  STICKER_CIRCLE = 'sctr-icon-sticker-circle',
  BOX = 'sctr-icon-box',
  BANK = 'sctr-icon-bank',
  BANK_NOTE_01 = 'sctr-icon-bank-note-01',
  BANK_NOTE_02 = 'sctr-icon-bank-note-02',
  BANK_NOTE_03 = 'sctr-icon-bank-note-03',
  COINS_01 = 'sctr-icon-coins-01',
  COINS_02 = 'sctr-icon-coins-02',
  COINS_03 = 'sctr-icon-coins-03',
  COINS_04 = 'sctr-icon-coins-04',
  COINS_HAND = 'sctr-icon-coins-hand',
  COINS_STACKED_01 = 'sctr-icon-coins-stacked-01',
  COINS_STACKED_02 = 'sctr-icon-coins-stacked-02',
  COINS_STACKED_03 = 'sctr-icon-coins-stacked-03',
  COINS_STACKED_04 = 'sctr-icon-coins-stacked-04',
  COINS_SWAP_01 = 'sctr-icon-coins-swap-01',
  COINS_SWAP_02 = 'sctr-icon-coins-swap-02',
  CREDIT_CARD_01 = 'sctr-icon-credit-card-01',
  CREDIT_CARD_02 = 'sctr-icon-credit-card-02',
  CREDIT_CARD_CHECK = 'sctr-icon-credit-card-check',
  CREDIT_CARD_DOWN = 'sctr-icon-credit-card-down',
  CREDIT_CARD_DOWNLOAD = 'sctr-icon-credit-card-download',
  CREDIT_CARD_EDIT = 'sctr-icon-credit-card-edit',
  CREDIT_CARD_LOCK = 'sctr-icon-credit-card-lock',
  CREDIT_CARD_PLUS = 'sctr-icon-credit-card-plus',
  CREDIT_CARD_REFRESH = 'sctr-icon-credit-card-refresh',
  CREDIT_CARD_SEARCH = 'sctr-icon-credit-card-search',
  CREDIT_CARD_SHIELD = 'sctr-icon-credit-card-shield',
  CREDIT_CARD_UP = 'sctr-icon-credit-card-up',
  CREDIT_CARD_UPLOAD = 'sctr-icon-credit-card-upload',
  CREDIT_CARD_X = 'sctr-icon-credit-card-x',
  CRYPTOCURRENCY_01 = 'sctr-icon-cryptocurrency-01',
  CRYPTOCURRENCY_02 = 'sctr-icon-cryptocurrency-02',
  CRYPTOCURRENCY_03 = 'sctr-icon-cryptocurrency-03',
  CRYPTOCURRENCY_04 = 'sctr-icon-cryptocurrency-04',
  CURRENCY_BITCOIN = 'sctr-icon-currency-bitcoin',
  CURRENCY_BITCOIN_CIRCLE = 'sctr-icon-currency-bitcoin-circle',
  CURRENCY_DOLLAR = 'sctr-icon-currency-dollar',
  CURRENCY_DOLLAR_CIRCLE = 'sctr-icon-currency-dollar-circle',
  CURRENCY_ETHEREUM = 'sctr-icon-currency-ethereum',
  CURRENCY_ETHEREUM_CIRCLE = 'sctr-icon-currency-ethereum-circle',
  CURRENCY_EURO = 'sctr-icon-currency-euro',
  CURRENCY_EURO_CIRCLE = 'sctr-icon-currency-euro-circle',
  CURRENCY_POUND = 'sctr-icon-currency-pound',
  CURRENCY_POUND_CIRCLE = 'sctr-icon-currency-pound-circle',
  CURRENCY_RUBLE = 'sctr-icon-currency-ruble',
  CURRENCY_RUBLE_CIRCLE = 'sctr-icon-currency-ruble-circle',
  CURRENCY_RUPEE = 'sctr-icon-currency-rupee',
  CURRENCY_RUPEE_CIRCLE = 'sctr-icon-currency-rupee-circle',
  CURRENCY_YEN = 'sctr-icon-currency-yen',
  CURRENCY_YEN_CIRCLE = 'sctr-icon-currency-yen-circle',
  DIAMOND_01 = 'sctr-icon-diamond-01',
  DIAMOND_02 = 'sctr-icon-diamond-02',
  GIFT_01 = 'sctr-icon-gift-01',
  GIFT_02 = 'sctr-icon-gift-02',
  PIGGY_BANK_01 = 'sctr-icon-piggy-bank-01',
  PIGGY_BANK_02 = 'sctr-icon-piggy-bank-02',
  RECEIPT = 'sctr-icon-receipt',
  RECEIPT_CHECK = 'sctr-icon-receipt-check',
  SAFE = 'sctr-icon-safe',
  SALE_01 = 'sctr-icon-sale-01',
  SALE_02 = 'sctr-icon-sale-02',
  SALE_03 = 'sctr-icon-sale-03',
  SALE_04 = 'sctr-icon-sale-04',
  SCALES_01 = 'sctr-icon-scales-01',
  SCALES_02 = 'sctr-icon-scales-02',
  SHOPPING_BAG_01 = 'sctr-icon-shopping-bag-01',
  SHOPPING_BAG_02 = 'sctr-icon-shopping-bag-02',
  SHOPPING_BAG_03 = 'sctr-icon-shopping-bag-03',
  SHOPPING_CART_01 = 'sctr-icon-shopping-cart-01',
  SHOPPING_CART_02 = 'sctr-icon-shopping-cart-02',
  SHOPPING_CART_03 = 'sctr-icon-shopping-cart-03',
  TAG_01 = 'sctr-icon-tag-01',
  TAG_02 = 'sctr-icon-tag-02',
  TAG_03 = 'sctr-icon-tag-03',
  WALLET_01 = 'sctr-icon-wallet-01',
  WALLET_02 = 'sctr-icon-wallet-02',
  WALLET_03 = 'sctr-icon-wallet-03',
  WALLET_04 = 'sctr-icon-wallet-04',
  WALLET_05 = 'sctr-icon-wallet-05',
  ACTIVITY = 'sctr-icon-activity',
  ACTIVITY_HEART = 'sctr-icon-activity-heart',
  ANCHOR = 'sctr-icon-anchor',
  ARCHIVE = 'sctr-icon-archive',
  ASTERISK_01 = 'sctr-icon-asterisk-01',
  ASTERISK_02 = 'sctr-icon-asterisk-02',
  AT_SIGN = 'sctr-icon-at-sign',
  BOOKMARK_ADD = 'sctr-icon-bookmark-add',
  BOOKMARK_CHECK = 'sctr-icon-bookmark-check',
  BOOKMARK_MINUS = 'sctr-icon-bookmark-minus',
  BOOKMARK = 'sctr-icon-bookmark',
  BOOKMARK_X = 'sctr-icon-bookmark-x',
  BUILDING_01 = 'sctr-icon-building-01',
  BUILDING_02 = 'sctr-icon-building-02',
  BUILDING_03 = 'sctr-icon-building-03',
  BUILDING_04 = 'sctr-icon-building-04',
  BUILDING_05 = 'sctr-icon-building-05',
  BUILDING_06 = 'sctr-icon-building-06',
  BUILDING_07 = 'sctr-icon-building-07',
  BUILDING_08 = 'sctr-icon-building-08',
  CHECK_CIRCLE_BROKEN = 'sctr-icon-check-circle-broken',
  CHECK_CIRCLE = 'sctr-icon-check-circle',
  CHECK_DONE_01 = 'sctr-icon-check-done-01',
  CHECK_DONE_02 = 'sctr-icon-check-done-02',
  CHECK_HEART = 'sctr-icon-check-heart',
  CHECK_SQUARE_BROKEN = 'sctr-icon-check-square-broken',
  CHECK_SQUARE = 'sctr-icon-check-square',
  CHECK = 'sctr-icon-check',
  CHECK_VERIFIED_01 = 'sctr-icon-check-verified-01',
  CHECK_VERIFIED_02 = 'sctr-icon-check-verified-02',
  CHECK_VERIFIED_03 = 'sctr-icon-check-verified-03',
  CLOUD_BLANK_01 = 'sctr-icon-cloud-blank-01',
  CLOUD_BLANK_02 = 'sctr-icon-cloud-blank-02',
  COPY_01 = 'sctr-icon-copy-01',
  COPY_02 = 'sctr-icon-copy-02',
  COPY_03 = 'sctr-icon-copy-03',
  COPY_04 = 'sctr-icon-copy-04',
  COPY_05 = 'sctr-icon-copy-05',
  COPY_06 = 'sctr-icon-copy-06',
  COPY_07 = 'sctr-icon-copy-07',
  DIVIDE_01 = 'sctr-icon-divide-01',
  DIVIDE_02 = 'sctr-icon-divide-02',
  DIVIDE_03 = 'sctr-icon-divide-03',
  DOTS_GRID = 'sctr-icon-dots-grid',
  DOTS_HORIZONTAL = 'sctr-icon-dots-horizontal',
  DOTS_VERTICAL = 'sctr-icon-dots-vertical',
  DOWNLOAD_01 = 'sctr-icon-download-01',
  DOWNLOAD_02 = 'sctr-icon-download-02',
  DOWNLOAD_03 = 'sctr-icon-download-03',
  DOWNLOAD_04 = 'sctr-icon-download-04',
  DOWNLOAD_CLOUD_01 = 'sctr-icon-download-cloud-01',
  DOWNLOAD_CLOUD_02 = 'sctr-icon-download-cloud-02',
  EDIT_01 = 'sctr-icon-edit-01',
  EDIT_02 = 'sctr-icon-edit-02',
  EDIT_03 = 'sctr-icon-edit-03',
  EDIT_04 = 'sctr-icon-edit-04',
  EDIT_05 = 'sctr-icon-edit-05',
  EQUAL = 'sctr-icon-equal',
  EQUAL_NOT = 'sctr-icon-equal-not',
  EYE = 'sctr-icon-eye',
  EYE_OFF = 'sctr-icon-eye-off',
  FILTER_FUNNEL_01 = 'sctr-icon-filter-funnel-01',
  FILTER_FUNNEL_02 = 'sctr-icon-filter-funnel-02',
  FILTER_LINES = 'sctr-icon-filter-lines',
  GOOGLE_CHROME = 'sctr-icon-google-chrome',
  HASH_01 = 'sctr-icon-hash-01',
  HASH_02 = 'sctr-icon-hash-02',
  HEART = 'sctr-icon-heart',
  HEART_CIRCLE = 'sctr-icon-heart-circle',
  HEART_HAND = 'sctr-icon-heart-hand',
  HEART_HEXAGON = 'sctr-icon-heart-hexagon',
  HEART_OCTAGON = 'sctr-icon-heart-octagon',
  HEART_ROUNDED = 'sctr-icon-heart-rounded',
  HEART_SQUARE = 'sctr-icon-heart-square',
  HEARTS = 'sctr-icon-hearts',
  HELP_CIRCLE = 'sctr-icon-help-circle',
  HELP_OCTAGON1 = 'sctr-icon-help-octagon1',
  HELP_OCTAGON = 'sctr-icon-help-octagon',
  HELP_SQUARE = 'sctr-icon-help-square',
  HOME_01 = 'sctr-icon-home-01',
  HOME_02 = 'sctr-icon-home-02',
  HOME_03 = 'sctr-icon-home-03',
  HOME_04 = 'sctr-icon-home-04',
  HOME_05 = 'sctr-icon-home-05',
  HOME_LINE = 'sctr-icon-home-line',
  HOME_SMILE = 'sctr-icon-home-smile',
  INFO_CIRCLE = 'sctr-icon-info-circle',
  INFO_HEXAGON = 'sctr-icon-info-hexagon',
  INFO_OCTAGON = 'sctr-icon-info-octagon',
  INFO_SQUARE = 'sctr-icon-info-square',
  LIFE_BUOY_01 = 'sctr-icon-life-buoy-01',
  LIFE_BUOY_02 = 'sctr-icon-life-buoy-02',
  LINK_01 = 'sctr-icon-link-01',
  LINK_02 = 'sctr-icon-link-02',
  LINK_03 = 'sctr-icon-link-03',
  LINK_04 = 'sctr-icon-link-04',
  LINK_05 = 'sctr-icon-link-05',
  LINK_BROKEN_01 = 'sctr-icon-link-broken-01',
  LINK_BROKEN_02 = 'sctr-icon-link-broken-02',
  LINK_EXTERNAL_01 = 'sctr-icon-link-external-01',
  LINK_EXTERNAL_02 = 'sctr-icon-link-external-02',
  LOADING_01 = 'sctr-icon-loading-01',
  LOADING_02 = 'sctr-icon-loading-02',
  LOADING_03 = 'sctr-icon-loading-03',
  LOG_IN_01 = 'sctr-icon-log-in-01',
  LOG_IN_02 = 'sctr-icon-log-in-02',
  LOG_IN_03 = 'sctr-icon-log-in-03',
  LOG_IN_04 = 'sctr-icon-log-in-04',
  LOG_OUT_01 = 'sctr-icon-log-out-01',
  LOG_OUT_02 = 'sctr-icon-log-out-02',
  LOG_OUT_03 = 'sctr-icon-log-out-03',
  LOG_OUT_04 = 'sctr-icon-log-out-04',
  MEDICAL_SQUARE = 'sctr-icon-medical-square',
  MEDICAL_CROSS = 'sctr-icon-medical-cross',
  MEDICAL_CIRCLE = 'sctr-icon-medical-circle',
  MENU_01 = 'sctr-icon-menu-01',
  MENU_02 = 'sctr-icon-menu-02',
  MENU_03 = 'sctr-icon-menu-03',
  MENU_04 = 'sctr-icon-menu-04',
  MENU_05 = 'sctr-icon-menu-05',
  MINUS = 'sctr-icon-minus',
  MINUS_CIRCLE = 'sctr-icon-minus-circle',
  MINUS_SQUARE = 'sctr-icon-minus-square',
  PERCENT_01 = 'sctr-icon-percent-01',
  PERCENT_02 = 'sctr-icon-percent-02',
  PERCENT_03 = 'sctr-icon-percent-03',
  PIN_01 = 'sctr-icon-pin-01',
  PIN_02 = 'sctr-icon-pin-02',
  PLUS = 'sctr-icon-plus',
  PLACEHOLDER = 'sctr-icon-placeholder',
  PLUS_CIRCLE = 'sctr-icon-plus-circle',
  PLUS_SQUARE = 'sctr-icon-plus-square',
  SAVE_01 = 'sctr-icon-save-01',
  SAVE_02 = 'sctr-icon-save-02',
  SAVE_03 = 'sctr-icon-save-03',
  SEARCH_LG = 'sctr-icon-search-lg',
  SEARCH_MD = 'sctr-icon-search-md',
  SEARCH_REFRACTION = 'sctr-icon-search-refraction',
  SEARCH_SM = 'sctr-icon-search-sm',
  SETTINGS_01 = 'sctr-icon-settings-01',
  SETTINGS_02 = 'sctr-icon-settings-02',
  SETTINGS_03 = 'sctr-icon-settings-03',
  SETTINGS_04 = 'sctr-icon-settings-04',
  SHARE_01 = 'sctr-icon-share-01',
  SHARE_02 = 'sctr-icon-share-02',
  SHARE_03 = 'sctr-icon-share-03',
  SHARE_04 = 'sctr-icon-share-04',
  SHARE_05 = 'sctr-icon-share-05',
  SHARE_06 = 'sctr-icon-share-06',
  SHARE_07 = 'sctr-icon-share-07',
  SLASH_CIRCLE_01 = 'sctr-icon-slash-circle-01',
  SLASH_CIRCLE_02 = 'sctr-icon-slash-circle-02',
  SLASH_DIVIDER = 'sctr-icon-slash-divider',
  SLASH_OCTAGON = 'sctr-icon-slash-octagon',
  SPEEDOMETER_01 = 'sctr-icon-speedometer-01',
  SPEEDOMETER_02 = 'sctr-icon-speedometer-02',
  SPEEDOMETER_03 = 'sctr-icon-speedometer-03',
  SPEEDOMETER_04 = 'sctr-icon-speedometer-04',
  TARGET_01 = 'sctr-icon-target-01',
  TARGET_02 = 'sctr-icon-target-02',
  TARGET_03 = 'sctr-icon-target-03',
  TARGET_04 = 'sctr-icon-target-04',
  TARGET_05 = 'sctr-icon-target-05',
  TOGGLE_01_LEFT = 'sctr-icon-toggle-01-left',
  TOGGLE_01_RIGHT = 'sctr-icon-toggle-01-right',
  TOGGLE_02_RIGHT = 'sctr-icon-toggle-02-right',
  TOGGLE_02_LEFT = 'sctr-icon-toggle-02-left',
  TOGGLE_03_LEFT = 'sctr-icon-toggle-03-left',
  TOGGLE_03_RIGHT = 'sctr-icon-toggle-03-right',
  TOOL_01 = 'sctr-icon-tool-01',
  TOOL_02 = 'sctr-icon-tool-02',
  TRANSLATE_01 = 'sctr-icon-translate-01',
  TRANSLATE_02 = 'sctr-icon-translate-02',
  TRASH_01 = 'sctr-icon-trash-01',
  TRASH_02 = 'sctr-icon-trash-02',
  TRASH_03 = 'sctr-icon-trash-03',
  TRASH_04 = 'sctr-icon-trash-04',
  UPLOAD_01 = 'sctr-icon-upload-01',
  UPLOAD_02 = 'sctr-icon-upload-02',
  UPLOAD_03 = 'sctr-icon-upload-03',
  UPLOAD_04 = 'sctr-icon-upload-04',
  UPLOAD_CLOUD_01 = 'sctr-icon-upload-cloud-01',
  UPLOAD_CLOUD_02 = 'sctr-icon-upload-cloud-02',
  VIRUS = 'sctr-icon-virus',
  X = 'sctr-icon-x',
  X_CIRCLE = 'sctr-icon-x-circle',
  X_CLOSE = 'sctr-icon-x-close',
  X_SQUARE = 'sctr-icon-x-square',
  ZAP = 'sctr-icon-zap',
  ZAP_CIRCLE = 'sctr-icon-zap-circle',
  ZAP_FAST = 'sctr-icon-zap-fast',
  ZAP_OFF = 'sctr-icon-zap-off',
  ZAP_SQUARE = 'sctr-icon-zap-square',
  CAMERA_01 = 'sctr-icon-camera-01',
  CAMERA_02 = 'sctr-icon-camera-02',
  CAMERA_03 = 'sctr-icon-camera-03',
  CAMERA_LENS = 'sctr-icon-camera-lens',
  CAMERA_OFF = 'sctr-icon-camera-off',
  CAMERA_PLUS = 'sctr-icon-camera-plus',
  FLASH = 'sctr-icon-flash',
  FLASH_OFF = 'sctr-icon-flash-off',
  IMAGE_01 = 'sctr-icon-image-01',
  IMAGE_02 = 'sctr-icon-image-02',
  IMAGE_03 = 'sctr-icon-image-03',
  IMAGE_04 = 'sctr-icon-image-04',
  IMAGE_05 = 'sctr-icon-image-05',
  IMAGE_CHECK = 'sctr-icon-image-check',
  IMAGE_DOWN = 'sctr-icon-image-down',
  IMAGE_LEFT = 'sctr-icon-image-left',
  IMAGE_PLUS = 'sctr-icon-image-plus',
  IMAGE_RIGHT = 'sctr-icon-image-right',
  IMAGE_UP = 'sctr-icon-image-up',
  IMAGE_USER = 'sctr-icon-image-user',
  IMAGE_USER_CHECK = 'sctr-icon-image-user-check',
  IMAGE_USER_DOWN = 'sctr-icon-image-user-down',
  IMAGE_USER_LEFT = 'sctr-icon-image-user-left',
  IMAGE_USER_PLUS = 'sctr-icon-image-user-plus',
  IMAGE_USER_RIGHT = 'sctr-icon-image-user-right',
  IMAGE_USER_UP = 'sctr-icon-image-user-up',
  IMAGE_USER_X = 'sctr-icon-image-user-x',
  IMAGE_X = 'sctr-icon-image-x',
  ALIGN_BOTTOM_01 = 'sctr-icon-align-bottom-01',
  ALIGN_BOTTOM_02 = 'sctr-icon-align-bottom-02',
  ALIGN_HORIZONTAL_CENTRE_01 = 'sctr-icon-align-horizontal-centre-01',
  ALIGN_HORIZONTAL_CENTRE_02 = 'sctr-icon-align-horizontal-centre-02',
  ALIGN_LEFT_01 = 'sctr-icon-align-left-01',
  ALIGN_LEFT_02 = 'sctr-icon-align-left-02',
  ALIGN_RIGHT_01 = 'sctr-icon-align-right-01',
  ALIGN_RIGHT_02 = 'sctr-icon-align-right-02',
  ALIGN_TOP_ARROW_01 = 'sctr-icon-align-top-arrow-01',
  ALIGN_TOP_ARROW_02 = 'sctr-icon-align-top-arrow-02',
  ALIGN_VERTICAL_CENTER_01 = 'sctr-icon-align-vertical-center-01',
  ALIGN_VERTICAL_CENTER_02 = 'sctr-icon-align-vertical-center-02',
  COLUMNS_01 = 'sctr-icon-columns-01',
  COLUMNS_02 = 'sctr-icon-columns-02',
  COLUMNS_03 = 'sctr-icon-columns-03',
  DISTRIBUTE_SPACING_HORIZONTAL = 'sctr-icon-distribute-spacing-horizontal',
  DISTRIBUTE_SPACING_VERTICAL = 'sctr-icon-distribute-spacing-vertical',
  DIVIDER = 'sctr-icon-divider',
  FLEX_ALIGN_BOTTOM = 'sctr-icon-flex-align-bottom',
  FLEX_ALIGN_LEFT = 'sctr-icon-flex-align-left',
  FLEX_ALIGN_RIGHT = 'sctr-icon-flex-align-right',
  FLEX_ALIGN_TOP = 'sctr-icon-flex-align-top',
  GRID_01 = 'sctr-icon-grid-01',
  GRID_02 = 'sctr-icon-grid-02',
  GRID_03 = 'sctr-icon-grid-03',
  GRID_DOTS_BLANK = 'sctr-icon-grid-dots-blank',
  GRID_DOTS_BOTTOM = 'sctr-icon-grid-dots-bottom',
  GRID_DOTS_HORIZONTAL_CENTER = 'sctr-icon-grid-dots-horizontal-center',
  GRID_DOTS_LEFT = 'sctr-icon-grid-dots-left',
  GRID_DOTS_OUTER = 'sctr-icon-grid-dots-outer',
  GRID_DOTS_RIGHT = 'sctr-icon-grid-dots-right',
  GRID_DOTS_TOP = 'sctr-icon-grid-dots-top',
  GRID_DOTS_VERTICAL_CENTER = 'sctr-icon-grid-dots-vertical-center',
  INTERSECT_CIRCLE = 'sctr-icon-intersect-circle',
  INTERSECT_SQUARE = 'sctr-icon-intersect-square',
  LAYER_SINGLE = 'sctr-icon-layer-single',
  LAYERS_THREE_01 = 'sctr-icon-layers-three-01',
  LAYERS_THREE_02 = 'sctr-icon-layers-three-02',
  LAYERS_TWO_01 = 'sctr-icon-layers-two-01',
  LAYERS_TWO_02 = 'sctr-icon-layers-two-02',
  LAYOUT_ALT_01 = 'sctr-icon-layout-alt-01',
  LAYOUT_ALT_02 = 'sctr-icon-layout-alt-02',
  LAYOUT_ALT_03 = 'sctr-icon-layout-alt-03',
  LAYOUT_ALT_04 = 'sctr-icon-layout-alt-04',
  LAYOUT_BOTTOM = 'sctr-icon-layout-bottom',
  LAYOUT_GRID_01 = 'sctr-icon-layout-grid-01',
  LAYOUT_GRID_02 = 'sctr-icon-layout-grid-02',
  LAYOUT_LEFT = 'sctr-icon-layout-left',
  LAYOUT_RIGHT = 'sctr-icon-layout-right',
  LAYOUT_TOP = 'sctr-icon-layout-top',
  LIST = 'sctr-icon-list',
  MAXIMIZE_01 = 'sctr-icon-maximize-01',
  MAXIMIZE_02 = 'sctr-icon-maximize-02',
  MINIMIZE_01 = 'sctr-icon-minimize-01',
  MINIMIZE_02 = 'sctr-icon-minimize-02',
  ROWS_01 = 'sctr-icon-rows-01',
  ROWS_02 = 'sctr-icon-rows-02',
  ROWS_03 = 'sctr-icon-rows-03',
  SPACING_HEIGHT_01 = 'sctr-icon-spacing-height-01',
  SPACING_HEIGHT_02 = 'sctr-icon-spacing-height-02',
  SPACING_WIDTH_01 = 'sctr-icon-spacing-width-01',
  SPACING_WIDTH_02 = 'sctr-icon-spacing-width-02',
  TABLE = 'sctr-icon-table',
  BUS = 'sctr-icon-bus',
  CAR_01 = 'sctr-icon-car-01',
  CAR_02 = 'sctr-icon-car-02',
  COMPASS_01 = 'sctr-icon-compass-01',
  COMPASS_02 = 'sctr-icon-compass-02',
  COMPASS_03 = 'sctr-icon-compass-03',
  FLAG_01 = 'sctr-icon-flag-01',
  FLAG_02 = 'sctr-icon-flag-02',
  FLAG_03 = 'sctr-icon-flag-03',
  FLAG_04 = 'sctr-icon-flag-04',
  FLAG_05 = 'sctr-icon-flag-05',
  FLAG_06 = 'sctr-icon-flag-06',
  GLOBE_03 = 'sctr-icon-globe-03',
  GLOBE_04 = 'sctr-icon-globe-04',
  GLOBE_05 = 'sctr-icon-globe-05',
  GLOBE_06 = 'sctr-icon-globe-06',
  LUGGAGE_01 = 'sctr-icon-luggage-01',
  LUGGAGE_02 = 'sctr-icon-luggage-02',
  LUGGAGE_03 = 'sctr-icon-luggage-03',
  MAP_01 = 'sctr-icon-map-01',
  MAP_02 = 'sctr-icon-map-02',
  MARK = 'sctr-icon-mark',
  MARKER_PIN_01 = 'sctr-icon-marker-pin-01',
  MARKER_PIN_02 = 'sctr-icon-marker-pin-02',
  MARKER_PIN_03 = 'sctr-icon-marker-pin-03',
  MARKER_PIN_04 = 'sctr-icon-marker-pin-04',
  MARKER_PIN_05 = 'sctr-icon-marker-pin-05',
  MARKER_PIN_06 = 'sctr-icon-marker-pin-06',
  NAVIGATION_POINTER_01 = 'sctr-icon-navigation-pointer-01',
  NAVIGATION_POINTER_02 = 'sctr-icon-navigation-pointer-02',
  NAVIGATION_POINTER_OFF_01 = 'sctr-icon-navigation-pointer-off-01',
  NAVIGATION_POINTER_OFF_02 = 'sctr-icon-navigation-pointer-off-02',
  PASSPORT = 'sctr-icon-passport',
  PLANE = 'sctr-icon-plane',
  ROCKET_01 = 'sctr-icon-rocket-01',
  ROCKET_02 = 'sctr-icon-rocket-02',
  ROUTE = 'sctr-icon-route',
  TICKET_01 = 'sctr-icon-ticket-01',
  TICKET_02 = 'sctr-icon-ticket-02',
  TRAIN = 'sctr-icon-train',
  TRAM = 'sctr-icon-tram',
  TRUCK_01 = 'sctr-icon-truck-01',
  TRUCK_02 = 'sctr-icon-truck-02',
  FACE_CONTENT = 'sctr-icon-face-content',
  FACE_FROWN = 'sctr-icon-face-frown',
  FACE_HAPPY = 'sctr-icon-face-happy',
  FACE_NEUTRAL = 'sctr-icon-face-neutral',
  FACE_SAD = 'sctr-icon-face-sad',
  FACE_SMILE = 'sctr-icon-face-smile',
  FACE_WINK = 'sctr-icon-face-wink',
  USER_01 = 'sctr-icon-user-01',
  USER_02 = 'sctr-icon-user-02',
  USER_03 = 'sctr-icon-user-03',
  USER_CHECK_01 = 'sctr-icon-user-check-01',
  USER_CHECK_02 = 'sctr-icon-user-check-02',
  USER_CIRCLE = 'sctr-icon-user-circle',
  USER_DOWN_01 = 'sctr-icon-user-down-01',
  USER_DOWN_02 = 'sctr-icon-user-down-02',
  USER_EDIT = 'sctr-icon-user-edit',
  USER_LEFT_01 = 'sctr-icon-user-left-01',
  USER_LEFT_02 = 'sctr-icon-user-left-02',
  USER_MINUS_01 = 'sctr-icon-user-minus-01',
  USER_MINUS_02 = 'sctr-icon-user-minus-02',
  USER_PLUS_01 = 'sctr-icon-user-plus-01',
  USER_PLUS_02 = 'sctr-icon-user-plus-02',
  USER_RIGHT_01 = 'sctr-icon-user-right-01',
  USER_RIGHT_02 = 'sctr-icon-user-right-02',
  USER_SQUARE = 'sctr-icon-user-square',
  USER_UP_01 = 'sctr-icon-user-up-01',
  USER_X_01 = 'sctr-icon-user-x-01',
  USER_X_02 = 'sctr-icon-user-x-02',
  USERS_01 = 'sctr-icon-users-01',
  USERS_02 = 'sctr-icon-users-02',
  USERS_03 = 'sctr-icon-users-03',
  USERS_CHECK = 'sctr-icon-users-check',
  USERS_DOWN = 'sctr-icon-users-down',
  USERS_EDIT = 'sctr-icon-users-edit',
  USERS_LEFT = 'sctr-icon-users-left',
  USERS_MINUS = 'sctr-icon-users-minus',
  USERS_PLUS = 'sctr-icon-users-plus',
  USERS_RIGHT = 'sctr-icon-users-right',
  USERS_UP = 'sctr-icon-users-up',
  USERS_X = 'sctr-icon-users-x',
  USER_UP_02 = 'sctr-icon-user-up-02',
  CLOUD_01 = 'sctr-icon-cloud-01',
  CLOUD_02 = 'sctr-icon-cloud-02',
  CLOUD_03 = 'sctr-icon-cloud-03',
  CLOUD_LIGHTNING = 'sctr-icon-cloud-lightning',
  CLOUD_MOON = 'sctr-icon-cloud-moon',
  CLOUD_OFF = 'sctr-icon-cloud-off',
  CLOUD_RAINING_01 = 'sctr-icon-cloud-raining-01',
  CLOUD_RAINING_02 = 'sctr-icon-cloud-raining-02',
  CLOUD_RAINING_03 = 'sctr-icon-cloud-raining-03',
  CLOUD_RAINING_04 = 'sctr-icon-cloud-raining-04',
  CLOUD_RAINING_05 = 'sctr-icon-cloud-raining-05',
  CLOUD_RAINING_06 = 'sctr-icon-cloud-raining-06',
  CLOUD_SNOWING_01 = 'sctr-icon-cloud-snowing-01',
  CLOUD_SNOWING_02 = 'sctr-icon-cloud-snowing-02',
  CLOUD_SUN_01 = 'sctr-icon-cloud-sun-01',
  CLOUD_SUN_02 = 'sctr-icon-cloud-sun-02',
  CLOUD_SUN_03 = 'sctr-icon-cloud-sun-03',
  DROPLETS_01 = 'sctr-icon-droplets-01',
  DROPLETS_02 = 'sctr-icon-droplets-02',
  DROPLETS_03 = 'sctr-icon-droplets-03',
  HURRICANE_01 = 'sctr-icon-hurricane-01',
  HURRICANE_02 = 'sctr-icon-hurricane-02',
  HURRICANE_03 = 'sctr-icon-hurricane-03',
  LIGHTNING_01 = 'sctr-icon-lightning-01',
  LIGHTNING_02 = 'sctr-icon-lightning-02',
  MOON_01 = 'sctr-icon-moon-01',
  MOON_02 = 'sctr-icon-moon-02',
  MOON_ECLIPSE = 'sctr-icon-moon-eclipse',
  MOON_STAR = 'sctr-icon-moon-star',
  SNOWFLAKE_01 = 'sctr-icon-snowflake-01',
  SNOWFLAKE_02 = 'sctr-icon-snowflake-02',
  STARS_01 = 'sctr-icon-stars-01',
  STARS_02 = 'sctr-icon-stars-02',
  STARS_03 = 'sctr-icon-stars-03',
  SUN = 'sctr-icon-sun',
  SUN_SETTING_01 = 'sctr-icon-sun-setting-01',
  SUN_SETTING_02 = 'sctr-icon-sun-setting-02',
  SUN_SETTING_03 = 'sctr-icon-sun-setting-03',
  SUNRISE = 'sctr-icon-sunrise',
  SUNSET = 'sctr-icon-sunset',
  THERMOMETER_01 = 'sctr-icon-thermometer-01',
  THERMOMETER_02 = 'sctr-icon-thermometer-02',
  THERMOMETER_03 = 'sctr-icon-thermometer-03',
  THERMOMETER_COLD = 'sctr-icon-thermometer-cold',
  THERMOMETER_WARM = 'sctr-icon-thermometer-warm',
  UMBRELLA_01 = 'sctr-icon-umbrella-01',
  UMBRELLA_02 = 'sctr-icon-umbrella-02',
  UMBRELLA_03 = 'sctr-icon-umbrella-03',
  WAVES = 'sctr-icon-waves',
  WIND_01 = 'sctr-icon-wind-01',
  WIND_02 = 'sctr-icon-wind-02',
  WIND_03 = 'sctr-icon-wind-03',
  AIRPLAY = 'sctr-icon-airplay',
  AIRPODS = 'sctr-icon-airpods',
  BATTERY_CHARGING_01 = 'sctr-icon-battery-charging-01',
  BATTERY_CHARGING_02 = 'sctr-icon-battery-charging-02',
  BATTERY_EMPTY = 'sctr-icon-battery-empty',
  BATTERY_FULL = 'sctr-icon-battery-full',
  BATTERY_LOW = 'sctr-icon-battery-low',
  BATTERY_MID = 'sctr-icon-battery-mid',
  BLUETOOTH_CONNECT = 'sctr-icon-bluetooth-connect',
  BLUETOOTH_OFF = 'sctr-icon-bluetooth-off',
  BLUETOOTH_ON = 'sctr-icon-bluetooth-on',
  BLUETOOTH_SIGNAL = 'sctr-icon-bluetooth-signal',
  CHROME_CAST = 'sctr-icon-chrome-cast',
  CLAPPERBOARD = 'sctr-icon-clapperboard',
  DISC_01 = 'sctr-icon-disc-01',
  DISC_02 = 'sctr-icon-disc-02',
  FAST_BACKWARD = 'sctr-icon-fast-backward',
  FAST_FORWARD = 'sctr-icon-fast-forward',
  FILM_01 = 'sctr-icon-film-01',
  FILM_02 = 'sctr-icon-film-02',
  FILM_03 = 'sctr-icon-film-03',
  GAMING_PAD_01 = 'sctr-icon-gaming-pad-01',
  GAMING_PAD_02 = 'sctr-icon-gaming-pad-02',
  HARD_DRIVE = 'sctr-icon-hard-drive',
  HEADPHONES_01 = 'sctr-icon-headphones-01',
  HEADPHONES_02_CONVERTED = 'sctr-icon-headphones-02-converted',
  KEYBOARD_01 = 'sctr-icon-keyboard-01',
  KEYBOARD_02 = 'sctr-icon-keyboard-02',
  LAPTOP_01 = 'sctr-icon-laptop-01',
  LAPTOP_02 = 'sctr-icon-laptop-02',
  LIGHTBULB_01 = 'sctr-icon-lightbulb-01',
  LIGHTBULB_02 = 'sctr-icon-lightbulb-02',
  LIGHTBULB_03 = 'sctr-icon-lightbulb-03',
  LIGHTBULB_04 = 'sctr-icon-lightbulb-04',
  LIGHTBULB_05 = 'sctr-icon-lightbulb-05',
  MICROPHONE_01 = 'sctr-icon-microphone-01',
  MICROPHONE_02 = 'sctr-icon-microphone-02',
  MICROPHONE_OFF_01 = 'sctr-icon-microphone-off-01',
  MICROPHONE_OFF_02 = 'sctr-icon-microphone-off-02',
  MODEM_01 = 'sctr-icon-modem-01',
  MODEM_02 = 'sctr-icon-modem-02',
  MONITOR_01 = 'sctr-icon-monitor-01',
  MONITOR_02 = 'sctr-icon-monitor-02',
  MONITOR_03 = 'sctr-icon-monitor-03',
  MONITOR_04 = 'sctr-icon-monitor-04',
  MONITOR_05 = 'sctr-icon-monitor-05',
  MOUSE = 'sctr-icon-mouse',
  MUSIC_NOTE_01 = 'sctr-icon-music-note-01',
  MUSIC_NOTE_02 = 'sctr-icon-music-note-02',
  MUSIC_NOTE_PLUS = 'sctr-icon-music-note-plus',
  PAUSE_CIRCLE = 'sctr-icon-pause-circle',
  PAUSE_SQUARE = 'sctr-icon-pause-square',
  PHONE_01 = 'sctr-icon-phone-01',
  PHONE_02 = 'sctr-icon-phone-02',
  PLAY = 'sctr-icon-play',
  PLAY_CIRCLE = 'sctr-icon-play-circle',
  PLAY_SQUARE = 'sctr-icon-play-square',
  PODCAST = 'sctr-icon-podcast',
  POWER_01 = 'sctr-icon-power-01',
  POWER_02 = 'sctr-icon-power-02',
  POWER_03 = 'sctr-icon-power-03',
  PRINTER = 'sctr-icon-printer',
  RECORDING_01 = 'sctr-icon-recording-01',
  RECORDING_02 = 'sctr-icon-recording-02',
  RECORDING_03 = 'sctr-icon-recording-03',
  REPEAT_01 = 'sctr-icon-repeat-01',
  REPEAT_02 = 'sctr-icon-repeat-02',
  REPEAT_03 = 'sctr-icon-repeat-03',
  REPEAT_04 = 'sctr-icon-repeat-04',
  RSS_01 = 'sctr-icon-rss-01',
  RSS_02 = 'sctr-icon-rss-02',
  SHUFFLE_01 = 'sctr-icon-shuffle-01',
  SHUFFLE_02 = 'sctr-icon-shuffle-02',
  SIGNAL_01 = 'sctr-icon-signal-01',
  SIGNAL_02 = 'sctr-icon-signal-02',
  SIGNAL_03 = 'sctr-icon-signal-03',
  SIMCARD = 'sctr-icon-simcard',
  SKIP_BACK = 'sctr-icon-skip-back',
  SKIP_FORWARD = 'sctr-icon-skip-forward',
  SLIDERS_01 = 'sctr-icon-sliders-01',
  SLIDERS_02 = 'sctr-icon-sliders-02',
  SLIDERS_03 = 'sctr-icon-sliders-03',
  SLIDERS_04 = 'sctr-icon-sliders-04',
  SPEAKER_01 = 'sctr-icon-speaker-01',
  SPEAKER_02 = 'sctr-icon-speaker-02',
  SPEAKER_03 = 'sctr-icon-speaker-03',
  STOP = 'sctr-icon-stop',
  STOP_CIRCLE = 'sctr-icon-stop-circle',
  STOP_SQUARE = 'sctr-icon-stop-square',
  TABLET_01 = 'sctr-icon-tablet-01',
  TABLET_02 = 'sctr-icon-tablet-02',
  TV_01 = 'sctr-icon-tv-01',
  TV_02 = 'sctr-icon-tv-02',
  TV_03 = 'sctr-icon-tv-03',
  USB_FLASH_DRIVE = 'sctr-icon-usb-flash-drive',
  VIDEO_RECORDER = 'sctr-icon-video-recorder',
  VIDEO_RECORDER_OFF = 'sctr-icon-video-recorder-off',
  VOICEMAIL = 'sctr-icon-voicemail',
  VOLUME_MAX = 'sctr-icon-volume-max',
  VOLUME_MIN = 'sctr-icon-volume-min',
  VOLUME_MINUS = 'sctr-icon-volume-minus',
  VOLUME_PLUS = 'sctr-icon-volume-plus',
  VOLUME_X = 'sctr-icon-volume-x',
  WEBCAM_01 = 'sctr-icon-webcam-01',
  WEBCAM_02 = 'sctr-icon-webcam-02',
  WIFI = 'sctr-icon-wifi',
  WIFI_OFF = 'sctr-icon-wifi-off',
  YOUTUBE = 'sctr-icon-youtube',
  FACE_ID = 'sctr-icon-face-id',
  FACE_ID_SQUARE = 'sctr-icon-face-id-square',
  FILE_LOCK_01 = 'sctr-icon-file-lock-01',
  FILE_LOCK_02 = 'sctr-icon-file-lock-02',
  FILE_LOCK_03 = 'sctr-icon-file-lock-03',
  FILE_SHIELD_01 = 'sctr-icon-file-shield-01',
  FILE_SHIELD_02 = 'sctr-icon-file-shield-02',
  FILE_SHIELD_03 = 'sctr-icon-file-shield-03',
  FINGERPRINT_01 = 'sctr-icon-fingerprint-01',
  FINGERPRINT_02 = 'sctr-icon-fingerprint-02',
  FINGERPRINT_03 = 'sctr-icon-fingerprint-03',
  FINGERPRINT_04 = 'sctr-icon-fingerprint-04',
  FOLDER_SHIELD = 'sctr-icon-folder-shield',
  KEY_01 = 'sctr-icon-key-01',
  KEY_02 = 'sctr-icon-key-02',
  LOCK_01 = 'sctr-icon-lock-01',
  LOCK_02 = 'sctr-icon-lock-02',
  LOCK_03 = 'sctr-icon-lock-03',
  LOCK_04 = 'sctr-icon-lock-04',
  LOCK_KEYHOLE_CIRCLE = 'sctr-icon-lock-keyhole-circle',
  LOCK_KEYHOLE_SQUARE = 'sctr-icon-lock-keyhole-square',
  LOCK_UNLOCKED_01 = 'sctr-icon-lock-unlocked-01',
  LOCK_UNLOCKED_02 = 'sctr-icon-lock-unlocked-02',
  LOCK_UNLOCKED_03 = 'sctr-icon-lock-unlocked-03',
  LOCK_UNLOCKED_04 = 'sctr-icon-lock-unlocked-04',
  PASSCODE = 'sctr-icon-passcode',
  PASSCODE_LOCK = 'sctr-icon-passcode-lock',
  SCAN = 'sctr-icon-scan',
  SHIELD_03 = 'sctr-icon-shield-03',
  SHIELD_DOLLAR = 'sctr-icon-shield-dollar',
  SHIELD_OFF = 'sctr-icon-shield-off',
  SHIELD_PLUS = 'sctr-icon-shield-plus',
  SHIELD_TICK = 'sctr-icon-shield-tick',
  SHIELD_ZAP = 'sctr-icon-shield-zap',
  SHIELD_01 = 'sctr-icon-shield-01',
  SHIELD_02 = 'sctr-icon-shield-02',
  CIRCLE = 'sctr-icon-circle',
  CUBE_01 = 'sctr-icon-cube-01',
  CUBE_02 = 'sctr-icon-cube-02',
  CUBE_03 = 'sctr-icon-cube-03',
  CUBE_04 = 'sctr-icon-cube-04',
  CUBE_OUTLINE = 'sctr-icon-cube-outline',
  DICE_1 = 'sctr-icon-dice-1',
  DICE_2 = 'sctr-icon-dice-2',
  DICE_4 = 'sctr-icon-dice-4',
  DICE_3 = 'sctr-icon-dice-3',
  DICE_5 = 'sctr-icon-dice-5',
  DICE_6 = 'sctr-icon-dice-6',
  HEXAGON_01 = 'sctr-icon-hexagon-01',
  HEXAGON_02 = 'sctr-icon-hexagon-02',
  OCTAGON = 'sctr-icon-octagon',
  PENTAGON = 'sctr-icon-pentagon',
  SQUARE = 'sctr-icon-square',
  STAR_01 = 'sctr-icon-star-01',
  STAR_02 = 'sctr-icon-star-02',
  STAR_03 = 'sctr-icon-star-03',
  STAR_04 = 'sctr-icon-star-04',
  STAR_05 = 'sctr-icon-star-05',
  STAR_06 = 'sctr-icon-star-06',
  STAR_07 = 'sctr-icon-star-07',
  TRIANGLE = 'sctr-icon-triangle',
  ELLIPSE = 'sctr-icon-ellipse',
  ALARM_CLOCK = 'sctr-icon-alarm-clock',
  ALARM_CLOCK_CHECK = 'sctr-icon-alarm-clock-check',
  ALARM_CLOCK_MINUS = 'sctr-icon-alarm-clock-minus',
  ALARM_CLOCK_OFF = 'sctr-icon-alarm-clock-off',
  ALARM_CLOCK_PLUS = 'sctr-icon-alarm-clock-plus',
  CALENDAR = 'sctr-icon-calendar',
  CALENDAR_CHECK_01 = 'sctr-icon-calendar-check-01',
  CALENDAR_CHECK_02 = 'sctr-icon-calendar-check-02',
  CALENDAR_DATE = 'sctr-icon-calendar-date',
  CALENDAR_HEART_01 = 'sctr-icon-calendar-heart-01',
  CALENDAR_HEART_02 = 'sctr-icon-calendar-heart-02',
  CALENDAR_MINUS_01 = 'sctr-icon-calendar-minus-01',
  CALENDAR_MINUS_02 = 'sctr-icon-calendar-minus-02',
  CALENDAR_PLUS_01 = 'sctr-icon-calendar-plus-01',
  CALENDAR_PLUS_02 = 'sctr-icon-calendar-plus-02',
  CLOCK = 'sctr-icon-clock',
  CLOCK_CHECK = 'sctr-icon-clock-check',
  CLOCK_FAST_FORWARD = 'sctr-icon-clock-fast-forward',
  CLOCK_PLUS = 'sctr-icon-clock-plus',
  CLOCK_REFRESH = 'sctr-icon-clock-refresh',
  CLOCK_REWIND = 'sctr-icon-clock-rewind',
  CLOCK_SNOOZE = 'sctr-icon-clock-snooze',
  CLOCK_STOPWATCH = 'sctr-icon-clock-stopwatch',
  HOURGLASS_01 = 'sctr-icon-hourglass-01',
  HOURGLASS_02 = 'sctr-icon-hourglass-02',
  HOURGLASS_03 = 'sctr-icon-hourglass-03',
  WATCH_CIRCLE = 'sctr-icon-watch-circle',
  WATCH_SQUARE = 'sctr-icon-watch-square',
}
