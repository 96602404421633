import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})

export class TokenStorageService {

  constructor() {}

  logOut(): void {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  }

  saveToken(token: string): void {
    localStorage.removeItem(environment.TOKEN_KEY);
    localStorage.setItem(environment.TOKEN_KEY, token);
  }

  getToken(): string | null {
    return localStorage.getItem(environment.TOKEN_KEY);
  }

  saveRefreshToken(token: string): void {
    localStorage.removeItem(environment.REFRESH_TOKEN_KEY);
    const refreshToken = {
      refreshToken: token
    }
    const refreshTokenString = JSON.stringify(refreshToken);
    localStorage.setItem(environment.REFRESH_TOKEN_KEY, refreshTokenString);
  }

  saveAccessTokenExpiry(access_token_expiry_date: string): void {
    localStorage.removeItem(environment.ACCESS_TOKEN_EXPIRY_KEY);
    localStorage.setItem(
      environment.ACCESS_TOKEN_EXPIRY_KEY,
      access_token_expiry_date
    );
  }

  saveRefreshTokenExpiry(refresh_token_expiry_date: string): void {
    localStorage.removeItem(environment.REFRESH_TOKEN_EXPIRY_KEY);
    localStorage.setItem(
      environment.REFRESH_TOKEN_EXPIRY_KEY,
      refresh_token_expiry_date
    );
  }

  getRefreshToken(): string | null {
    return localStorage.getItem(environment.REFRESH_TOKEN_KEY);
  }
  
  clearTokenLocalStorage() {
    localStorage.removeItem(environment.TOKEN_KEY);
    localStorage.removeItem(environment.REFRESH_TOKEN_KEY);
    localStorage.removeItem(environment.ACCESS_TOKEN_EXPIRY_KEY);
    localStorage.removeItem(environment.REFRESH_TOKEN_EXPIRY_KEY);
  }
}
