import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BookedRoomListService {
  private _bookedRoomList: ReplaySubject<any> = new ReplaySubject();
  private bookedRoomList$: Observable<any> = this._bookedRoomList.asObservable();
  private booked_list_room: any = [];

  setBookedRoomList(data: any, type: string) {
    const foundItem = this.booked_list_room.find(
      (item: any) => item.room_type_id === data
    );

    if (type == 'plus') {
        if (foundItem) {
          foundItem.quantity++;
        } else {
          const newItem = { room_type_id: data, quantity: 1 };
          this.booked_list_room.push(newItem);
        }
    } else {
        foundItem.quantity--;
    }

    
    this._bookedRoomList.next(this.booked_list_room);
  }

  getBookedRoomList() {
    return this.bookedRoomList$;
  }

  clearBookedRoomList() {
    this.booked_list_room = [];
  }
}
