import { Component, Input, OnInit, inject } from '@angular/core';
import { TimePickerService } from '@modules/hotel-booking/services/time-picker/time-picker.service'; 

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements OnInit {
  @Input() key: string;
  @Input() defaultHour: string;
  @Input() defaultMinute: string;

  public _timePicker: TimePickerService = inject(TimePickerService);

  // open timepicker
  public isOpen: boolean = false;

  // generate
  public hoursGenerate: string[] = [];
  public minutesGenerate: string[] = [];

  // display
  public timeTemp: { hours: string, minutes: string } = {
    hours: '',
    minutes: '',
  };
  public displayTime: string;

  // effect UI
  public actives: { hours: string, minutes: string };

  public activesHours: string;
  public activesMinutes: string;

  constructor() {
    this.generateHours();
    this.generateMinutes();
  }

  ngOnInit(): void {
    if (this._timePicker.getIsSave(this.key)) {
      this.getHistoryTime();
    } else {
      this.getDefaultTime();
    }
  }

  // open/close timpicker
  public showTimePicker() {
    this.isOpen = true;
  }

  public hiddenTimePicker() {
    this.isOpen = false;
  }

  public getTime() {
    this.timeTemp = {...this._timePicker.getTime(this.key)};
  }

  public setActives() {
    this.actives = {...this.timeTemp}; 
  }

  public getHistoryTime() {
    this.getTime();
    this.setActives(); 
    this.handleDisplayTime();
  }

  public getDefaultTime() {
    this._timePicker.setTime(this.key, this.defaultHour, this.defaultMinute);
    this.getTime();
    this.setActives();
    this.handleDisplayTime();
  }

  public handleDisplayTime() {
    this.displayTime = this.timeTemp.hours + ":" + this.timeTemp.minutes;
    this.setActives();
  }

  public handleApply() {
    this._timePicker.setTime(
      this.key,
      this.timeTemp.hours,
      this.timeTemp.minutes,
    );
    this._timePicker.setIsSave(this.key, true);
    this.hiddenTimePicker();
  }

  public handleCancel() {
    this.getTime();
    this.handleDisplayTime();
    this.hiddenTimePicker();
  }

  public selectNowDate() {
    let today = new Date();
    let m = today.getMinutes();
    let round5 = (m % 5) >= 2.5 ? parseInt('' + (m / 5)) * 5 + 5 : parseInt('' + (m / 5)) * 5;
    if (round5 === 60) {
      today.setHours(today.getHours() + 1, 0);
    } else {
      today.setMinutes(round5);
    }
    let minute = today.getMinutes() <= 9 ? `0${today.getMinutes()}` : today.getMinutes().toString();
    let hour = today.getHours() <= 9 ? `0${today.getHours()}` : today.getHours().toString();
    this.timeTemp.hours = hour;
    this.timeTemp.minutes = minute;
    this.actives = { hours: hour, minutes: minute };
    this.handleDisplayTime();
  }

  public selectHour(hour: string) {
    this.timeTemp.hours = hour;
    this.actives.hours = hour;
    this.handleDisplayTime();
  }

  public selectMinute(minute: string) {
    this.timeTemp.minutes = minute;
    this.actives.minutes = minute;
    this.handleDisplayTime();
  }

  public generateHours() {
    for (let i = 0; i <= 23; i ++) {
      if (i <= 9) {
        this.hoursGenerate.push(`0${i}`);
      } else {
        this.hoursGenerate.push(''+ i);
      }
    }
  }

  public generateMinutes() {
    for (let i = 0; i <= 55; i += 5) {
      if (i <= 9) {
        this.minutesGenerate.push(`0${i}`);
      } else {
        this.minutesGenerate.push('' + i);
      }
    }
  }
}

