<div class="flex justify-center items-center h-[calc(100vh-100px)] w-full !bg-palette-base-white rounded-md">
    <div class="flex flex-col h-full justify-center items-center">
        <img class="mt-5 max-w-[360px] max-h-[360px] h-[90%] overflow-hidden" src="assets/images/error/forbidden/relax.png" alt="">
        <div class="font-inter flex flex-col items-center !gap-4 !text-palette-gray-900">
            <p class="text-xl lg:text-[30px] font-semibold">{{'COMMON.FORBIDDEN.FORBIDDEN_TITLE' | translate}}
                ...</p>
            <p class="text-sm text-palette-gray-500 font-medium text-wrap px-2">
                {{'COMMON.FORBIDDEN.DESCRIPTION' | translate}}
            </p>
            <div class=" block md:flex space-x-3">
                <button (click)="onRefreshBtn()"
                class="button whitespace-nowrap font-semibold flex items-center space-x-2 justify-center !mt-3 h-11 border border-palette-blue-600 text-palette-blue-600 w-[160px] rounded-lg">
                <i class="sctr-icon-refresh-ccw-01 text-palette-blue-600 text-xl mr-2 "></i>
                {{'COMMON.REFRESH' | translate}}
                    
            </button>
                <button [routerLink]="['/booking/hotel']"
                class="button whitespace-nowrap flex items-center space-x-2 justify-center !mt-3 h-11 bg-palette-blue-600 text-white w-[160px] rounded-lg">
                {{'COMMON.GO_TO_HOME' | translate}}
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" class="ml-1 !w-5 !h-5 ">
                        <path
                            d="M11.6666 4.1665C11.4456 4.16654 11.2336 4.25436 11.0774 4.41064C10.9211 4.56692 10.8334 4.77886 10.8334 4.99984C10.8334 5.22083 10.9211 5.43277 11.0774 5.58904L14.6548 9.1665H3.33332C3.11231 9.1665 2.90035 9.2543 2.74407 9.41057C2.5878 9.56685 2.5 9.77881 2.5 9.99982C2.5 10.2208 2.5878 10.4328 2.74407 10.5891C2.90035 10.7453 3.11231 10.8331 3.33332 10.8331H14.6548L11.0774 14.4106C10.9211 14.5669 10.8334 14.7788 10.8334 14.9998C10.8334 15.2208 10.9211 15.4327 11.0774 15.589C11.2337 15.7453 11.4456 15.833 11.6666 15.833C11.8876 15.833 12.0995 15.7453 12.2558 15.589L17.2557 10.5891C17.3593 10.486 17.434 10.3574 17.4722 10.2164C17.4767 10.1665 17.4767 10.1164 17.4722 10.0666C17.4824 10.0448 17.4916 10.0225 17.4999 9.99988C17.4946 9.95307 17.4853 9.90679 17.4722 9.86153C17.4675 9.82143 17.4599 9.78172 17.4494 9.74272C17.4116 9.61846 17.3453 9.50476 17.2557 9.41069L12.2558 4.41075C12.0996 4.25446 11.8876 4.16654 11.6666 4.1665Z"
                            fill="white" />
                    </svg>
            </button>
            </div>
           
        </div>
    </div>
</div>