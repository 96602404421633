<div class="calendar-dashboard-container md:w-[280px]">
    <span class="w-full p-input-icon-right" (click)="calendarDashBoard.toggle($event)">
        <input pInputText class="h-9 min-h-0 truncate" placeholder="Select date" type="text" readonly
            [value]="dateString" />
        <i class="sctr-icon-calendar "></i>
    </span>
    <p-overlayPanel #calendarDashBoard styleClass="min-w-[453px] min-[1850px]:-ml-[173px]">
        <ng-template pTemplate="body">
            <div class="flex max-w-[453px] gap-4 px-2" (click)="$event.stopPropagation()">
                <div class="my-2 gap-2">
                    <ul class="flex flex-col">
                        <li class="cursor-pointer truncate rounded p-2 text-sm font-medium hover:bg-palette-gray-100"
                            *ngFor="let item of DF1" [ngClass]="
                DFActive === item.value
                  ? 'bg-palette-blue-50 text-palette-blue-600'
                  : 'hover:text-palette-blue-500'
              " (click)="filterDate(item)">
                            {{ "primeng." + item.value | translate | sentenceCase }}
                        </li>
                    </ul>
                    <hr />
                    <ul class="flex flex-col">
                        <li class="flex cursor-pointer items-center justify-between truncate rounded p-2 text-sm font-medium hover:bg-palette-gray-100"
                            *ngFor="let item of DF2" [ngClass]="
                DFActive === item.value
                  ? 'bg-palette-blue-50 text-palette-blue-600'
                  : 'hover:text-palette-blue-500'
              " (click)="filterDate(item)">
                            {{ "COMMON.BY" | translate | sentenceCase }}
                            {{ "primeng." + item.value | translate | sentenceCase }}
                            <i class="sctr-icon-chevron-right"></i>
                        </li>
                    </ul>
                </div>
                <div class="border-l">
                    <p-calendar class="calendar-dashboard" panelStyleClass="!border-0 w-full h-full"
                        [selectionMode]="selectionMode" [maxDate]="currentDate" [view]="viewDate"
                        yearRange="(1990:{{ currentYear }})" [(ngModel)]="selectionMode == 'range' ? rangeDates : date"
                        (ngModelChange)="onSelect($event)" [inline]="true" [showOnFocus]="false"></p-calendar>
                </div>
            </div>
        </ng-template>
    </p-overlayPanel>
</div>