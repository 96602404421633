<div class="w-[292px] h-[290px] bg-white relative rounded-t-lg">
    <div (click)="handleBookmark($event)"
        class="md:!w-7 md:!h-7 md:!p-2 flex justify-center items-center md:rounded-lg absolute top-2 right-2 cursor-pointer bg-[#10182866]">
        <i
            class="!text-base !leading-4"
            [ngClass]="{ 'sctr-icon-solid-bookmark text-palette-amber-400': isBookmarked, 'text-white sctr-icon-bookmark': !isBookmarked }">
        </i>
    </div>

    <img *ngIf="markerData.property_image_ids?.length" class="!h-[180px] !w-[292px] !rounded-t-lg"
        src="{{baseImageId}}/{{ markerData.property_image_ids[0]}}.webp" (error)="replaceWithDefaultImage($event)" />
    <img *ngIf="!markerData.property_image_ids?.length" class="!h-[180px] !w-[292px] !rounded-t-lg"
        src="{{markerData.property_image_overview_url}}" (error)="replaceWithDefaultImage($event)" />


    <!-- <img src="{{ markerData.property_image_overview_url || baseUrl + '/storage/files/web/' + markerData.property_image_ids[0] + '.webp' || 'assets/images/temporary/default-image.jpg'}}" class="!h-[180px] !w-[292px] !rounded-t-lg"> -->
    <div *ngIf="markerData" class="p-2 gap-[6px] flex flex-col">
        <div class="info w-[100%]">
            <div class="hotel-title flex justify-between">
                <div class="max-w-[90%] truncate">
                    <div class="flex">
                        <app-star [count]="markerData.star" starStyle="!gap-0.5"
                            iconStyle="sctr-icon-solid-star-01 text-palette-yellow-400 text-sm w-[14px] h-[14px]">
                        </app-star>
                    </div>
                    <a [routerLink]="genRouterLink(markerData.property_id, markerData.property_name, markerData.city_name)"
                        target="_blank"
                        [queryParams]="genQueryParams(markerData.property_id, markerData.property_name, markerData.city_name)"
                        class="hotel-name hover:opacity-75 cursor-pointer !text-palette-gray-900 font-semibold font-inter text-base leading-6 max-w-fit whitespace-nowrap overflow-hidden text-ellipsis">
                        {{markerData.property_name}}
                    </a>
                </div>
                <div *ngIf="markerData.average_rating_point"
                    class="text-sm leading-5 text-center font-semibold !w-[34px] !h-7 rounded-md py-1 px-[6px] text-palette-blue-600 border border-palette-blue-600">
                    {{markerData.average_rating_point}}
                </div>
            </div>
            <div>
                <div class="hotel-address flex gap-1 cursor-default">
                    <i class="sctr-icon-marker-pin-03 text-palette-gray-500 !text-base"></i>
                    <div class="flex items-center w-full text-sm font-medium font-inter text-palette-gray-500 relative">
                        <div class="max-w-[50%] truncate" [ngClass]="{'!max-w-[90%]': !markerData.distance_from_center}"
                            [isTruncated]="true" [tooltip]="markerData.address" [theme]="TooltipTheme.LIGHT"
                            [position]="TooltipPosition.BELOW">
                            {{markerData.address}}</div>
                        <span *ngIf="markerData.distance_from_center" class="relative flex items-center">
                            <span class="ml-1 text-sm xl:text-base text-palette-gray-500"> •</span>
                            <div class="ml-2 max-w-[70%] truncate text-palette-gray-500"
                                [tooltip]="distanFromCenterText" [theme]="TooltipTheme.LIGHT"
                                [position]="TooltipPosition.BELOW">
                                {{distanFromCenterText}}</div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="markerData.net_price" class="hotel-price flex justify-end">
            <span class="text-palette-gray-700 text-sm leading-5 font-inter font-medium">{{'PAYMENT.FROM' |
                translate}}</span>
            <span class="text-red-500 gap-[6px] text-base font-semibold ml-1">
                <app-price-format [price]="markerData.net_price"></app-price-format>
            </span>
        </div>
    </div>
</div>