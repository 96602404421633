import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByInteractions'
})
export class SortByInteractionsPipe implements PipeTransform {
  transform(posts: any[], interactionType: string): any[] {
    if (!posts || posts.length <= 1) {
      return posts;
    }
    return posts.sort((a, b) => b[interactionType] - a[interactionType]);
  }
}
