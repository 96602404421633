<div class="relative hot-deal-item-container rounded-lg cursor-pointer">
    <a 
        class="hot-deal-item rounded-lg cursor-pointer font-inter col-span-1"
        [routerLink]="genRouterLink(item)"
        [queryParams]="genQueryParams(item.property_id, item.city_name)"
    >
        <div>
            <div class="!p-2 image-container">
                <img *ngIf="item.property_image_overview_id" loading="lazy"
                    class="seo-image rounded-lg aspect-[16/9] w-full max-h-[118px] object-cover"
                    src="{{baseImageId}}/{{ item.property_image_overview_id }}.webp" (error)="replaceWithDefaultImage($event)" alt=" ">
                <img 
                    *ngIf="!item.property_image_overview_id"
                    loading="lazy"
                    class="seo-image rounded-lg aspect-[16/9] w-full max-h-[118px] object-cover"
                    src="{{ item.property_image_overview_url }}"
                    (error)="replaceWithDefaultImage($event)"
                    alt=" "
                >
                <div
                    class="discount-rate text-white px-[6px] py-1 rounded-tr-md rounded-bl-md absolute bottom-3 left-3 text-xs text-center font-semibold leading-4">
                    <p>-{{item.discount_percent}}%</p>
                </div>
            </div>
            <div class="bg-white !px-3 !pb-3 relative rounded-lg flex flex-col gap-2">
                <div class="flex flex-col gap-1">
                    <div class="relative h-[40px]">
                        <h1 
                            [tooltip]="item.property_name"
                            [isTruncated]="true"
                            [position]="TooltipPosition.BELOW"
                            class="seo-title property-name text-sm not-italic font-semibold leading-5 text-palette-gray-900"
                        >
                            {{ item.property_name }}
                    </h1>
                    </div>
                    <div class="flex items-center relative" (click)="openMap($event, item)">
                        <common-icon-text 
                            class="!w-full text-ellipsis overflow-hidden flex items-center"
                            [icon]="'sctr-icon-marker-pin-01'"
                            [iconStyle]="'text-base !leading-5 mr-1 opacity-70'"
                            [text]="item.address"
                            [textStyle]="'text-soctrip-small font-normal text-palette-gray-500 whitespace-nowrap text-ellipsis overflow-hidden'"
                            [dataCyValue]="'address-hotel'">
                        </common-icon-text>
                    </div>
                    <div class="h-4 grid grid-cols-8 !gap-2 [&>*:nth-child(n+9)]:hidden">
                        <ng-container *ngFor="let facility of renderAmenityList(item.property_amenity_list)">
                            <div class="icon-service col-span-1 !w-4 h-4 flex items-center">
                                <i class="amenity-icon sctr-icon-{{facility.amenity_icon}}"></i>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <!-- <div *ngIf="!isOverflowPrice" #containerElementRef class="flex items-center gap-[6px] h-6">
                    <app-price-format [price]="item.net_price"
                        [styleClass]="'seo-price text-palette-orange-dark-500 text-base font-medium !leading-6'"></app-price-format>
                    <app-price-format [price]="item.original_price"
                        [styleClass]="'seo-price text-palette-gray-500 text-sm whitespace-nowrap text-ellipsis overflow-hidden line-through'"></app-price-format>
                </div> -->
                <div class="flex flex-col items-end gap-[6px] !mt-[6px]">
                    <div>
                        <app-price-format 
                            [price]="item.original_price"
                            [styleClass]="'seo-saling-price text-palette-gray-500 text-sm whitespace-nowrap text-ellipsis overflow-hidden line-through'">
                        </app-price-format>
                    </div>
                    <app-price-format
                        [price]="item.net_price"
                        [styleClass]="'seo-price text-palette-orange-dark-500 text-base font-medium !leading-6'">
                    </app-price-format>
                </div>
            </div>
        </div>
    </a>
    <button
        class="btn-favorite flex justify-center !h-7 bg-palette-gray-900 bg-opacity-40 rounded-lg p-[6px] absolute top-2 right-2 text-center"
        (click)="handleBookmark($event)"
    >   
        <i
            class="!text-base !leading-4"
            [ngClass]="{ 'sctr-icon-solid-bookmark text-palette-amber-400': isBookmarked, 'text-white sctr-icon-bookmark': !isBookmarked }">
        </i>
    </button>
</div>

<app-toast keyToast="hd"></app-toast>