<div *ngIf="count" class="flex items-center" [ngClass]="starStyle">
    <div *ngFor="let star of [].constructor(getStarCount(count)); let i = index">
        <i [ngClass]="iconStyle"></i>
    </div>
    <div *ngIf="hasHalfStar(count)">
        <div class="relative">
            <i [ngClass]="iconStyle"></i>
            <div class="w-1/2 h-full bg-white absolute right-0 top-0"></div>
        </div>
    </div>
</div>