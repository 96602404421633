import { Component, OnInit } from '@angular/core';
import { TooltipPosition, TooltipTheme } from '@shared/enum/tooltip.enums';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {
  public position: TooltipPosition = TooltipPosition.DEFAULT;
  public theme: TooltipTheme = TooltipTheme.DEFAULT;
  public tooltip = '';
  public left = 0;
  public top = 0;
  public visible = true

  constructor() {}

  ngOnInit(): void {}
}
