<div class="font-inter my-3 px-2">
    <p class="chapter-title uppercase">
        TERMS AND CONDITIONS OF SERVICE USE FOR <br />
        ACCOMMODATION SERVICE SUPPLIERS ON SOCTRIP SYSTEM
    </p>
    <p class="chapter-title  mt-2 !text-lg">(Agreement)</p>
    <p class="chapter-section">1. DEFINITION</p>
    <p class='text-content'>1.1. <strong>Hahalolo:</strong> Interpreted as Hahalolo of USA Social Network and Travel
        Inc. and affiliated offices of Hahalolo of USA Social Network and Travel Inc..</p>
    <p class='text-content'>1.2. <strong>Soctrip System:</strong> Interpreted as an online E-commerce Exchange operating
        on social networking platforms through website Soctrip.com and Soctrip mobile apps provided &nbsp;by Hahalolo of
        USA Social Network and Travel Inc..&nbsp;</p>
    <p class='text-content'>1.3.<strong>&nbsp;Supplier:&nbsp;</strong>The person who provides and is responsible for the
        Accommodation Services on Soctrip System and permitted by Hahalolo to sell and use the services under the
        conditions stated in the Agreement.</p>
    <p class='text-content'>1.4.<strong>&nbsp;Customer:</strong> The person who buys products and services on Soctrip
        System agrees to the terms accepted by the Supplier in the Agreement.</p>
    <p class='text-content'>1.5. <strong>Business Account:&nbsp;</strong>The account created on Soctrip System by the
        Supplier to manage business operations.</p>
    <p class='text-content'>1.6. <strong>Business payment account:</strong> The account is formed after the Supplier
        registers a Business Account on Soctrip System. The Business Payment Account &nbsp;shows the available balance
        from the Supplier&rsquo;s revenue.</p>
    <p class='text-content'>1.7.<strong>&nbsp;Page</strong>: A Business Account can create multiple pages to set up a
        business for the type of Accommodation Services on Soctrip System.</p>
    <p class='text-content'>1.8. <strong>Available Balance</strong>: the content showing the actual value in the
        Supplier&apos;s Business Payment Account. After the Supplier completes service provision, if the Customer does
        not have any claim of Order value after deducting Service Fee, the System will switch from &quot;<strong>Pending
            Balance</strong> &quot; to &quot;<strong>Available Balance</strong> &quot;. Note that only in this section
        the Supplier has the right to manage the amount for private use and is allowed to withdraw that amount.</p>
    <p class='text-content'>1.9. <strong>Pending Balance</strong>: the content showing the value of orders the Supplier
        has obtained when confirming their agreement to provide Accommodation Services for Orders on Soctrip System.</p>
    <p class='text-content'>1.10. <strong>Frozen &nbsp;Balance:</strong> the content showing the value corresponding to
        the Accommodation Service in dispute between Supplier and Customer. Hahalolo will temporarily save the order
        value in this Section &nbsp;until the problem is &nbsp;resolved.</p>
    <p class='text-content'>1.11. <strong>Account locking</strong>: The Supplier&rsquo;s account that is put in a state
        of inactivity when the transactions in question show signs of violating the law and Hahalolo&apos;s Community
        Standards.</p>
    <p class='text-content'>1.12. <strong>Accommodation service:&nbsp;</strong>A business activity based on short-term
        accommodation, including but not limited to the following types of services: hotels, motels, resorts, villas,
        serviced apartments, B&amp;Bs (bed and breakfast), rooms for rent, cottages, ryokan, etc. (&quot;Hotel&quot;).
    </p>
    <p class='text-content'>1.13.<strong>&nbsp;Payment intermediary service</strong>: Interpreted as a payment service
        with payment intermediary service suppliers connected to Hahalolo. Customers use Accommodation Services and make
        online payments through the Payment Intermediary Service.</p>
    <p class='text-content'>1.14.<strong>&nbsp;Listed Price:</strong>&nbsp;Interpreted as the price fixed on Soctrip
        System in United States dollar (US dollar) or the type of Currency listed due to the choice of the Supplier
        (including Value-added tax (&ldquo;VAT&rdquo;) and fees, other taxes (if any) as prescribed by law).</p>
    <p class='text-content'>1.15. <strong>Service fee:</strong> The fee that the Supplier is obliged to pay Hahalolo
        when using services on Soctrip System, calculated based on Hahalolo&apos;s policy from time to time.</p>
    <p class='text-content'>1.16.<strong>&nbsp;Data:</strong> The amount of money collected if Accommodation &nbsp;
        Services Provision is completed towards the Customer (satisfying the regulation of 01 hours of response), when
        the Supplier does business on Soctrip System.&nbsp;</p>
    <p class='text-content'>1.17. <strong>Closing date:</strong> Data is closed on the last day of each month.</p>
    <p class='text-content'>1.18. <strong>Service completion:</strong> After the Customer finishes using the
        Accommodation Service (12:00 according to the general regulation) and no complaints arise before.&nbsp;</p>
    <p class='text-content'>1.19.<strong>&nbsp;Supplier&rsquo;s Revenue:</strong> The entire amount of money collected
        from transactions between the Supplier and the Customer on Soctrip System.</p>
    <p class='text-content'>1.20.<strong>&nbsp;Customer Service Center</strong>: A Center of Hahalolo of USA Social
        Network and Travel Inc., in charge of supporting, solving questions, and receiving feedback from the Customers
        and the Suppliers.</p>
    <p class='text-content border:none;'>1.21. <strong>Payment fee</strong>: The service fee incurred when the Customer
        makes payment via Payment Gateway or E-Wallet, Bank transfer fee, etc. (if any).&nbsp;</p>

    <p class="chapter-section">2. VERIFICATION OF BUSINESS ACCOUNT INFORMATION </p>
    <p class='text-content'>2.1. Hahalolo validates the Supplier&apos;s Business Account information to confirm that the
        Supplier is the owner of the Business Account.</p>
    <p class='text-content'>- If the Business Account is an organization: The Supplier is responsible for providing
        documents to prove its status as an organization according to the verification process on Soctrip System,
        including &nbsp;but not limited to Business Registration Certificate and other documents as prescribed by law.
    </p>
    <p class='text-content'>- If the Business Account is an individual: Hahalolo shall perform the same verification of
        information as Business Accounts as &nbsp;organizations. Accordingly, as an individual doing business on Soctrip
        System, Hahalolo shall verify through the Supplier&apos;s personal identification papers in accordance with the
        law.</p>
    <p class='text-content'>- Within 72 hours from the time the Supplier completes information verification, Hahalolo
        shall issue a notice of successful verification or request an update and resend the mentioned verification
        request. During the process of verifying Business Account information, Hahalolo shall refuse to verify when
        realizing that the inspection results of Business Account information provided to Hahalolo by the Supplier are
        incorrect or show signs of violating the law or Hahalolo&apos;s Community Standards.</p>
    <p class='text-content'>2.2. In order for the verification to take place swiftly, the Supplier is responsible for
        providing accurate information in Section 2.1.</p>
    <p class="chapter-section">3. PROCESS SETUP FOR ACCOMMODATION SERVICES</p>
    <p class='text-content'>3.1. As a Service Providing Party, Hahalolo is responsible for building and developing the
        Soctrip System such as: researching, designing, operating hardware and software equipment, connecting the
        internet, boosting the demand for Customers search &hellip;</p>
    <p class='text-content'>3.2. Hahalolo is not responsible for the services of Supplier including flight, insurance
        and other related services described on the Supplier&rsquo;s Page.</p>
    <p class='text-content'>3.3. Hahalolo is not responsible for any warranty (express or implied) as to the suitability
        or quality of the Accommodation Services informed by the Supplier on the Supplier&rsquo;s Page.</p>
    <p class='text-content'>3.4. The payment process between the Supplier and the Customer is established through
        Soctrip System. The Supplier shall be responsible for establishing information about their services as well as
        the transaction value between the Supplier and the Customer.</p>
    <p class='text-content'>3.5. Hahalolo shall update and supplement new regulations on its business activities to be
        consistent with current domestic and international laws, thereby ensuring the rights and obligations of the
        Customer and the Supplier when using services on Soctrip System.</p>

    <p class="chapter-section">4. LEGAL AND HONEST TRANSACTIONS </p>
    <p class='text-content'>4.1. The Supplier commits to ensuring that the Accommodation Services described on the Page
        are authorized services and comply with the provisions of relevant applicable laws, ensuring the quality of
        facilities and equipment when the Customer stays and uses the service exactly as described on the Business Page.
        The Supplier will be responsible for regularly updating accurate and complete information about the
        accommodation on Soctrip system, including detailed information about available rooms, published prices, booking
        conditions, all policies and other relevant information, and ensuring the accuracy of the information provided
        in accordance with the Contract and this Agreement. In the circumstance that the information provided by the
        Supplier is incorrect, incomplete or misleading, the Supplier agrees to compensate Party B for all actual
        damages incurred and to keep Party B from incurring any losses, any claim from a third party.</p>
    <p class='text-content'>4.2. The Supplier commits to taking responsibility after confirming the receipt of the
        Customer&apos;s booking request when receiving information on Soctrip System.</p>
    <p class='text-content'>4.3. The Supplier commits to taking responsibility for the time arrangement, advertising
        plan, promotion program as well as the conditions for applying vouchers and gifts on Supplier&apos;s
        Accommodation Services.</p>
    <p class='text-content'>4.4. The Supplier commits that when confirming the Customer&apos;s booking information on
        Soctrip System, the Supplier is responsible for assisting the Customer in correcting errors in personal
        information and attached requirements.</p>
    <p class='text-content'>4.5. The Supplier commits to ensuring that it is qualified for the business of Accommodation
        Services and other related Services as well as taking measures to ensure security and order, prevent social
        evils, and prevent and fight fires in accordance with current laws.</p>
    <p class='text-content'>4.6. The Supplier commits to using the payment method approved by Hahalolo in Section 9.2.3
        for providing Accommodation Services legally. Actions that are not allowed by applicable laws and
        Hahalolo&apos;s Community Standards, for example: money laundering, bribe giving/receiving, Customer frauds,
        etc. are strictly prohibited.</p>
    <p class='text-content'>4.7. The Supplier and/or its personnel must not perform any acts to gain improper benefits
        and/or engage in conduct that may cause loss of honesty, correctness and objectivity in transactions with
        Hahalolo includes but is not limited to: offering/approving/making bribes to Hahalolo&apos;s personnel (as well
        as their relatives and those who have close relationships with them) material/spiritual benefits such as money,
        property, valuable gifts, etc.</p>
    <p class='text-content'>4.8. The Supplier immediately notifies Hahalolo when detecting or having grounds to believe
        that there is an arising or potential violation of the above contents.</p>
    <p class="chapter-section">5. TRANSACTIONS TRACKING AND MONITORING </p>
    <p class='text-content'>5.1. Within <strong>1 hour </strong>after receiving booking completion information
        from Soctrip System, the Supplier is responsible for checking and confirming by logging into the Business
        Account:</p>
    <p class='text-content'>&emsp;5.1.1. The Supplier shall confirm that the Accommodation Service is available and make
        a reservation for the Customer according to the quantity and quality committed in the description on the Page.
        At the same time, the Supplier shall save valid documents such as invoices, bills related to the Accommodation
        Service for comparison in case of necessity.</p>
    <p class='text-content'>&emsp;5.1.2. If the Supplier is unable to provide the Accommodation Services, the Supplier
        must refuse to confirm the booking.&nbsp;</p>
    <p class='text-content'>5.2. In case exceeding the time limit specified in Section 5.1, the Supplier does not
        confirm the booking, the transaction shall be automatically canceled. Accordingly, if the transaction is
        canceled because the Supplier <strong>does not confirm from 2 times or more</strong> , Hahalolo has the right to
        hide or lock the Supplier&rsquo;s Business Account temporarily or permanently depending on the severity and
        density of violations according to Section 12.2.</p>
    <p class='text-content'>5.3. In case there is a problem with Soctrip System (Hahalolo is self-detected or received a
        notification from the Supplier, Customer) resulting in Hahalolo not recording the completion of Customer&apos;s
        payment, Hahalolo shall notify the Supplier and promptly fix the problem that was/is happening.</p>
    <p class="chapter-section">6. PRIVATE REQUEST </p>
    <p class='text-content'>6.1. The Supplier may request to remove the Business Account by written notice to Hahalolo.
        Notwithstanding such removal of the Business Account, the Supplier shall remain responsible and liable for any
        uncompleted transactions (arising before or after account removal) provided by the Supplier.</p>
    <p class='text-content'>6.2. The Supplier shall contact Hahalolo immediately after completing the uncompleted
        transactions in Section 6.1. Hahalolo is not responsible for any losses incurred in connection with such
        transactions.</p>
    <p class="chapter-section">7. INFORMATION SECURITY AND SYSTEM SAFETY </p>
    <p class='text-content'>7.1. The Supplier commits to using a Business Account to set up a Page that corresponds to
        their&nbsp; business. Hahalolo is not responsible for any content, features, security, services, privacy
        policies, or other practices of such products, websites or services. However, in the event that Hahalolo
        suspects, notices or receives report from users that the content, features, security, services, privacy
        policies, or other practices of the products, websites or services of the Supplier violates the terms, policies
        of Hahalolo and other legal regulations, Hahalolo shall have the right to apply measures to handle violations
        according to Section 12.2.</p>
    <p class='text-content'>7.2. The Supplier is responsible for the security of the Business Account that the Supplier
        has created on Soctrip System. If any unauthorized use of the Account, Username and/or Password is detected, the
        Supplier shall immediately notify Hahalolo for assistance to resolve. In the event that the Supplier encounters
        this problem without informing or delays in notifying Hahalolo, Hahalolo is not liable for any losses resulting
        from such a problem. When making notifications, the Supplier needs to prove that it is the legal owner of the
        Business Account by providing Hahalolo with the following documents: (i) Documents in Section 2.1, (ii) Service
        Provision Contract for Accommodation Service Suppliers on Soctrip System and other related documents (if any).
    </p>
    <p class='text-content'>7.3. The Supplier shall be solely responsible for all activities on the Page of the Business
        Account managed by the Supplier, Hahalolo is not responsible for any loss or damage arising from unauthorized
        use in connection with the Account, Username, and/or Password or from failure to comply with this security term.
    </p>
    <p class='text-content'>7.4. The Supplier does not use any program, tool or other form to interfere with Soctrip
        System or change data structure. It is strictly forbidden to distribute, propagate or promote any activity that
        interferes with, destroys or infiltrates the data of Soctrip System. Any violation shall be subject to the
        handling measures in Section 12.2.</p>
    <p class='text-content'>7.5. The Supplier agrees not to use Soctrip System in any manner that may damage, obstruct,
        disrupt, damage, interrupt, limit the functionality of software, hardware, steal ID, passwords, email addresses
        of others and Customers, other similar issues or use of telecommunications equipment that interferes with
        Soctrip System or the users (who has an account on Soctrip System).</p>
    <p class='text-content'>7.6. Hahalolo commits to using the Supplier&rsquo;s information to serve the
        Supplier&rsquo;s business and bring the best experience for the Supplier on Soctrip System.</p>
    <p class='text-content'>7.7. Hahalolo commits to taking technical and security measures to best limit and prevent
        unauthorized access to the Supplier&rsquo;s Business Account information on Soctrip System. However, the
        confidentiality of information through internet services is not absolute and the Supplier shall agree to accept
        the risks that may occur when transmitting information over the internet.</p>
    <p class='text-content'>7.8. Hahalolo commits the system shall be operated, updated and upgraded in a safe way,
        without affecting the use of users on Soctrip System. All information related to the Business Account provided
        by the Supplier to Hahalolo during the management and business activities on Soctrip System is kept confidential
        unless otherwise agreed by the Parties or differently stipulated by law.</p>
    <p class="chapter-section">8. PRIVACY </p>
    <p class='text-content'>The Supplier agrees to the contents related to the privacy specified in the
        <strong>&ldquo;Data Policy and Privacy&rdquo;</strong> on Soctrip System.</p>
    <p class="chapter-section">9. SERVICE FEE, REVENUE RECONCILIATION AND PAYMENT METHODS </p>
    <p class='chapter-section-2'>9.1. Service fee and other fees</p>
    <p class='text-content'>9.1.1. The Supplier acknowledges and agrees that Hahalolo may deduct the Service Fee and
        related fees from the service payment amount of money paid by the Customer.</p>
    <p class='text-content'>9.1.2. Hahalolo shall issue a receipt or VAT invoice&nbsp; for the fee paid by the Supplier
        to Hahalolo.</p>
    <p class='text-content'>9.1.3. Depending on the time, the Service Fee Schedule shall be applied accordingly.
        When&nbsp; there is a change in the Service Fee, Hahalolo shall notify the Supplier 30 (thirty) working days in
        advance.</p>
    <p class='text-content'>The Service Fee is calculated based on the total value of transactions made on SoctripSystem
        multiplied by the Service Fee Rate, specifically as follows:</p>
    <p class='text-content'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; A: Total value of
        successful transactions on Soctrip System.</p>
    <p class='text-content'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; B (%): The Service Fee
        Rate.</p>
    <p class='text-content'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Service fee = A x B</p>
    <p class='text-content'>&nbsp;</p>
    <!-- <p class='chapter-section-2'>TABLE 1: Service Fee Rate</p>
    <div class="pt-[10px] w-full text-center text-content">
        <div class="flex w-full text-[14px] min-[400px]:text-[17px]">
            <div class="w-1/3 px-2 py-3 font-semibold border border-palette-base-black ">Type of business </div>
            <div class="w-1/3 px-2 py-3 font-semibold border-y border-palette-base-black ">Service fee rate within first
                … months <br />
                (From the date of opening for sale)
            </div>
            <div class="w-1/3 px-2 py-3 font-semibold border border-palette-base-black ">Service fee rate after … months
            </div>
        </div>
        <div class="flex w-full text-[14px] min-[400px]:text-[17px] ">
            <div class="w-1/3 border-x border-b border-palette-base-black">
                <div class="h-1/2 px-2 py-3 border-b border-palette-base-black">Domestic Accommodation Service Supplier
                </div>
                <div class="h-1/2 px-2 py-3">
                    International Accommodation Service Supplier
                </div>
            </div>
            <div class="w-1/3 px-2 py-3 border-b border-palette-base-black">
                No charge
            </div>
            <div class="w-1/3 px-2 py-3 border-x border-b border-palette-base-black">
                …%
            </div>
        </div>

    </div> -->

    <p class='text-content'>9.1.4. Payment Fee</p>
    <p class='text-content'>- The payment fee will be calculated at 03% of the total value of the canceled
        transaction(s) and applied in the following cases:</p>
    <ul>
        <li class='text-content'>&emsp;+ In case the Supplier fails to comply with the regulations committed to
            Hahalolo, this fee shall be charged to the Supplier and Hahalolo is entitled to apply handling measures
            specified in Section 12.2;</li>
        <li class='text-content'>&emsp;+ In case the Supplier refuses to serve and/or cancels the Customer's Booking,
            this fee shall be charged to the Supplier;</li>
        <li class='text-content'>&emsp;+ In case after the end of Accommodation Services, if there is a dispute, or
            complaint leading to a refund to the Customer, the Payment Fee shall be borne by the Supplier.</li>
    </ul>
    <p class='text-content'>- In case the Customer cancels the booking, the payment fee shall be borne by the Customer.
    </p>
    <p class='text-content'>- In case the Supplier requests to withdraw an amount from Soctrip System, all payment fees
        arising from this request shall be borne by the Supplier.</p>
    <p class='text-content'>9.1.5. Cancellation fee</p>
    <p class='text-content'>(i) In case the Supplier cancels the booking after confirming to serve the Customer who has
        successfully made a reservation, a fee of 50% of the value of the canceled transaction&nbsp; shall be charged.
    </p>
    <p class='text-content'>(ii) In case the Customer makes a successful payment but requests to cancel the reservation,
        the cancellation fee will be applied according to the policy of the Supplier published on www.soctrip.com at the
        time of successful booking, the cancellation fee will be applied as follows:</p>

    <p class='text-content pl-3'>-&emsp;In case the Supplier does not apply a cancellation fee or does not specify the
        cancellation rate and fee, the Supplier shall not ask Hahalolo to pay the agreed order value of that room to the
        Supplier.</p>
    <p class='text-content pl-3'>-&emsp;In case the Supplier applies the cancellation fee as announced on
        www.soctrip.com, Hahalolo is obliged to pay the Supplier the amount of the cancellation penalty minus the
        service fee. Service fee rate specified in Section 9.1.3.</p>
    <p class='text-content pl-3'>-&emsp;The Supplier is not allowed to change any request after successful booking
        confirmation.</p>
    <p class='text-content pl-3'>-&emsp; In the event that the Supplier cannot serve that reservation, the Supplier is
        responsible for:</p>
    <p class='text-content pl-4'>+&emsp;Arranging 01 (one) other similar room or in a larger size in the same
        accommodation to serve the Customer without collecting any additional service fees other than the fee collected
        at the customer&rsquo;s reservation. The Supplier contacts the Customer to confirm the change at the time of
        room changes, responds to Hahaholo staff and confirms the changed information at Soctrip.com; or</p>
    <p class='text-content pl-4'>+&emsp;Arranging 01 (one) other similar room at another accommodation to serve the
        Customer. The Supplier contacts the Customer to confirm the change at the time of room changes, responds to
        Hahaholo staff and confirms the changed information at Soctrip.com.</p>

    <p class='text-content pl-3'>In case the Supplier cannot arrange a room, Hahalolo shall find a reservation for the
        Customer at another accommodation. The Supplier must bear all costs incurred related to the change of
        accommodation facilities, such as passenger transportation fee, room price difference, etc. In case Hahalolo is
        responsible for finding another accommodation for the Customer, the charge incurred on the room price difference
        will be deducted from the order value that Hahalolo paid to the Supplier.</p>
    <p class='chapter-section-2'>9.2. Payment </p>
    <p class='text-content'>9.2.1. General provisions on Payment</p>
    <p class='text-content'>- The Supplier shall commit to not asking the Customer to provide personal financial
        information such as bank accounts, credit card numbers, or login passwords on Soctrip System.</p>
    <p class='text-content'>- The time Hahalolo processes the Supplier&rsquo;s withdrawal order for the Supplier to
        receive payment from Hahalolo is in accordance with the value of the order after deducting Hahalolo's Service
        Fee and other fees (if any) specified in Section 9.4.3 of this Terms and Conditions.</p>
    <p class='text-content'>- Customers who pay for Accommodation Services on Soctrip System shall make payment in
        advance through a payment intermediary service.</p>
    <p class='text-content'>- Hahalolo shall collect a Service Fee from the Supplier as specified in the &ldquo;Service
        Fee&rdquo; section of this Agreement.</p>
    <p class='text-content'>- The Customer shall not bear any additional fees other than the Listed Price on the
        Supplier&rsquo;s Page unless the Customer requests to provide other services besides the content described by
        the Supplier or otherwise agreed by the Parties. In some cases, fees, charges and other service fees that the
        Supplier has not been able to set up using the features on Soctrip System, must be fully and specifically noted
        in the details of that Service. In addition, if the Customers have questions about the above fees, the Supplier
        has the responsibility to explain and specifically instruct the Customer on how to pay these fees outside of
        Soctrip System.</p>
    <p class='text-content'>- In case there is a problem related to payment processing, the Customer should contact the
        Supplier or Hahalolo's Customer Service Center for support. Upon receiving feedback, Hahalolo will assist in
        processing the Customer's payment with the payment intermediary service business Partner(s).</p>
    <p class='text-content'>- Payment of order value: Hahalolo will pay the Supplier for successful orders at the actual
        room rate after deducting service fees and other fees (if any) as agreed in this Agreement.</p>
    <p class='text-content'>- Hahalolo makes Payment by bank transfer right after the Supplier completes the service (at
        standard check-out time: 12:00 PM) without receiving any complaints from the Customer during the use of the
        service, and Hahalolo has received a "Withdrawal Order" from the Supplier. The two Parties will cross-check
        revenue and make payment according to the reconciliation and payment process specified in Section 9.4.1;</p>
    <p class='text-content'>- In case Hahalolo deploys a payment service in another form, Hahalolo will notify the
        Supplier via email/phone for coordination.</p>
    <p class='text-content'>9.2.2. Service completion</p>
    <p class='text-content'>- When completing the provision of Accommodation Services for Customers, the Supplier is
        responsible for confirming on Soctrip System. Hahalolo shall base on the time the Supplier confirms the
        completion to set up the system in accordance with the reconciliation process in Section 9.4.</p>
    <p class='text-content'>- After the Supplier completes the service without any Customer complaints, the transaction
        shall be automatically completed and the transaction value of the Supplier will be recorded in the "Available
        Balance" section (specified in the Reconciliation Section).</p>
    <p class='text-content'>- The Supplier&rsquo;s Business Payment Account on Soctrip System shall show the Available
        Balance from the Supplier&rsquo;s revenue. The Supplier may initiate withdrawals on SoctripSystem at any time.
        Withdrawal orders will be moderated by Hahalolo and paid according to the payment process of Soctrip System. The
        arising fees related to the withdrawal request are specified by Hahalolo in Section 9.1.4.</p>
    <p class='text-content'>9.2.3. Payment method</p>
    <p class='text-content'>Hahalolo supports the following payment methods:</p>
    <p class='text-content pl-4'>-&emsp;Online payment via Payment intermediary services connected with Hahalolo.</p>
    <p class='text-content pl-4'>-&emsp;Payment via VISA/MASTER cards.</p>
    <p class='text-content pl-4'>-&emsp;Payment via domestic ATM cards.</p>
    <p class='text-content pl-4'>-&emsp;Other payment methods (if any), in case there is a new payment method Hahalolo
        will notify the Supplier for information and implementation.</p>
    <p class='text-content'>9.2.4. Confirmation</p>
    <p class='text-content'>- Within <strong>1 hour </strong>of receiving the reservation request of the Customer, the Supplier is
        responsible for confirming/rejecting service.</p>
    <p class='text-content'>- After the above time, if the Supplier does not confirm, the reservation request of the
        Customer will be automatically canceled.</p>
    <p class='chapter-section-2'>9.3. Refund </p>
    <p class='text-content'>9.3.1. The Supplier is responsible for establishing and applying a refund policy to the
        Customers in providing Accommodation Services on Soctrip System, specifically as follows:</p>
    <p class='text-content'>- In case the Customer cancels on their own and the Supplier has confirmed the provision of
        Accommodation Services.</p>
    <p class='text-content'>- In case the Customer uses the Accommodation Services but it is not as described and makes
        a complaint, requesting a refund of the payment made to the Supplier.</p>
    <p class='text-content'>- In case the Supplier refuses to serve the Customer and/or cancels the Customer&rsquo;s
        booking, not excluding force majeure events.</p>
    <p class='text-content'>9.3.2. The Supplier commits that the refund policy is transparent, clear, reasonable, and
        has authentic grounds to ensure the interests of the Customers when booking or in case of disputes.</p>
    <p class='text-content'>9.3.3. The Supplier is responsible for providing Hahalolo with content of&nbsp; refund
        policies and updating it on Soctrip System upon request.</p>
    <p class='text-content'>9.3.4. Hahalolo may cancel the reservation at the request of the Customer when: (i) Hahalolo
        has confirmed with the Customer the cancellation fee according to the cancellation policy of The Supplier on
        Soctrip System at the time of receiving the request; and (ii) HHLL has emailed cancellation confirmation to The
        Supplier and the Customer.</p>
    <p class='text-content'>9.3.5. The time amount for Hahalolo to refund the Customer for the cases specified in
        Section 9.3.1 is as follows:</p>
        <div class="pt-[10px] w-full text-center text-content ">
            <div class="flex w-full  text-[14px] min-[400px]:text-[17px]">
                <div class="w-1/2 p-2 font-semibold border border-palette-base-black ">Area</div>
                <div class="w-1/2 p-2 border-y border-r border-palette-base-black "><span class="font-semibold">The time amount
                        Hahalolo carries out the refund for the Customer</span> (working day) 
                </div>
            </div>
            <div class="flex w-full  text-[14px] min-[400px]:text-[17px]">
                <div class="w-1/2 p-2 border-x border-b border-palette-base-black">
                    Domestic Customers <br /> (registered a payment account in the United States)
                </div>
                <div class="w-1/2 p-2 border-b border-r border-palette-base-black">
                    From 03 to 05
                </div>
            </div>
            <div class="flex w-full  text-[14px] min-[400px]:text-[17px]">
                <div class="w-1/2 p-2 border-x border-b border-palette-base-black">
                    International Customers <br /> (with only international payment accounts)
                </div>
                <div class="w-1/2 p-2 border-b border-r border-palette-base-black">
                    From 07 to 15
                </div>
            </div>
        </div>
    <p class='text-content'>9.3.6. In case Hahalolo has grounds to believe that the origin of the amount paid by the
        Customer in the online form on Soctrip System is illegal or related to a fraudulent transaction, Hahalolo shall
        notify the Supplier of the withholding of the amount related to that payment transaction to handle in accordance
        with the provisions of law.</p>

    <p class='chapter-section-2'>9.4. Revenue Reconciliation</p>
    <p class='text-content'>9.4.1. Hahalolo and the Supplier carry out the reconciliation once a month on the last day
        of the calendar month to agree on data for invoice issuance in accordance with the law and corresponding to the
        service fee that the Supplier shall pay to Hahalolo under this Agreement. The reconciliation of revenue from
        business activities of the Supplier shall be managed and checked by Hahalolo on the Business Payment Account on
        Soctrip System.</p>
    <p class='text-content'>9.4.2. Hahalolo shall manage the Customer's payment corresponding to the Supplier's order on
        the system through the status displayed at the "Business payment account" specified in Section 1 of the
        Agreement.</p>
    <p class='text-content'>9.4.3. Withdrawal</p>
    <p class='text-content'>- The Supplier has the right to request withdrawals displayed in the Available Balance
        section. Based on the Supplier&rsquo;s bank account number in the general information section of the Contract,
        Hahalolo shall make a payment through that account.</p>
    <p class='text-content'>- Hahalolo processing time for the Supplier's withdrawals: Hahalolo shall process the
        transfer payment to the Supplier within 24 hours after receiving a Withdrawal Order.</p>
    <p class='text-content'>&nbsp;- The minimum limit for a withdrawal from the Supplier's Business Payment Account is
        100 US dollars (One hundred United States dollars) and the maximum limit is the amount shown on the Available
        Balance.</p>
    <p class="chapter-section">10. INTELLECTUAL PROPERTY RIGHTS </p>
    <p class='text-content'>10.1. All content, trademarks, service marks, trade names, logos and other proprietary
        intellectual property (&ldquo;Intellectual Property&rdquo;) of Hahalolo are owned by Hahalolo and Third-Party
        Owners (if any).</p>
    <p class='text-content'>10.2. The Supplier is entitled to freely use Intellectual Property on Soctrip System in
        accordance with Section 10.3.</p>
    <p class='text-content'>10.3. The Supplier commits that the Intellectual Property posted and used by the Supplier on
        Soctrip System belongs to the Supplier or has the permission of a third party who is the owner or author of the
        Intellectual Property.</p>
    <p class='text-content'>10.4. The Supplier shall agree that Hahalolo is entitled to use the Intellectual Property
        that the Supplier displays on Soctrip System to carry out business activities.</p>
    <p class='text-content'>10.5. The Supplier commits to complying with the contents of this Agreement, other
        regulations of Soctrip System and the laws related to Intellectual Property when using or accessing Soctrip
        System.</p>
    <p class='text-content'>10.6. The Supplier shall agree not to copy, distribute, republish, transmit, publicly
        disclose, publicly perform, modify, reproduce, rent, sell, create derivative works or perform any further acts
        that may affect the Intellectual Property of Hahalolo or a third party without the permission of the owner
        and/or author of such Intellectual Property.</p>
    <p class='text-content'>10.7. When the Supplier violates the above limitation of intellectual property rights,
        Hahalolo has the right to apply the measures to handle the violation in Section 12.2.</p>
    <p class="chapter-section">11. TAXATION </p>
    <p class='text-content'>11.1. Hahalolo and the Supplier undertake that for taxes and other obligations as prescribed
        by applicable law arising from any Party's business activities on Soctrip System, that Party shall be solely
        responsible for declaring and fulfilling the obligations with competent authorities of the United States and the
        country of the Supplier (if any), unless otherwise provided for in this Agreement or as mutually agreed by the
        two Parties from time to time.</p>
    <p class='text-content'>11.2. The Supplier has to determine the taxes for the services they do business on their own
        and commits that the tax rate applied by the Supplier on the Listed Price is in accordance with current law.</p>
    <p class='text-content'>11.3. The Supplier and Hahalolo have the obligation to support, cooperate, and do revenue
        reconciliation in declaring invoices and financial statistics to avoid false declaration, fraud or tax evasion.
        Any violations of tax law by any Party, that Party shall take responsibility before the laws.</p>
    <p class='text-content'>11.4. In case Hahalolo suspects or is informed that the Supplier shows signs of
        non-cooperation, misrepresents taxes, fails to fully pay taxes to competent state agencies and/or commits other
        acts of violating tax laws, Hahalolo has the right to apply the measures to handle violations in Section 12.2
        until the matter has been resolved.</p>
    <p class="chapter-section">12. VIOLATION OF TERMS OF SERVICE </p>
    <p class='chapter-section-2'>12.1. Grounds for determining violations</p>
    <p class='text-content'>Hahalolo provides grounds for identifying signs of violation in order to control and limit
        the Supplier&rsquo;s violations that are detrimental to the Customer or Soctrip System. The Supplier must take
        responsibility for their violations as prescribed in Section 12.2, except for the case specified in clause
        12.1.5.</p>
    <p class='text-content'>12.1.1. The Supplier&rsquo;s Business Account and the Page managed by the Supplier are
        inactive for 30 consecutive days on Soctrip System.</p>
    <p class='text-content'>12.1.2. The Supplier&rsquo;s Business Account and the Page managed by the Supplier
        violate the Terms, Policies of this Agreement or the spirit of other Terms and Policies both inside and outside
        the Soctrip System.</p>
    <p class='text-content'>12.1.3. The Supplier's Business Account and the Page managed by the Supplier show illegal,
        fraudulent, harassing, infringing, threatening or abusive conduct of the Terms and Policies of this
        Agreement or other Terms and Policies inside and outside Soctrip System.</p>
    <p class='text-content'>12.1.4. The Supplier&rsquo;s behavior may be detrimental and/or harmful to Customers, other
        Suppliers, partners of Hahalolo or affect the economic interests, reputation, and brands of Hahalolo.</p>
    <p class='text-content'>12.1.5. In the event that Hahalolo receives a report that the Supplier&rsquo;s Business
        Account or the provision of the Supplier&rsquo;s Accommodation Services is managed, used by a third party (other
        than Hahalolo) and has grounds to demonstrate that this action is not executed by the Supplier.</p>
    <p class='text-content'>12.1.6. The Supplier provides Accommodation Services to Customers with inaccurate
        quality as described at the Supplier&rsquo;s Page displayed on Soctrip System.</p>
    <p class='text-content'>12.1.7. The Supplier provides the actual Accommodation Services that are not in line
        with the Listed Price on the Supplier&rsquo;s Page displayed on Soctrip System.</p>
    <p class='text-content'>12.1.8. The Supplier has collusion with the Customer or a third party in order to profit,
        defraud and affect the interests of Hahalolo or other Customers.</p>
    <p class='text-content'>12.1.9. Violations have been specifically stated in the sections of this Agreement as well
        as the provisions of applicable law.</p>
    <p class='chapter-section-2'>12.2 Violation Handling Measures</p>
    <p class='text-content'>Hahalolo based on violations of the Supplier when they provide Accommodation Services on
        Soctrip System and the level and density of violations, the Supplier shall be applied one or more of Hahalolo's
        handling measures as well as shall not get fees refunded (including service or advertising fees, if applicable).
        Hahalolo's remedies include, but are not limited to, the following actions:</p>
    <p class='text-content'>12.2.1. Hide Accommodation Services category on Business Page.</p>
    <p class='text-content'>12.2.2. Hide or remove Business Page(s) managed by the Supplier.</p>
    <p class='text-content'>12.2.3. Explain in writing when a dispute or complaint arises.</p>
    <p class='text-content'>12.2.4. Refund to Customers the fees that the Supplier has obtained through illegal profit
        collection.</p>
    <p class='text-content'>12.2.5. Apply for civil action, including but not limited to, claim for damages and/or apply
        for interim relief.</p>
    <p class='text-content'>12.2.6. Press criminal charges.</p>
    <p class="chapter-section">13. TERMINATION OF SERVICE USE </p>
    <p class='text-content'>13.1. The Supplier has the right to suspend or terminate cooperation by notifying via
        Hahalolo's Customer Service Center&rsquo;s email address (support@soctrip.com). At that time, the Supplier must
        be responsible for monitoring and resolving backlogs of conflicts of interest (if any) with Hahalolo and
        Customers arising during the previous service use to ensure Hahalolo does not bear any responsibility related to
        the obligations between the Supplier and the Customers.</p>
    <p class='text-content'>13.2. In case the Supplier suspends but does not notify, resulting in damage to Hahalolo,
        depending on the extent of damage proven, the Supplier must compensate Hahalolo for damage.</p>
    <p class="chapter-section">14. FORCE MAJEURE EVENT </p>
    <p class='text-content'>14.1. One Party is not liable to the other Party for the failure to perform its obligations
        due to force majeure events. When encountering a force majeure event, either Party is responsible for notifying
        the other Party of any event that may cause any delay or non-performance. According to the provisions of the law
        and Hahalolo, the following events are considered force majeure events:</p>
    <p class='text-content'>- Natural disaster, fire or accident;</p>
    <p class='text-content'>- War or threat of war, sabotage, insurrection, civil riot or expropriation, act of
        terrorism;</p>
    <p class='text-content'>- Actions, restrictions, regulations, bans or measures of any kind on the part of a
        governmental, Congressional&nbsp; or local authority;</p>
    <p class='text-content'>- Traffic disruption, strike or other industrial action or commercial dispute (whether
        involving employees of that Party or a third Party);</p>
    <p class='text-content'>- Diseases announced by the World Health Organization or the United States competent
        authorities.</p>
    <p class='text-content'>14.2. After the occurrence of any of the events mentioned in Section 14.1, the affected
        Party has the right to completely suspend part of the provision of the Accommodation Services or perform other
        obligations under this Agreement while the event or situation occurs. Within 05 (five) days from the date of the
        force majeure event, the affected Party must notify in writing or email to the unaffected Party and/or related
        parties (if any) to cooperate, discuss and solve problems. In case the affected Party fails to notify or notify
        late and causes damage, depending on the extent of damage that the remaining parties can prove, the aggrieved
        Party must compensate the other Parties.</p>
    <p class='text-content'>14.3. If any of the events in Section 14.1 takes place more than 30 (thirty) working days,
        the unaffected party may immediately terminate this Agreement upon written or electronic notice (Email) to the
        affected party.</p>
    <p class='text-content'>14.4. In case the Supplier violates their&nbsp; responsibilities in Section 14.2, Hahalolo
        has the right to apply measures to handle violations in Section 12.2.</p>
    <p class="chapter-section">15. APPLICABLE LAW AND DISPUTES </p>
    <p class='chapter-section-2'>15.1. Applicable law</p>
    <p class='text-content'>15.1.1. These Terms of Service&nbsp; shall be governed, construed in accordance with the law
        of the State of Florida, USA.&nbsp;</p>
    <p class='text-content'>15.1.2. Any dispute, controversy, claim or disagreement of any kind arising from these Terms
        of Service&nbsp; and/or relating to these Terms of Service&nbsp; and/or against any Indemnified Party subject to
        these Terms of Service&nbsp; will be settled at the Vietnam International Arbitration Center (VIAC).</p>
    <p class='text-content'>15.1.3. The language of the Arbitration Centre's award is English. The Supplier agrees that
        the version in another language (if any) prepared is a translation of the English version. If there is any
        difference between the translation and the original, it will be considered as a translation error and will be
        resolved by referring to the original English version.</p>
    <p class='chapter-section-2'>15.2. Dispute resolution</p>
    <p class='text-content'>15.2.1. In case there is a problem related to the Supplier&rsquo;s service provision to the
        Customer, if the problem is internal between the Supplier and the Customer, does not violate any terms or
        policies of Hahalolo, and does not occur due to any error on Soctrip System, in the extent permitted, Hahalolo
        can only consider whether the Supplier&rsquo;s terms and policies violate the law and/or whether or not the
        Customer's request and problem is reasonable. If the issue is not resolved by the agreement of the two Parties,
        either Party can complain to Hahalolo or the local competent authority to resolve the dispute arising from the
        service of the Supplier.</p>
    <p class='text-content'>15.2.2. In the event that a problem related to the provision of accommodation services
        arises that is not due to the fault of either Party but due to a force majeure event, the arising Party must
        prove that the reason for the force majeure event is accurate and true to reality, damage does occur (if any).
        In the event of a dispute arising, Hahalolo in the role of an Intermediary allows the Parties to agree to apply
        their own policies to resolve the force majeure event. In the event that the agreement between the Parties does
        not resolve the issue and/or the force majeure remedy proposed by the Party does not satisfy the current legal
        provisions, one of the Parties may file a complaint to the local competent authority to settle the arising
        dispute. Suppose a Party is in a force majeure situation but does not notify it, causing misunderstandings about
        the reputation and quality of services, causing consequences for the other Parties, to the extent that the
        damage can be proved, in that case, the damaged Party shall have the right to request the application of civil
        measures to settle.</p>
    <p class='text-content'>15.2.3. In case the problem arises due to the error of Soctrip System, the payment service
        error of Hahalolo's Affiliated Companies and/or other Hahalolo's errors that the Supplier and Customer can
        prove, the Supplier and the Customer are responsible for promptly notifying Hahalolo for settlement. In the
        event of a dispute, the Supplier and the Customer commit to agreeing that they will not initiate legal
        proceedings but will instead enter into an agreement with Hahalolo or Hahalolo's Affiliated Companies (in the
        case of any transaction made on Soctrip System or any dispute related to other Suppliers of Hahalolo).</p>
    <p class='text-content'>15.2.4. In case there is a request to handle a Customer's complaint and/or a request from
        Hahalolo but after 48 hours since receiving the notice of complaint, the Supplier does not have any response,
        Hahalolo will decide that the complaint from the Customer is due to the Supplier's fault and they&nbsp; must
        refund to the Customer.</p>
</div>