import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {
  transform(value: any, format: string = 'dd MMM yyyy'): string {
    if (!value) return '';

    const date = new Date(value);

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    format = format.replace('dd', this.formatDigit(day));
    format = format.replace('MM', this.formatDigit(month));
    format = format.replace('yyyy', year.toString());

    return format;
  }

  private formatDigit(value: number): string {
    return value.toString().padStart(2, '0');
  }
}
