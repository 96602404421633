import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-rating-score',
  templateUrl: './rating-score.component.html',
  styleUrls: ['./rating-score.component.scss']
})
export class RatingScoreComponent {
  @Input() rating?: number;
  @Input() style: string;
  @Input() tooltipStyle: string;
  showScoreTooltip = false;
}
