import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MapSearchService } from '@modules/hotel-booking/services/map-search/map-search.service';
import { DetailHotel } from '@modules/hotel-booking/models/view-details';
import { createSlug } from '@shared/utils/helper';
import { ActivatedRoute, Router } from '@angular/router';
import { BookMarkService } from '@modules/hotel-booking/services/bookmark/bookmark.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TooltipPosition, TooltipTheme } from '@shared/enum/tooltip.enums';
import { SearchPropertyControllerV2Service, SearchRoomControllerService } from '@booking/angular-booking-property-search-service';
import { SearchBarService } from '@modules/hotel-booking/services/search-bar.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { DEFAULT_SEARCH_PARAMS } from '@shared/constant';
import { take } from 'rxjs';
import { environment } from '@env/environment';
import { PlaceType } from '@shared/enum/recommend-place.enum';
import { MessageService } from 'primeng/api';
import { ToastType } from '@shared/enum/toast-type';
import { SearchService } from '@modules/hotel-booking/services/search/search.service';

@Component({
  selector: 'map-marker-popup',
  templateUrl: './map-marker-popup.component.html',
  providers: [MessageService, TranslateModule],
  styleUrls: ['./map-marker-popup.component.scss'],
})
export class MapMarkerPopupComponent implements OnInit {
  markerData: DetailHotel;
  distanFromCenterText: string = '';
  netPrice: number;
  TooltipPosition: typeof TooltipPosition = TooltipPosition;
  TooltipTheme: typeof TooltipTheme = TooltipTheme;
  queryParams: any;
  baseUrl = environment.BASE_PATH;
  baseImageId = environment.STORAGE_SERVICE_API_BASE_PATH_ID_IMAGE;
  propertyId: any;
  isBookmarked: boolean;
  isLogin: boolean | null;

  constructor(
    private mapSearchService: MapSearchService,
    private route: ActivatedRoute,
    private bookMarkService: BookMarkService,
    private translateService: TranslateService,
    private searchRoomControllerService: SearchRoomControllerService,
    private searchResultService: SearchBarService,
    private userService: UserService,
    private messageService: MessageService,
    private searchService: SearchService,
    private searchPropertyControllerV2Service: SearchPropertyControllerV2Service,
  ) {
    this.searchResultService.getQueryParamData().subscribe((res) => {
      this.queryParams = res;
    });
  }

  ngOnInit(): void {
    this.checkIsLogin();
    this.getMarker();
    this.receiveSignalRecentSavedList();
  }

  receiveSignalRecentSavedList() {
    this.bookMarkService.recentSavedList$.asObservable().subscribe(data => {
      if(!data) return;
      if(data.type === 'sync-marker') {
        this.isBookmarked = data.value;
      }
    });
  }

  checkIsLogin() {
    this.userService.getLoginStatus().subscribe(isLogin => {
      this.isLogin = isLogin;
    });
  }

  checkBookmark() {
    if(!this.markerData.property_id) return;
    this.bookMarkService.isBookmarkExists(this.markerData.property_id).subscribe({
      next: (isBookmarked) => {
        this.isBookmarked = isBookmarked;
      }
    });
  }

  handleBookmark(event: any) {
    event.stopPropagation();
    if(!this.isLogin) {
      this.messageService.add({
        key: 'fh',
        severity: ToastType.WARNING,
        detail: 'Please login before bookmark',
      });
      return;
    }
    if(this.isBookmarked) {
      this.bookMarkService.removeBookMark(this.markerData.property_id).subscribe({
        next: (response) => {
          if(response.success) {
            this.isBookmarked = false;
            this.bookMarkService.getBloomFilter();
            this.bookMarkService.recentSavedList$.next({ type: 'sync-list', value: this.isBookmarked });
            this.messageService.add({
              key: 'loh',
              severity: ToastType.SUCCESS,
              detail: this.translateService.instant('BOOKMARK.DELETE'),
            });
          }
        },
      });
    } else {
      this.bookMarkService.addBookMark(this.markerData.property_id).subscribe({
        next: (response) => {
          if(response.success) {
            this.isBookmarked = true;
            this.bookMarkService.getBloomFilter();
            this.bookMarkService.recentSavedList$.next({ type: 'sync-list', value: this.isBookmarked });
            this.messageService.add({
              key: 'loh',
              severity: ToastType.SUCCESS,
              detail: this.translateService.instant('BOOKMARK.ADD'),
            });
          }
        },
      });
    }
  }

  getRoomPrice() {
    const body = { user_id: this.userService.getUserUuid() };
    this.searchRoomControllerService
      .getRoomsByProperty(
        DEFAULT_SEARCH_PARAMS.checkin,
        DEFAULT_SEARCH_PARAMS.checkout,
        DEFAULT_SEARCH_PARAMS.num_of_rooms,
        DEFAULT_SEARCH_PARAMS.num_of_children,
        DEFAULT_SEARCH_PARAMS.num_of_adults,
        DEFAULT_SEARCH_PARAMS.currency,
        this.queryParams?.property_id,
        body,
        DEFAULT_SEARCH_PARAMS.page_num,
        DEFAULT_SEARCH_PARAMS.page_size
      )
      .subscribe({
        next: (res) => {
            if (res.success) {
              const { rooms } = res.data;
              let minNetPrice = Infinity;
              for (const room of rooms) {
                const netPrice = room.rate_plan[0].net_price;
                if (netPrice < minNetPrice) {
                  minNetPrice = netPrice;
                }
              }
              this.netPrice = minNetPrice;
          }
        },
        complete: () => {
        }
      });
  }

  getMarker() {
    if (
      this.queryParams?.dest_type === PlaceType.PROPERTY.toUpperCase() &&
      this.queryParams?.property_id
    ) {
      this.getRoomPrice();
    }
    this.mapSearchService
      .getMarker()
      .pipe(take(1))
      .subscribe((res) => {
        this.distanFromCenterText =
          `${res.distance_from_center?.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })} ` + this.translateService.instant('DETAIL.KM_FROM_CENTRE');
        this.markerData = res;
        this.checkBookmark();
      });
  }

  genRouterLink(id: string, name: string, city_name: string) {
    let cityNameSlug = createSlug(city_name);
    if (cityNameSlug == '' || !cityNameSlug) {
      cityNameSlug = 'property';
    }
    let hotelNameSlug = createSlug(name);
    if (hotelNameSlug == '' || !hotelNameSlug) {
      hotelNameSlug = 'hotel-name';
    }
    return `/booking/hotel/search/${cityNameSlug}/${hotelNameSlug}`;
  }

  genQueryParams(id: string, name: string, city_name: string) {
    const queryParams = { ...this.route.snapshot.queryParams, property_id: id, destination_name: city_name, };
    return queryParams;
  }

  replaceWithDefaultImage(event: any): void {
    event.target.src = 'assets/images/temporary/default-hotel.png';
  }
}
