import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  //TODO: Replace with actual code
  basePath = 'http://172.16.8.193:3000/v1/admin';

  constructor(private http: HttpClient) {}

  login(login: any) {
    //TODO: Replace with actual code
    return this.http.post(`${this.basePath}/auth/login`, login);
  }

  refreshToken(refreshToken: any): Observable<any> {
    //TODO: Replace with actual code
    return this.http.post(`${this.basePath}/auth/refresh`, refreshToken);
  }

  getUserInfo() {
    //TODO: Replace with actual code
    return this.http.get(`${this.basePath}/auth/user-info`);
  }
  isTokenExpired(): boolean {
    const access_token_expiry_date = localStorage.getItem(
      environment.ACCESS_TOKEN_EXPIRY_KEY
    );
    const refresh_token_expiry_date = localStorage.getItem(
      environment.REFRESH_TOKEN_EXPIRY_KEY
    );
    if (access_token_expiry_date && refresh_token_expiry_date) {
      const refreshTokenExpiry = new Date(access_token_expiry_date);
      const accessTokenExpiry = new Date(refresh_token_expiry_date);
      const currentDate = new Date();
      return (
        refreshTokenExpiry < currentDate || accessTokenExpiry < currentDate
      );
    }

    return false;
  }
}
