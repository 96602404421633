<p-dialog [(visible)]="visible" [style]="{ width: '708px', 'max-height': '80vh' }" [modal]="true"
  class="custom-dialog " [draggable]="false" [resizable]="false"
  (onHide)="onDialogHide()" (onShow)="onShowDialog()">
  <ng-template pTemplate="header" class="relative">
    <div class="w-full flex items-center gap-[18px]">
      <span class="text-xl font-semibold font-inter text-palette-gray-900">{{'POST.CREATE_PORT' | translate}}</span>
    </div>
    <div class="background-blob-left absolute top-[0] right-14">
      <img src="assets/icons/booking_detail/vector.svg" />
    </div>
    <div class="background-blob-left absolute top-14 right-0 z-0">
      <img src="assets/icons/booking_detail/vector-2.svg" />
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="py-0 px-0">
      <form [formGroup]="postForm" (ngSubmit)="onSubmit()" id="postForm">
        <div class="p-3 min-h-[120px] ">
          <div class="gap-2 flex justify-between w-full border bg-white border-pale rounded-xl p-3">
            <div class="relative box-avatar w-10 min-w-[40px]  max-w-[40px] h-10 min-h-[40px]">
              <div class="rounded-full flex justify-center items-center h-full w-full ">
                <img
                  class="rounded-full w-[34px] min-h-[34px] h-[34px] min-w-[34px]"
                  src="{{baseUrl}}/storage/files/web/{{userInfo.avatar_thumbnail_url}}.webp"
                  alt=""
                  (error)="replaceAvatarWithDefaultImage($event)">
              </div>
              <div class="absolute flex justify-center min-h-[12px] min-w-[12px] max-h-[12px] max-w-[12px] rounded-full
             bg-white items-center top-0 right-0 -mr-1">
                <img class="min-h-[9px] max-h-[9px] min-w-[9px] max-w-[9px]" src="assets/images/home/icon/tier.svg"
                  alt="">
              </div>
            </div>

            <textarea class="w-full focus:outline-none self-start h-24 resize-none ml-[6px]" formControlName="content"
              id="content" placeholder="{{'POST.HI_SHARE_YOUR_FEEL' | translate: { userName: userInfo.full_name } }}">
            </textarea>
            <div class="flex justify-content-center">
              <button class="self-end" type="button">
                <img alt="logo" src="assets/icons/booking_detail/emoji-solid.svg" width="100%" />
              </button>
            </div>
          </div>

        </div>
      </form>
      <div class=" min-h-[150px] w-full px-3 font-inter ">
        <div
          class="flex  w-full flex-col justify-center gap-1 rounded-xl border bg-palette-gray-50 border-gray-200 py-3">
          <div>
            <div class="flex h-[44px] w-full items-center gap-1 px-3 space-x-1 text-16 font-semibold">
              <div
                class="rounded-full flex justify-center items-center min-h-[40px] h-[40px] min-w-[40px] max-w-[40px]">
                <img *ngIf="dataDetailHotel.property_image_overview_id" class="rounded-full min-h-[40px] h-[40px] min-w-[40px] max-w-[40px]" alt=""
                  src="{{baseImageId}}/{{dataDetailHotel.property_image_overview_id}}" (error)="replaceWithDefaultImage($event)">
                <img *ngIf="!dataDetailHotel.property_image_overview_id" class="rounded-full min-h-[40px] h-[40px] min-w-[40px] max-w-[40px]" alt="" src="{{dataDetailHotel.property_image_overview_url}}" (error)="replaceWithDefaultImage($event)">
              </div>
              <div>
                <div class="text-palette-gray-900 text-base font-semibold ">
                  {{dataDetailHotel.property_name}}

                </div>
                <div class="text-sm text-palette-gray-500 font-normal">
                  {{dataDetailHotel.property_type_name}} - {{dataDetailHotel.city_name}}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div #hotelDescription *ngIf="dataDetailHotel.property_description"
              class="text-sm px-3 sm:text-base hotel-description-content"
              [ngClass]="isExpanded ? 'line-clamp-none':'line-clamp-3'"
              [innerHTML]="formatBreakLine(dataDetailHotel.property_description)">
            </div>
            <div class="hotel-detail-about !px-4">
              <p-editor [(ngModel)]="description" [readonly]="true"></p-editor>
            </div>
            <button *ngIf="isLongText"
              (click)="onExpandText()"
              class="text-palette-blue-700 text-sm font-semibold !mt-1 hover:underline !px-4">
              {{ (isExpanded ? 'COMMON.SHOW_LESS' : 'COMMON.VIEW_MORE') | translate }}
            </button>
            <div class="pt-2 relative overflow-hidden">
              <img *ngIf="dataDetailHotel.property_image_overview_id" class="rounded-t-xl !object-cover" src="{{baseImageId}}/{{dataDetailHotel.property_image_overview_id}}" alt=""
                (error)="replaceWithDefaultImage($event)">
              <img *ngIf="!dataDetailHotel.property_image_overview_id" class="rounded-t-xl !object-cover" src="{{dataDetailHotel.property_image_overview_url}}" alt="" (error)="replaceWithDefaultImage($event)">
              <div class="background-opacity absolute bottom-0 w-full z-10"></div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="footer" class="relative">
    <div class="pt-3 flex items-center space-x-2 justify-end w-full">
      <p-dropdown [options]="myOptions" optionLabel="label" class="custom-dropdown-post w-40"
        (onChange)="onChangePriority($event.value.value)">
        <ng-template let-option pTemplate="selectedItem">
          <div class="flex items-center text-sm px-2 w-fit space-x-2 h-[40px] ">
            <i [ngClass]="option.icon" class="text-xl text-gray-600"></i>
            <span class="font-inter">{{ option.label | translate}}</span>
          </div>
        </ng-template>
        <ng-template let-option pTemplate="item">
          <div class="flex items-center text-sm space-x-2 h-[40px] px-3">
            <i [ngClass]="option.icon" class="text-xl text-gray-600"></i>
            <span class="font-inter">{{ option.label | translate}}</span>
          </div>
        </ng-template>
      </p-dropdown>
      <!-- <button class=" text-white text-sm font-bold py-2 justify-center font-inter rounded-lg h-[40px] max-w-[100px] min-w-[100px] {{
          shouldDisableButton() ? 'bg-blue-200 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'
        }}" [disabled]="shouldDisableButton()" type="submit" form="postForm">
        {{"POST.POST" | translate}}
      </button> -->
      <button 
        class="text-white text-sm font-bold py-2 justify-center font-inter 
                 rounded-lg h-[40px] max-w-[100px] min-w-[100px] bg-blue-600 hover:bg-blue-700"
        type="submit"
        form="postForm"
      >
        {{"POST.POST" | translate}}
      </button>
    </div>
  </ng-template>
</p-dialog>