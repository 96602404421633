<div
  (mouseenter)="showScoreTooltip = true"
  (mouseleave)="showScoreTooltip = false"
  class="flex justify-center items-center rounded-md font-inter font-semibold"
  [ngClass]="style"
>
  {{ rating }}
  <div *ngIf="showScoreTooltip"
    class="absolute z-[9999] rounded bg-palette-gray-600 bg-opacity-90 p-2 text-center text-xs font-inter font-normal text-palette-base-white shadow"
    [ngClass]="tooltipStyle"
    >
    The score is reviewed by customers who are experienced in this property
  </div>
</div>
