import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  public priceCacheBehaviorSubject = new BehaviorSubject<any>([]);
  public isLoadingPriceCacheBehaviorSubject = new BehaviorSubject<boolean>(true);
  public isLoadingMarker = new BehaviorSubject<boolean>(true);
  statusSearchBehaviorSubject = new BehaviorSubject<number | null>(null);

  setPriceCache(data: any) {
    this.priceCacheBehaviorSubject.next(data);
  }

  getStatus() {
    return this.statusSearchBehaviorSubject.asObservable();
  }

  setIsLoadingMarker(data: any) {
    this.isLoadingMarker.next(data);
  }

  getIsLoadingMarker() {
    return this.isLoadingMarker.asObservable();
  }

  setStatus(status: number | null) {
    this.statusSearchBehaviorSubject.next(status);
  }

  getPriceCache() {
    return this.priceCacheBehaviorSubject.asObservable();
  }

  setIsLoadingPrice(data: boolean) {
    this.isLoadingPriceCacheBehaviorSubject.next(data);
  }

  isLoadingPrice() {
    return this.isLoadingPriceCacheBehaviorSubject.asObservable();
  }

  searchInArray(query: string, data: any[]): any[] {
    query = query.toLowerCase();
    return data.filter((item) => item.name.toLowerCase().includes(query));
  }

  constructor() {}
}
