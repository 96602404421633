import { Component, HostListener, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FooterService } from '@modules/hotel-booking/services/footer/footer.service';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { SoctripTranslationService } from 'src/app/services/soctrip-translation/soctrip-translation.service';
import { environment } from '@env/environment';
import { SharedModule } from '@shared/shared.module';
import { BOOKING } from '@shared/constants/routes';
import { UserService } from 'src/app/core/services/user/user.service';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    TranslateModule,
    DialogModule,
    DropdownModule,
    SharedModule,
  ],
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  isShowFooter: Boolean;
  visiblePopupChangeLanguage = false;
  disableSearchButtonApply: boolean = true;
  countries: any[] = [];
  languages: any[] = [];
  currencies: any[] = [];

  selectedCountry: any;
  selectedLanguage: any;
  selectedCurrency: any;
  currentCountry: any;
  currentLanguage: any;
  currentCurrency: any;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private userService: UserService,
    private footerService: FooterService,
    private soctripTranslationService: SoctripTranslationService
  ) {
    this.subscribeForm = this.fb.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}'
          ),
          this.emailValidator,
        ],
      ],
    });
  }
  ngOnInit(): void {
    this.initData();
    this.currentYear = new Date().getFullYear();
    this.userService.getUserProfile().subscribe((user) => {
      if (user) {
        if (
          user.language &&
          environment.support_languages.includes(user.language.toLowerCase())
        ) {
          const lang = user.language.toLowerCase();
          this.selectedLanguage = this.languages.find(
            (item: any) => item.code === lang
          );
        } else {
          this.selectedLanguage = this.languages[0];
        }
        // if (user.currency) {
        //   this.selectedCurrency = this.currencies.find(
        //     (item: any) => item.code === user.currency?.toUpperCase()
        //   );
        //   this.footerService.setCurrency({
        //     code: user.currency.toUpperCase(),
        //   });
        //   localStorage.setItem('currency', user.currency.toUpperCase());
        // } else {
        //   this.selectedCurrency = this.currencies[0];
        //   localStorage.setItem('currency', 'USD');
        // }
      } else {
        // localStorage.setItem('currency', environment.support_currency[0]);
        this.selectedLanguage = this.languages[0];
        this.selectedCurrency = this.currencies[0];
        // this.footerService.setCurrency({
        //   code: environment.support_currency[0],
        // });
      }
      this.currentLanguage = this.selectedLanguage;
      this.currentCurrency = this.selectedCurrency;
      this.footerService.setFooterSetting({
        region: this.selectedCountry,
        lang: this.currentLanguage?.code,
        currency: this.currentCurrency?.code,
      });
    });
    // this.selectedLanguage = localStorage.getItem('lang') || environment.support_languages[0]
    // this.selectedLanguage = this.languages.find((item: any) => item.code === this.selectedLanguage)
    // this.currentLanguage =  this.selectedLanguage

    // this.currentCurrency = localStorage.getItem('currency');
    // this.selectedCurrency = this.currencies.find((item: any) => item.code === this.currentCurrency)
    // this.footerService.setCurrency({
    //   code: this.currentCurrency,
    // });
    this.footerService.getIsShowFooter().subscribe((data) => {
      this.isShowFooter = data;
    });
  }

  emailValidator(control: any) {
    if (control.value && /[^a-zA-Z0-9@.]/.test(control.value)) {
      return { invalidEmail: true };
    }
    return null;
  }

  subscribeForm: FormGroup;
  submittedSubscribe = false;
  currentPage = 'booking';
  isHoveredFacebook = false;
  isHoveredYoutube = false;
  emailRegister: string;
  isRegisterSuccess = false;
  currentYear: number;

  aboutUs: any = {
    contactUS: {
      phoneNumber: '+1 402 580 7163',
      email: 'contact.hotel.us@soctrip.com',
      address: '5104 N Orange Blossom Trl, Orlando, FL 32810, United States',
      url: 'https://maps.app.goo.gl/Ds3mRT8pkt3LvQko7'
    },
    contactVietNam: {
      phoneNumber: '+84 1900 571248',
      email: 'contact.hotel.vn@soctrip.com',
      address: '400/8B-400/10, Ung Văn Khiêm, Phường 25, Quận Bình Thạnh, TP.HCM, Việt Nam',
      url: 'https://maps.app.goo.gl/tnwHu3pRzz4hDw8V7'
    },
    contactIndia: {
      phoneNumber: '+91 33 4802 5170',
      email: 'contact.hotel.in@soctrip.com',
      address: '9th Floor, Aurora Waterfront, Unit No 902, Plot 34/1, GN Block, Kolkata, West Bengal 700091, India',
      url: 'https://maps.app.goo.gl/Z1nM5rCSp8yr8n5g6'
    },
    services: [
      {
        name: 'HEADER.MENU.BOOKING_MENU.FLIGHTS',
        link: 'flight',
      },
      {
        name: 'HEADER.MENU.BOOKING_MENU.HOTELS',
        link: 'hotel',
      },
      {
        name: 'HEADER.MENU.BOOKING_MENU.CAR_RENTALS',
        link: 'car-rental',
      },
      {
        name: 'HEADER.MENU.BOOKING_MENU.TOURS',
        link: 'tour',
      },
      {
        name: 'HEADER.MENU.BOOKING_MENU.RESTAURANT',
        link: 'restaurant',
      },
      {
        name: 'HEADER.MENU.BOOKING_MENU.SHOP',
        link: 'shop',
      },
    ],
    info: [
      {
        name: 'FOOTER.TERM_CONDITION',
        link: 'terms-and-conditions',
      },
      {
        name: 'FOOTER.INFORMATION_SECURITY',
        link: 'information-security',
      },
      {
        name: 'FOOTER.OPERATIONAL',
        link: 'operational-regulations',
      },
      {
        name: 'FOOTER.FAQ',
        link: 'faqs',
      },
    ],
    aboutSoctrip: [
      {
        name: 'FOOTER.ABOUT_US',
        link: '',
      },
      {
        name: 'FOOTER.TERMS_ND_CONDITIONS',
        link: '',
      },
      {
        name: 'FOOTER.PRIVACY_POLICY',
        link: '',
      },
      {
        name: 'FOOTER.COOKIES_POLICY',
        link: '',
      },
      {
        name: 'FOOTER.CAREERS',
        link: '',
      },
      {
        name: 'FOOTER.HELP_CENTER',
        link: '',
      },
    ],
    otherServie: [
      {
        name: 'FOOTER.BECOME_OUR_PARTNER',
        link: 'become_our_partner',
      },
      // {
      //   name: 'FOOTER.AFFILIATE_PROGRAM',
      //   link: 'affiliate_program',
      // },
      {
        name: 'FOOTER.SUBMIT_FEEDBACK',
        link: 'submit-feedback',
      },
    ],
  };

  // Validate when click on it Subcribe
  onInputChange(value: string) {
    this.isRegisterSuccess = false;
    this.submittedSubscribe = false;
  }

  onSubmitSubscribe() {
    this.submittedSubscribe = true;
    if (this.subscribeForm.valid) {
      this.isRegisterSuccess = true;
    }
  }

  // Click button Facebook && Youtube
  handleClickPlatform(typePlatform: string) {
    if (typePlatform === 'facebook') {
      alert('This feature is developing.');
    } else {
      alert('This feature is developing.');
    }
  }

  onMouseEnterIcon(typePlatform: string) {
    if (typePlatform === 'facebook') {
      this.isHoveredFacebook = true;
    } else {
      this.isHoveredYoutube = true;
    }
  }

  onMouseLeaveIcon(typePlatform: string) {
    if (typePlatform === 'facebook') {
      this.isHoveredFacebook = false;
    } else {
      this.isHoveredYoutube = false;
    }
  }

  // Set a threshold value (in pixels) to determine when the "Go to Top" button should be shown
  private readonly SCROLL_THRESHOLD = 200;
  showGoToTopButton = false;

  @HostListener('window:scroll')
  onWindowScroll() {
    // Show/hide the "Go to Top" button based on the scroll position
    this.showGoToTopButton = window.scrollY >= this.SCROLL_THRESHOLD;
  }

  handleLinkBooking(service: any) {
    switch (service.link) {
      case 'flight':
        window.location.href = environment.FLIGHT_URL;
        break;
      case 'car-rental':
        window.location.href = environment.CAR_URL;
        break;
      case 'tour':
        window.location.href = environment.TRAVEL_ASSIST_URL;
        break;
      case 'restaurant':
        window.location.href = environment.RESTAURANT_URL;
        break;
      case 'shop':
        window.location.href = environment.SHOP_URL;
        break;
      default: // handle 'hotel' case
        if (this.router.url === `/${BOOKING.BOOKING_HOTEL.BOOKING_HOTEL}`) {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        } else this.router.navigate([BOOKING.BOOKING_HOTEL.BOOKING_HOTEL]);
        break;
    }
  }

  handleLinkAbout(typeAbout: any) {
    switch (typeAbout.link) {
      case 'become_our_partner':
        break;
      case 'affiliate_program':
        break;
      case 'submit-feedback':
        window.open(
          'https://docs.google.com/forms/d/e/1FAIpQLSffHdqYVh0WSfEznnr72ihL1iIZlH0yTlnKUK8PlM7WqC6H9w/viewform'
        );
        break;
      default:
        break;
    }
  }

  handleLinkSubscription(typeSubscription: string) {
    this.currentPage = 'booking';
    switch (typeSubscription) {
      case 'Google Play':
        window.open(
          'https://play.google.com/store/apps/details?id=com.soctrip.social&hl=en_US'
        );
        //alert('This feature is developing.');
        break;
      case 'App Store':
        window.open(
          'https://apps.apple.com/vn/app/soctrip/id6469956803'
        );
        //alert('This feature is developing.');
        break;
      case 'Registered':
        alert('This feature is developing.');
        break;
      case 'Announced':
        alert('This feature is developing.');
        break;
    }
  }

  handleClickChangeLanguage() {
    this.visiblePopupChangeLanguage = true;
  }

  onCloseModal() {
    this.selectedLanguage = this.currentLanguage;
    this.selectedCurrency = this.currentCurrency;
    this.visiblePopupChangeLanguage = false;
  }

  handleClickApply() {
    this.visiblePopupChangeLanguage = false;
    this.soctripTranslationService.setLanguage(this.selectedLanguage.code);
    this.currentLanguage = this.selectedLanguage;
    this.currentCurrency = this.selectedCurrency;
    localStorage.setItem('currency', this.selectedCurrency.code);
    // this.footerService.setCurrency({
    //   code: this.currentCurrency.code,
    // });
    this.footerService.setFooterSetting({
      region: this.selectedCountry,
      lang: this.currentLanguage,
      currency: this.currentCurrency.code,
    });
  }
  onChangeCountry(event: any) {}

  onChangeLanguage(event: any) {}

  onChangeCurrency(event: any) {}

  initData() {
    this.countries = [
      { name: 'United States', code: 'US' },
      { name: 'Viet Nam', code: 'VN' },
    ];
    this.languages = [
      {
        name: 'English (US)',
        code: 'en',
        country_code: 'US',
        icon_name: 'assets/images/common-icon/us.svg',
      },
      {
        name: 'Tiếng Việt',
        code: 'vi',
        country_code: 'VN',
        icon_name: 'assets/images/common-icon/vn.svg',
      },
    ];
    this.currencies = [
      {
        name: 'US Dollar',
        symbol: '$',
        code: 'USD',
        locale: 'en-US',
        value: 'USD $',
      },
      {
        name: 'Vietnamese Dong',
        symbol: '₫',
        code: 'VND',
        locale: 'vi-VN',
        value: 'VND ₫',
      },
    ];
  }
}
