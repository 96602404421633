import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ImagesService {

  constructor(private http: HttpClient) { }

  downloadImage(url: string, filename: string): void {
    this.http.get(url, { responseType: 'blob' }).subscribe((data: Blob) => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(data);
      link.download = filename;
      link.click();
    });
  }
}
