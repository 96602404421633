import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchBarDataService {
  private _searchBarData: ReplaySubject<ISearchBarData> = new ReplaySubject();
  private searchBarData$: Observable<ISearchBarData> =
    this._searchBarData.asObservable();
  private _isPanelOpened = new Subject<boolean>();
  constructor() {}
  setSearchData(data: ISearchBarData) {
    this._searchBarData.next(data);
  }
  getSearchData() {
    return this.searchBarData$;
  }

  setPanelStatus(data: boolean) {
    this._isPanelOpened.next(data);
  }
  getPanelStatus() {
    return this._isPanelOpened.asObservable();
  }
}

export interface ISearchBarData {
  destination: string | null;
  destination_name?: string | null;
  adults: number | null;
  children: number | null;
  child_ages_list: string[] | null;
  rooms: number | null;
  check_in: string | null;
  check_out: string | null;
  currency: string | null;
  country_code: string | null,
}
