<div class="relative">
    <div class="!w-[108px] !h-10 border border-palette-gray-300 rounded-lg !py-2 !px-3 flex !gap-2 items-center cursor-pointer {{isOpen ? 'input-time-picker' : ''}}" (click)="showTimePicker()">
        <i class="text-palette-gray-500 !text-[20px] sctr-icon-clock"></i>
        <input [disabled]="isOpen" [value]="displayTime" class="!w-[50px] text-gray-900 font-inter !text-base !font-normal outline-none bg-white placeholder-palette-gray-500 !pt-[1.5px]" type="text">
    </div>
    <div *ngIf="isOpen" class="time-picker absolute !w-[310px] !h-[400px] bg-white z-30 bottom-11 rounded-lg border border-palette-gray-200">
        <div class="flex justify-start">
            <div class="custom-scrollbar-time flex-1 !h-[332px] !p-2 overflow-y-scroll !space-y-1">
                <div *ngFor="let hour of hoursGenerate" (click)="selectHour(hour)" [class.active]="hour === actives.hours ? 'active' : ''" class="!py-2 !h-9 text-center rounded !items-center hover:bg-palette-gray-100 cursor-pointer">
                    <p class="time-item !font-inter !text-sm !font-normal text-palette-gray-700">{{ hour }}</p>
                </div>
            </div>
            <div class="custom-scrollbar-time flex-1 !h-[332px] !p-2 overflow-y-scroll !space-y-1 border-x border-palette-gray-200">
                <div *ngFor="let minute of minutesGenerate" (click)="selectMinute(minute)" [class.active]="minute === actives.minutes ? 'active' : ''" class="!py-2 !h-9 text-center rounded !items-center hover:bg-palette-gray-100 cursor-pointer">
                    <p class="time-item !font-inter !text-sm !font-normal text-palette-gray-700">{{ minute }}</p>
                </div>
            </div>
        </div>
        <div class="border-t border-palette-gray-200 !py-4 !pl-2 !pr-6 flex justify-between !gap-3">
            <button (click)="selectNowDate()" class="!px-4 font-inter !text-sm !font-semibold whitespace-nowrap text-palette-blue-600">{{"PAYMENT.NOW" | translate}}</button>
            <div class="space-x-3">
                <button (click)="handleCancel()" class="!w-20 !px-4 !py-2 rounded-lg border border-palette-gray-300 font-inter !text-sm  !font-semibold !text-palette-gray-900 !h-9">{{"COMMON.CANCEL" | translate}}</button>
                <button (click)="handleApply()" class="w-fit !px-3 !py-2 rounded-lg bg-palette-blue-600 font-inter whitespace-nowrap !text-sm !font-semibold !text-palette-base-white !h-9">{{"COMMON.APPLY" | translate}}</button>
            </div>
        </div>
    </div>
</div>
