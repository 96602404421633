import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { UserInfo } from '@modules/users/models/user';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  catchError,
  map,
  of,
  throwError,
} from 'rxjs';
import { User } from 'src/app/data/users/models/user.model';
import { v4 as uuidv4 } from 'uuid';
import { CookieService } from '../cookie/cookie.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  basePath = environment.baseURL;
  private isLoginSubject = new BehaviorSubject<boolean | null>(null);
  private _userInfo: ReplaySubject<UserInfo | null> = new ReplaySubject();
  private userInfo$: Observable<UserInfo | null> =
    this._userInfo.asObservable();
  private userProfile = new Subject<UserInfo | null>();

  constructor(private http: HttpClient, private cookieService: CookieService) {}

  getUserProfile() {
    return this.userProfile.asObservable();
  }

  setUserProfile(data: UserInfo | null) {
    this.userProfile.next(data);
  }

  getUserInfo(id: string) {
    //TODO: Replace with actual code
    // return this.http.get(`${this.basePath}/auth/user-info/` + id);
    return of(['admin']);
  }

  getUserInfoById(userId: string): Observable<UserInfo> {
    return this.http
      .get<UserInfo>(this.basePath + `/user/users/${userId}`)
      .pipe(
        map((res: any) => {
          return res.data;
        }),
        catchError(this.handleError)
      );
  }

  saveUserInfoToLocalStorage(userRole: string[]) {
    localStorage.setItem(environment.USER_ROLE_KEY, JSON.stringify(userRole));
  }

  getUserInfoFromLocalStorage() {
    const userInfoString = localStorage.getItem(environment.USER_ROLE_KEY);
    if (userInfoString) return JSON.parse(userInfoString);
  }

  clearUserLocalStorage() {
    localStorage.removeItem(environment.USER_ROLE_KEY);
  }

  setUserInfo(userInfo: UserInfo | null) {
    this._userInfo.next(userInfo);
  }

  getUserInfor() {
    return this.userInfo$;
  }

  setLoginStatus(isLogin: boolean): void {
    this.isLoginSubject.next(isLogin);
  }

  getLoginStatus(): Observable<boolean | null> {
    return this.isLoginSubject.asObservable();
  }
  setLoginStatusToLocalStorage(isLogin: boolean): void {
    localStorage.setItem(
      environment.AUTH_STATUS,
      JSON.stringify({ isLoggedIn: isLogin })
    );
  }

  getLoginStatusFromLocalStorage() {
    const userInfoString = localStorage.getItem(environment.AUTH_STATUS);
    if (userInfoString) return JSON.parse(userInfoString);
  }
  // Error handling
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(() => {
      return errorMessage;
    });
  }

  getUserUuid() {
    const user = JSON.parse(
      localStorage.getItem(environment.USER_PROFILE) || '{}'
    );
    //IF: Logged in => Use userId
    //ELSE: use uuid
    return user.id ? user.id : this.cookieService.getCookie(environment.USER_UUID);
  }

  setUserUuid() {
    localStorage.setItem(environment.USER_UUID, uuidv4());
  }
}
