import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {
  public transform(values: any[], key: string): any[] {
    if (!values) {
      return [];
    }
    return values.sort(this.sortBy(key));
  }

  private sortBy(key: string) {
    return (current: any, next: any): number => {
      if (current[key] > next[key]) {
        return 1;
      } else if (next[key] > current[key]) {
        return -1;
      }
      return 0;
    };
  }
}
