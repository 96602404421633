<div class="flex justify-center my-3 {{containerStyle}}">
    <div
        class="w-[1200px] max-[1550px]:w-[1000px] max-[1350px]:w-[800px] max-[1150px]:w-[650px] max-[980px]:w-[500px] {{contentStyle}}">
        <div class="border border-palette-amber-300 bg-palette-amber-25 !p-[16px] rounded-lg min-h-[93px]">
            <div class="flex">
                <div>
                    <span class="font-inter text-lg !leading-5 font-semibold text-palette-gray-900">{{header
                        | translate | sentenceCase}}</span>
                    <p class="font-inter text-sm font-normal text-palette-gray-700">
                        {{message | translate | sentenceCase}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>