import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  createUrlTreeFromSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable, map } from 'rxjs';
import { PermissionService } from '../../services/permission/permission.service';
import { UserService } from '../../services/user/user.service';
@Injectable({
  providedIn: 'root',
})
export class PermissionGuard implements CanActivate {
  constructor(
    private permissionService: PermissionService,
    private router: Router,
    private userService: UserService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Observable<boolean | UrlTree>((observer) => {
      this.permissionService.userRoles
        .pipe(
          map((roles) => {
            if (
              this.permissionService.checkPermission(route.data['roles'], roles)
            ) {
              observer.next(true);
              observer.complete();
            } else {
              this.userService
                .getUserProfile()
                .pipe()
                .subscribe(() => {
                  this.permissionService.userRoles
                    .pipe(
                      map((roles) => {
                        if (
                          this.permissionService.checkPermission(
                            route.data['roles'],
                            roles
                          )
                        ) {
                          observer.next(true);
                          observer.complete();
                        } else {
                          this.router.navigate(['/']);
                          observer.next(
                            createUrlTreeFromSnapshot(route, ['/'])
                          );
                          observer.complete();
                        }
                      })
                    )
                    .subscribe();
                });
            }
          })
        )
        .subscribe();
    });
  }
}
