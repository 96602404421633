<input 
    #uploader
    hidden
    type="file"
    (change)="uploadFile($event)"
/>
<div class="flex !mb-3">
    <div class="bg-palette-gray-100 !w-[38%] !px-[11px] !py-[7px] flex items-center border rounded-tl-lg rounded-bl-lg">
        <span class="font-inter !text-sm !font-normal text-palette-gray-700">{{ attach.title | translate }}</span>
    </div>
    <div class="upload-file-section !w-[64.446%] !px-[11px] !py-[7px] border-t border-b border-r rounded-tr-lg rounded-br-lg flex justify-between items-center" (click)="uploader.click()">
        <div class="relative !w-[76%] hover:cursor-pointer">
            <!-- <span [tooltip]="nameFile" [isTruncated]="true" class="line-clamp-1 font-inter !text-sm !font-normal text-palette-gray-700">{{ nameFile }}</span> -->

            <span *ngIf="attach.file" [tooltip]="attach.file.name" [isTruncated]="true" class="line-clamp-1 font-inter !text-sm !font-normal text-palette-gray-700">{{ attach.file && attach.file.name }}</span>
            <span *ngIf="!attach.file?.name" class="font-inter !text-sm !font-normal text-palette-gray-400">{{ 'OTA.CONTRACT_AGREEMENT.CLICK_UPLOAD_FILE' | translate }}</span>
        </div>
        <div class="flex hover:cursor-pointer">
            <i *ngIf="isClose" (click)="onRemove()" class="sctr-icon-trash-03 !px-1.5 text-palette-gray-700 hover:text-palette-blue-700"></i>
            <i class="sctr-icon-upload-01 !px-1.5 text-palette-gray-700 hover:text-palette-blue-700"></i>
        </div>
    </div>
</div>