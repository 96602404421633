import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import {
  FormGroup,
  AbstractControl,
  ControlContainer,
  FormGroupDirective,
} from '@angular/forms';
import { countryCode } from '@modules/users/mock/country-code';

@Component({
  selector: 'app-sub-contact',
  templateUrl: './sub-contact.component.html',
  styleUrls: ['./sub-contact.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class SubContactComponent implements OnInit {
  countries: any[];
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Input('subContact') subContact: FormGroup | any;

  constructor() { }

  ngOnInit() {
    this.countries = countryCode;
  }

  get f(): { [key: string]: AbstractControl } {
    return this.subContact.controls;
  }

  getIcon(country: any): string {
    return country.icon;
  }

  onClose() {
    this.close.emit();
  }
}
