import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { CurrencyService } from '@modules/users/services/currency.service';

@Component({
  selector: 'app-price-format',
  templateUrl: './price-format.component.html',
  styleUrls: ['./price-format.component.scss'],
})
export class PriceFormatComponent implements OnInit {
  @Input() price: number;
  @Input() styleClass: string;
  currentCurrency: any;

  constructor(
    private currencyService: CurrencyService,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.currentCurrency = this.currencyService.currency;
  }

  getComponentSize(): { width: number, height: number } {
    const { offsetWidth, offsetHeight } = this.elementRef.nativeElement;
    return { width: offsetWidth, height: offsetHeight };
  }

  formatPrice(price: number): string {
    const formattedPrice = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(price);

    return `${formattedPrice} ${this.currentCurrency}`;
  }
}
