<div *ngIf="reminderFeature == 'ENABLED'; else elseBlock">
    <div id="menu-bar" [ngClass]='handleMenuBar()' class="h-auto md:h-[68px] whitespace-nowrap top-0 z-20 w-full">
        <lib-header
            #headerLib
            (updateLanguage)="onchangeLanguageAndCurrency($event)"
            [environment]="environment"
            [userInfo]="userInfo"
            [currentPage]="'booking'"
            [currentTheme]="currentTheme"
            [(megaVisible)]="megaVisible"
            [myBookingUrl]="myBookingUrl"
            [visibleShopingCart]="visibleShoppingCart"
            [loginReturnUrl] = "loginReturnUrl"
            [isShowRemindOrder] = "true"
            [isCrossModule] = "true"
            [numberOfReminder] = 1
            [reminderIdleTime] = 28800
            [anonymousUserId] = "anonymousUserId"
            [stickyBannerCode]="'HOTEL_STICKY_BANNERS'"
            [popUpBannerCode]="'HOTEL_POPUP_BANNERS'"
            [countryCode]="bannerCountry"
            [timezone]="timeZone"
            [isShowBanner]="true"
            [bannerIdleTime]="60*60*1000"
            module = "HOTEL"
            [floatBtnClass]="''"
            >
        </lib-header>
    </div>
</div>

<ng-template #elseBlock>
    <div id="menu-bar" [ngClass]='handleMenuBar()' class="h-auto md:h-[68px] whitespace-nowrap  top-0 z-20 w-full">
        <lib-header
            #headerLib
            (updateLanguage)="onchangeLanguageAndCurrency($event)"
            [environment]="environment"
            [userInfo]="userInfo"
            [currentPage]="'booking'"
            [currentTheme]="currentTheme"
            [(megaVisible)]="megaVisible"
            [myBookingUrl]="myBookingUrl"
            [visibleShopingCart]="visibleShoppingCart"
            [stickyBannerCode]="'HOTEL_STICKY_BANNERS'"
            [loginReturnUrl] = "loginReturnUrl"
            [stickyBannerCode]="'HOTEL_STICKY_BANNERS'"
            [popUpBannerCode]="'HOTEL_POPUP_BANNERS'"
            [countryCode]="bannerCountry"
            [timezone]="timeZone"
            [isShowBanner]="true"
            [bannerIdleTime]="60*60*1000">
        </lib-header>
    </div>
</ng-template>
