import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countdownFormat'
})
export class CountdownFormatPipe implements PipeTransform {
  transform(value: number) {
    const minutes: number = Math.floor(value / 60);
    const second: number = value % 60;
    const formattedMinutes: string = String(minutes).padStart(2, '0');
    const formattedSeconds: string = String(second).padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds}`;
  }
}
