<div class="card !p-6 border border-palette-gray-200 rounded-lg bg-white">
    <p class="text-palette-gray-900 font-inter text-lg font-semibold leading-7 mb-2">{{chartTitle}}</p>
    <div class="chart-legend flex gap-3 py-2">
        <div class="flex gap-1.5 items-center">
            <hr [style]="{borderColor : this.datasetsOptions[0].borderColor}">
            <span>{{'COMMON.CURRENT_PERIOD' | translate}}</span>
        </div>
        <div class="flex gap-1 items-center">
            <hr class="!border-palette-gray-300 border-dashed">
            <span>{{'COMMON.PREVIOUS_PERIOD' | translate}}</span>
        </div>
    </div>
    <p-chart height="300" type="line" [data]="data" [options]="options"></p-chart>
</div>