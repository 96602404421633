import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  currency: string = 'VND';
  rate: number;
  listCurrency: string[] = ['USD', 'VND', 'EUR', 'INR', 'AUD'];

  constructor() {
    let currencyConversationData = JSON.parse(
      localStorage.getItem('currency-conversation-data') || '{}'
    );
    if (this.listCurrency.includes(currencyConversationData.currency)) {
      this.currency = currencyConversationData.currency;
      this.rate = currencyConversationData.rate;
    }
  }
}
