<div class="ota-custom flex flex-row justify-center items-start">
    <form [formGroup]="generalInfoForm" novalidate class="w-full">
        <app-general-info
            [generalInfo]="data"
            [managerSystem]="managerSystem"
            [isCM]="isCM">
        </app-general-info>
        <app-location [generalInfo]="data"></app-location>
        <app-language [generalInfo]="data"></app-language>
        <app-floors-rooms [generalInfo]="data"></app-floors-rooms>
        <app-contact [generalInfo]="data"></app-contact>
    </form>

</div>