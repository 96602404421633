import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimePickerService } from '@modules/hotel-booking/services/time-picker/time-picker.service';

@Component({
  selector: 'app-time-picker-v2',
  templateUrl: './time-picker-v2.component.html',
  styleUrls: ['./time-picker-v2.component.scss']
})
export class TimePickerV2Component {
  @Input() inputHour: string;
  @Input() inputMinute: string;
  @Output() exportTime = new EventEmitter();

  // open timepicker
  isOpen: boolean = false;

  // generate
  hoursGenerate: string[] = [];
  minutesGenerate: string[] = [];

  displayTime: string;

  // effect UI
  actives: { hours: string; minutes: string } = { hours: '', minutes: '' };

  constructor() {
    this.generateHours();
    this.generateMinutes();
  }

  ngOnInit(): void {
    this.actives.hours = this.inputHour;
    this.actives.minutes = this.inputMinute;
    this.displayTime = this.actives.hours + ":" + this.actives.minutes;
  }

  // open/close timepicker
  public showTimePicker() {
    this.isOpen = true;
  }

  public hiddenTimePicker() {
    this.isOpen = false;
  }

  public handleDisplayTime() {
    this.displayTime = this.actives.hours + ":" + this.actives.minutes;
  }

  public handleApply() {
    this.exportTime.emit(this.actives);
    this.hiddenTimePicker();
  }

  public handleCancel() {
    this.actives.hours = this.inputHour;
    this.actives.minutes = this.inputMinute;
    this.handleDisplayTime();
    this.hiddenTimePicker();
  }

  public selectNowDate() {
    let today = new Date();
    let m = today.getMinutes();
    let round5 = (m % 5) >= 2.5 ? parseInt('' + (m / 5)) * 5 + 5 : parseInt('' + (m / 5)) * 5;
    if (round5 === 60) {
      today.setHours(today.getHours() + 1, 0);
    } else {
      today.setMinutes(round5);
    }
    let minute = today.getMinutes() <= 9 ? `0${today.getMinutes()}` : today.getMinutes().toString();
    let hour = today.getHours() <= 9 ? `0${today.getHours()}` : today.getHours().toString();
    this.actives = { hours: hour, minutes: minute };
    this.handleDisplayTime();
  }

  public selectHour(hour: string) {
    this.actives.hours = hour;
    this.handleDisplayTime();
  }

  public selectMinute(minute: string) {
    this.actives.minutes = minute;
    this.handleDisplayTime();
  }

  public generateHours() {
    for (let i = 0; i <= 23; i ++) {
      if (i <= 9) {
        this.hoursGenerate.push(`0${i}`);
      } else {
        this.hoursGenerate.push(''+ i);
      }
    }
  }

  public generateMinutes() {
    for (let i = 0; i <= 55; i += 5) {
      if (i <= 9) {
        this.minutesGenerate.push(`0${i}`);
      } else {
        this.minutesGenerate.push('' + i);
      }
    }
  }
}
