import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss'],
})
export class DoughnutChartComponent implements OnInit, OnChanges {
  data: any;
  options: any;
  dataLabels: any;
  dataColors: any;
  dataValues: any;
  @Input() totalBooking: number;
  @Input() notePosition: string = 'right';
  @Input() chartData: any;

  ngOnInit() {
    this.options = {
      cutout: '75%',
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: 'right',
          align: 'start',
          title: {
            display: true,
            text: 'Coverage Rate',
            padding: 0,
          },
          labels: {
            boxWidth: 13,
            textAlign: 'left',
            padding: 10,
            usePointStyle: true,
            fontSize: 8,
          },
        },
      },
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['chartData']?.currentValue && this.chartData) {
      this.dataLabels = this.chartData.items?.map((item: any) => item.key);
      this.dataColors = this.chartData.items?.map(
        (item: any) => '#' + item.color
      );
      this.dataValues = this.chartData.items?.map((item: any) => item.value);
      this.data = {
        labels: this.dataLabels,
        datasets: [
          {
            data: this.dataValues,
            backgroundColor: this.dataColors,
          },
        ],
      };
    }
  }

  isAllZeros(arr: number[]): boolean {
    for (let i = 0; i < arr?.length; i++) {
      if (arr[i] !== 0) {
        return false;
      }
    }
    return true;
  }
}
