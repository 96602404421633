import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {
  transform(value: number): string {
    if (isNaN(value)) {
      return 'Invalid Number';
    }

    return value.toLocaleString();
  }
}
