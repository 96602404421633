<nav aria-label="Pagination" class="text-right !py-3">
    <ul class="flex flex-row justify-end items-center m-0 p-0 list-none">
      <li class="select-none !mx-1 my-0">
        <button class="flex flex-row text-sm text-palette-gray-600 font-semibold"        
          [disabled]="current === 1"
          (click)="onPrevious()"
        >
          <img *ngIf="current === 1" class="!mr-2" src="/assets/images/temporary/arrow-left-disable.svg" alt="">
          <img *ngIf="current != 1" class="!mr-2" src="/assets/images/temporary/arrow-left.svg" alt="">
          {{'COMMON.PREVIOUS' | translate}} 
        </button>
      </li>
      <li *ngFor="let page of pages; index as i">
        <a
          *ngIf="page !== -1; else more"
          class="internal !w-10 !h-10 !rounded-[20px]"         
          [class.current]="page === current"
          tabindex="0"
          (click)="onGoTo(page)"
          (keyup.enter)="onGoTo(page)"
          >{{ page }}</a
        >
        <ng-template #more>
          <a class="more"> ... </a>
        </ng-template>
      </li>
      <li class="select-none !mx-1 my-0">
        <button class="flex flex-row text-sm text-palette-gray-600 font-semibold"      
          [disabled]="current === total"
          (click)="onNext()"
        >
          {{'COMMON.NEXT' | translate}} 
          <img *ngIf="current === total" class="!ml-2" src="/assets/images/temporary/arrow-right-disable.svg" alt="">
          <img *ngIf="current != total" class="!ml-2" src="/assets/images/temporary/arrow-right.svg" alt="">         
        </button>
      </li>
    </ul>
</nav>

