import { ChangeDetectorRef, Component, ElementRef, HostListener, ViewChild} from "@angular/core";
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { GalleriaModule } from 'primeng/galleria';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

@Component({
    selector: 'app-quick-view-hotel-photo',
    standalone: true,
    imports: [
        CommonModule,
        GalleriaModule,
        TranslateModule,
        ProgressSpinnerModule        
    ],
    templateUrl: './quick-view-hotel-photo.component.html',
    styleUrls: ['./quick-view-hotel-photo.component.scss']
  })
  export class QuickViewHotelPhotoModal{

    openImageRef: DynamicDialogRef | undefined;
    selectedImage: any;
    _activeIndex: number = 0;
    pageIndex = 1;
    pageSize = 20;
    galleria: any = [];
    allGalleria: any = [];
    showButton: boolean = false;
    @ViewChild('imageElement') imageElement: ElementRef;
    @ViewChild('listElement') listElement: ElementRef;
    
    constructor(
        private config: DynamicDialogConfig,
        public ref: DynamicDialogRef,
        public dialogService: DialogService,
        private cdr: ChangeDetectorRef
      ) { }
      
    ngOnInit() {
        if (this.config.data) {
            this.allGalleria = this.config.data.selectedImage;
        }
        this.getImages(this.pageIndex, this.pageSize);
        this.pageIndex++;
    }

    ngAfterViewInit() {
        this.checkShowButton();
        this.cdr.detectChanges();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: Event) {       
        this.checkShowButton();
      }

    get activeIndex(): number {
        return this._activeIndex;
    }

    set activeIndex(newValue) {
        if (
            this.galleria &&
            0 <= newValue &&
            newValue <= this.galleria.length - 1
        ) {
            this._activeIndex = newValue;
        }
    }

    handleImageClick(index: number): void {
        this.activeIndex = index;
        this.scrollImage();
        this.loadMorePictures();
    }

    next() {
        this.activeIndex++;
        this.scrollImage();
        this.loadMorePictures();
    }

    prev() {
        this.activeIndex--;
        this.scrollImage();
    }

    scrollImage() {
        const tixPos = document.querySelector(`#image-` + this.activeIndex);
        if (tixPos) {
            tixPos.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
            });
        }
    }

    onclose() {
        this.ref.close();
    }

    ngOnDestroy() {
        if (this.openImageRef) {
            this.ref.close();
        }
    }
    
    getImages(pageNum: number, pageSize: number){
        let value = this.allGalleria.slice(((pageNum-1)*pageSize), pageNum*pageSize);
        this.galleria = [...this.galleria,...value]
    }

    loadMorePictures(){
        if(this.activeIndex >= this.galleria.length - 15 && this.galleria.length < this.allGalleria.length)
        {
            this.getImages(this.pageIndex, this.pageSize);
            this.pageIndex +=1;
        }
    }

    checkShowButton()
    {
        const imageElement= this.imageElement.nativeElement;
        const listElement= this.listElement.nativeElement;
        this.showButton = (imageElement.offsetWidth * this.galleria.length) > listElement.offsetWidth;
    }

    replaceWithDefaultImage(event: any): void {
        event.target.src = 'assets/images/temporary/default-hotel.png';
    }
}