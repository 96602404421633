<div >
    <p-dialog header="{{'MESSAGE.SIGN_IN_BEFORE_CONTINUE' | translate}}" [(visible)]="visiblePleaseSignIn" [modal]="true" [style]="{ width: '500px', height: '150px', position: 'absolute' }"
      [draggable]="false" (onHide)="onCloseModal()" [blockScroll]="true" >
      <div class="flex flex-col px-4 pb-0  bg-white h-full relative rounded-b-lg">
        <div class="absolute !bottom-4 !right-5">
            <div class="flex !gap-3">
              <button
                  class="h-10 px-[16px] bg-palette-blue-600 text-white rounded-lg justify-center items-center 
                  cursor-pointer text-sm font-semibold font-inter hover:bg-opacity-75"
                  (click)="onSignUp()"
              >
              {{'COMMON.SIGN_UP' | translate}}
              </button>
              <button
              class="h-10 px-[16px] bg-white text-palette-gray-700 rounded-lg justify-center items-center 
              cursor-pointer text-sm font-semibold font-inter border border-palette-gray-300 hover:shadow-lg"
                  (click)="onSignIn()"
              >
              {{'COMMON.SIGN_IN' | translate}}
              </button>
          </div>
      </div>
      </div>
    </p-dialog>
</div>