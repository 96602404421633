import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from '@env/environment';

@Component({
  selector: 'app-sign-in-request-dialog',
  templateUrl: './sign-in-request-dialog.component.html',
  styleUrls: ['./sign-in-request-dialog.component.scss'],
})
export class SignInRequestDialogComponent {
  @Input() visiblePleaseSignIn = false;
  @Output() visiblePleaseSignInChange = new EventEmitter();
  constructor(private router: Router) {}

  onSignUp() {
    window.location.href = environment.SOCIAL_APP_URL + '/login?r='+ encodeURIComponent(window.location.href);
  }
  onSignIn() {
    window.location.href = environment.SOCIAL_APP_URL + '/login?r='+ encodeURIComponent(window.location.href);
  }
  onCloseModal() {
    this.visiblePleaseSignInChange.emit();
  }
}
