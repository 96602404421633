import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderService } from '@modules/hotel-booking/services/header/header.service';
import { FooterService } from '@modules/hotel-booking/services/footer/footer.service';
import { CtaSigninService } from '@modules/hotel-booking/services/cta-signin/cta-signin.service';
import { scrollToTop } from '@shared/utils/helper';

@Component({
  selector: 'app-view-terms-and-conditions',
  templateUrl: './view-terms-and-conditions.component.html',
  styleUrls: ['./view-terms-and-conditions.component.scss'],
})
export class ViewTermsAndConditionsComponent {
  constructor(
    private headerService: HeaderService,
    private footerService: FooterService,
    private ctaSigninService: CtaSigninService
  ) {}
  ngOnInit() {
    scrollToTop();
    this.headerService.setPropertyHeader({
      isShow: false,
      isFixed: false,
      isChangeColor: false,
      isWhite: false,
    });
    this.footerService.setIsShowFooter(false);
    this.ctaSigninService.hideCTA();
  }
}
