<div *ngIf="total>0" class="flex justify-between items-center h-[100%]">
    <div class="flex items-center space-x-[6px] justify-center max-h-[36px] min-h-[36px]"
    [ngClass]="{'invisible' : page === 'hotel-search-result'}">
        <div *ngIf="page === 'account-balance'" class="text-sm text-palette-gray-500 md:block hidden">{{'USER_MANAGEMENT.ITEMS_PER_PAGE' | translate}}</div>
        <div *ngIf="page !== 'rate-plans' && page !== 'account-balance'" class="text-sm text-palette-gray-500 md:block hidden">{{'MY_BOOKING.NUMBER_OF_CARDS_PER_PAGE' | translate}}</div>
        <div *ngIf="page === 'rate-plans'" class="text-sm text-palette-gray-500 md:block hidden">{{'MY_BOOKING.ROWS_PER_PAGE' | translate}}</div>
        <div>
            <div class="select-page-size h-[36px] space-x-2 bg-white max-h-[36px] min-h-[36px] flex items-center cursor-pointer 
                {{page !== 'rate-plans' ? 'max-w-[61px] min-w-[61px] justify-center' : 'max-w-[90px] min-w-[90px] justify-between'}}"
                (click)="overlayVisible = !overlayVisible">
                <div class="text-palette-gray-500 text-sm">{{pageSize}}</div>
                <i class="sctr-icon-chevron-down text-xl text-palette-gray-500"></i>
            </div>
            <p-overlay [(visible)]="overlayVisible" >
                <ng-template pTemplate="content">
                  <div class="custom-scroll min-w-[60px] lg:min-h-fit font-inter shadow-md bg-white mt-2 rounded-lg max-h-[160px] overflow-y-auto">
                    <div *ngFor="let item of pageSizeList">
                      <div
                        class="flex border-b items-center space-x-2 h-8 text-palette-gray-500 p-2
                         cursor-pointer hover:font-medium hover:bg-black hover:bg-opacity-[0.15]"
                        (click)="handleChangePageSize(item.page_size)">
                        <span class="text-sm ">{{item.page_size}}</span>
                      </div>
                    </div>
                  </div>
                </ng-template>
            </p-overlay>
        </div>
        <div *ngIf="page !== 'rate-plans' && page !== 'account-balance'" class="text-sm text-palette-gray-500 md:block hidden">{{'MY_BOOKING.ITEMS' | translate}}</div>
    </div>

    <nav aria-label="Pagination" class="text-right !py-3">
        <ul class="flex flex-row justify-end items-center m-0 p-0 list-none">
            <li class="select-none !mx-1 my-0">
                <button class="flex h-9 min-w-[36px] max-w-[36px] cursor-pointer justify-center items-center {{page ===  'rate-plans' ? 'border border-palette-gray-300 rounded-lg' : ''}}" [disabled]="current === 1"
                    (click)="onPrevious()">
                    <i class="sctr-icon-chevron-left text-[20px]"
                        [ngClass]="current === 1 ? 'text-palette-gray-500 cursor-default': 'text-palette-gray-800 cursor-pointer' "></i>
                </button>
            </li>
            <div *ngIf="page !== 'rate-plans'">
                <div class="flex items-center !gap-[2px]">
                    <li *ngFor="let page of pages; index as i">
                        <a *ngIf="page !== -1; else more" class="internal" [class.current]="page === current" tabindex="0"
                            (click)="onGoTo(page)" (keyup.enter)="onGoTo(page)">{{ page }}</a>
                        <ng-template #more>
                            <a class="more"> ... </a>
                        </ng-template>
                    </li>
                </div>
            </div>
            <div *ngIf="page === 'rate-plans'">
                <div class="flex items-center !gap-[2px]">
                    <li *ngFor="let page of pages; index as i">
                        <a *ngIf="page !== -1; else more" class="internal" [class.currentRatePlan]="page === current" tabindex="0"
                            (click)="onGoTo(page)" (keyup.enter)="onGoTo(page)">{{ page }}</a>
                        <ng-template #more>
                            <a class="more"> ... </a>
                        </ng-template>
                    </li>
                </div>
            </div>
            <li class="select-none !mx-1 my-0">
                <button class="flex h-9 min-w-[36px] max-w-[36px] cursor-pointer justify-center items-center {{page ===  'rate-plans' ? 'border border-palette-gray-300 rounded-lg' : ''}}" [disabled]="current === total"
                    (click)="onNext()">
                    <i class="sctr-icon-chevron-right text-[20px]"
                        [ngClass]="current === total ? 'text-palette-gray-500 cursor-default': 'text-palette-gray-800 cursor-pointer' "></i>
                </button>
            </li>
        </ul>
    </nav>

</div>