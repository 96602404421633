<ng-container *ngIf="!loading">
  <p-carousel 
    [value]="listBanners" 
    [numVisible]="1" 
    styleClass="custom-carousel" 
    [numScroll]="1" 
    [circular]="true"
    [autoplayInterval]="3000">
    <ng-template let-banner pTemplate="item">
      <div class="banner-item relative h-full">
        <div class="w-full h-full">
          <ng-container *ngIf="banner.extension == '.svg'; else imgTemplate">
            <iframe
              [src]="(baseUrl + '/storage/files/download/' + banner.id + '.svg') | safeUrl"
              class="h-full w-full object-cover mx-auto rounded-lg {{ iframeClass }} cursor-pointer"
            ></iframe>
          </ng-container>
          <ng-template #imgTemplate>
            <img
              [src]="baseUrl + '/storage/files/web/' + banner.id + '.webp'"
              class="rounded-lg object-cover {{ imgClass }} max-h-full w-full cursor-pointer"
              (click)="openInNewTab(banner.url)"
              (error)="replaceWithDefaultImage($event)" 
            />
          </ng-template>          
        </div>
      </div>
    </ng-template>
  </p-carousel>
</ng-container>