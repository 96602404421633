export interface CountryCode {
    id: string,
    name: string,
    code: string,
    icon: string,
}

export const telephoneCode: CountryCode[] = [
    { id: 'CN', name: 'China', code: '+86', icon: 'assets/icons/countries-flag-icon/CN.svg' },
    { id: 'FR', name: 'France', code: '+33', icon: 'assets/icons/countries-flag-icon/FR.svg' },
    { id: 'JP', name: 'Japan', code: '+81', icon: 'assets/icons/countries-flag-icon/JP.svg' },
    { id: 'KR', name: 'Korea', code: '+82', icon: 'assets/icons/countries-flag-icon/KR.svg' },
    { id: 'RU', name: 'Russia', code: '+7', icon: 'assets/icons/countries-flag-icon/RU.svg' },
    { id: 'GB', name: 'United Kingdom', code: '+44', icon: 'assets/icons/countries-flag-icon/GB.svg' },
    { id: 'US', name: 'United States', code: '+1', icon: 'assets/icons/countries-flag-icon/US.svg' },
    { id: 'VN', name: 'Viet Nam', code: '+84', icon: 'assets/icons/countries-flag-icon/VN.svg' }
  ];
  