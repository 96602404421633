import { Injectable } from '@angular/core';
import { DetailHotel } from '@modules/hotel-booking/models/view-details';
import { BehaviorSubject, Observable } from 'rxjs';

interface Tab {
  title: string;
  short_name: string
}

@Injectable({
  providedIn: 'root',
})
export class HotelDetailsService {

  private _hotelURL: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  hotelURL$: Observable<any> = this._hotelURL.asObservable();
  
  isFacilities(hotel: DetailHotel): boolean {
    if(!hotel) return false;
    return hotel.property_amenity_list?.length > 0;
  }

  isDescription(hotel: DetailHotel): boolean {
    if(!hotel) return false;
    return hotel.property_description?.length > 0;
  }

  setHotelURL(data: any) {    
    this._hotelURL.next(data);
  }

  getHotelURL() {
    return this.hotelURL$;
  }
}
