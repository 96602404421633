import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimePickerService {
  public timepickers: { 
    [key: string]: {
      hours: string,
      minutes: string,
    } 
  } = {};

  private timepickerSubject = new BehaviorSubject<{ [key: string]: { hours: string, minutes: string } }>({});

  public isSave: { [key: string]: boolean } = {};

  reset() {
    this.timepickers = {};
    this.isSave = {};
  }

  setIsSave(key: string, isSave: boolean) {
    this.isSave[key] = isSave;
  }

  getIsSave(key: string) {
    return this.isSave[key];
  }

  setTime(key: string, hours: string, minutes: string) {
    if (!this.timepickers[key]) {
      this.timepickers[key] = { hours: hours, minutes:minutes };
    } else {
      this.timepickers[key].hours = hours;
      this.timepickers[key].minutes = minutes;
    }
  }

  getTime(key: string) {
    return this.timepickers[key];
  }
}
