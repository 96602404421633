import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements OnInit, OnChanges {
  @Input() chartData: any;
  @Input() chartColor: string = '#2970FF';
  @Input() key: string = '';
  data: any;
  options: any;

  ngOnInit() {
    const documentStyle = getComputedStyle(document.documentElement);
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    this.options = {
      borderRadius: 4,
      barPercentage: 0.5,
      maxBarThickness: 37,
      categoryPercentage: 1,
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          ticks: {
            color: '#344054',
            font: {
              family: "'Inter', sans-serif",
              weight: 400,
              size: 14,
              lineHeight: '20px',
            },
          },
          grid: {
            display: false,
          },
        },
        y: {
          ticks: {
            color: '#667085',
            stepSize: 10000,
            callback: (value: number, index: any, ticks: any) => {
              let lastValue = ticks[ticks.length - 1];
              if (value === lastValue.value) {
                return '$' + this.formatNumber(value);
              }
              return this.formatNumber(value);
            },
          },
          grid: {
            display: true,
            offset: false,
            drawBorder: false,
            color: (context: any) => {
              if (context.tick.value === 0) {
                return 'transparent';
              }
              return surfaceBorder;
            },
            borderDash: [5, 5],
            tickBorderDash: [2, 5],
            lineWidth: 1.1,
          },
        },
      },
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['chartData'].currentValue && this.chartData) {
      let labels = [];
      let data = [];

      if (this.key === 'revpar') {
        labels = this.chartData.items?.map((item: any) => item.key);
        data = this.chartData.items?.map((item: any) => item.value);
      } else {
        labels = this.chartData.current_items?.map((item: any) => item.key);
        data = this.chartData.current_items?.map((item: any) => item.value);
      }

      this.data = {
        labels: labels,
        datasets: [
          {
            label: '',
            backgroundColor: this.chartColor,
            borderColor: this.chartColor,
            data: data,
          },
        ],
      };
    }
  }

  formatNumber(num: number, precision: number = 0) {
    const map = [
      { suffix: 't', threshold: 1e12 },
      { suffix: 'b', threshold: 1e9 },
      { suffix: 'm', threshold: 1e6 },
      { suffix: 'k', threshold: 1e3 },
      { suffix: '', threshold: 1 },
    ];

    const found = map.find((x) => Math.abs(num) >= x.threshold);
    if (found) {
      const formatted =
        (num / found.threshold).toFixed(precision) + found.suffix;
      return formatted;
    }

    return num;
  }
}
