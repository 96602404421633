<div class="disabled-approve flex !mt-2 bg-palette-base-white rounded-lg border general-information-ota-section">
    <div class="!p-6 !w-[31.55%]">
        <p class="font-inter !text-lg !font-semibold text-palette-gray-900">{{ "OTA.LANGUAGE.LANGUAGE_AND_CURRENCY" | translate }}</p>
        <p class="font-inter !text-sm !font-normal text-palette-gray-700 !mt-1">{{ "OTA.LANGUAGE.INFO" | translate }}
        </p>
    </div>

    <div class="!p-6 !w-[68.45%] ota-location-form">
        <form [formGroup]="formGroup" class="flex flex-col gap-3">
            <div>
                <p class="font-inter !text-sm !font-medium text-palette-gray-700">{{ "OTA.LANGUAGE.LANGUAGE" | translate
                    }}
                    <span class="text-palette-red-600">*</span>
                </p>
                <div class="!mt-1">
                    <p-multiSelect [options]="languages" formControlName="selectedlanguages" optionLabel="english_name"
                        optionValue="language_code"
                        [placeholder]="'OTA.LANGUAGE.SELECT_LANGUAGES' | translate"></p-multiSelect>
                </div>
                <div *ngIf="f['selectedlanguages'].touched && f['selectedlanguages'].errors"
                    class="!mt-1 invalid-feedback">
                    <div *ngIf="f['selectedlanguages'].errors['required']">
                        <div [message-error]="'OTA.LANGUAGE.LANGUAGE' | translate"></div>
                    </div>
                </div>
            </div>
            <div>
                <label htmlFor="city" class="font-inter text-sm font-medium text-palette-gray-700 mb-1">{{
                    "OTA.LANGUAGE.CURRENCY" |
                    translate }}
                    <span class="text-palette-red-600">*</span>
                </label>
                <p-dropdown formControlName="currency" [options]="currencies" class="mt-1"
                    [placeholder]="'OTA.LANGUAGE.SELECT_CURRENCY' | translate" [filter]="true"
                    [autoDisplayFirst]="false">
                    <ng-template let-currency pTemplate="item">
                        <div class="flex align-items-center">
                            <div>{{currency.code}}</div>
                        </div>
                    </ng-template>
                    <ng-template let-currency pTemplate="selectedItem">
                        <div>{{currency.code}}</div>
                    </ng-template>
                </p-dropdown>
                <div *ngIf="f['currency'].touched && f['currency'].errors" class="!mt-1 invalid-feedback">
                    <div *ngIf="f['currency'].errors['required']">
                        <div [message-error]="'OTA.LANGUAGE.CURRENCY' | translate"></div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>