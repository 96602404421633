import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
  AfterViewInit,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { PostRequest } from '@shared/models/post.model';
import { UserInfo } from '@modules/users/models/user';
import { environment } from '@env/environment';
import { difference } from 'lodash';
import { MessageService } from 'primeng/api';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { UserService } from 'src/app/core/services/user/user.service';
import { DetailHotel } from '@modules/hotel-booking/models/view-details';
import { ActivatedRoute, Router } from '@angular/router';
import { PostService } from 'src/app/services/posts/post.service';
import { ToastType } from '@shared/enum/toast-type';
import { SoctripTranslationService } from 'src/app/services/soctrip-translation/soctrip-translation.service';
import { createSlug } from '@shared/utils/helper';

interface FileWithPreview {
  thumbnail: string;
  original: string;
  file?: any;
  type?: any;
}

interface DropdownOption {
  label: string;
  value: any;
  icon: string;
}

@Component({
  selector: 'soctrip-post-dialog',
  templateUrl: './post-dialog.component.html',
  styleUrls: ['./post-dialog.component.scss'],
  providers: [MessageService],
})
export class PostDialogComponent implements OnInit, AfterViewInit {
  @Input() visible = false;
  @Input() dataDetailHotel: DetailHotel;
  @Output() visibleChange = new EventEmitter(false);
  @ViewChild('hotelDescription') hotelDescription: ElementRef;

  
  baseImageId = environment.STORAGE_SERVICE_API_BASE_PATH_ID_IMAGE;
  apiUrl = environment.baseURL;
  postForm: FormGroup;
  selectedOption: any = 'PUBLIC';
  baseUrl: string = environment.baseURL;
  userInfo: UserInfo;
  loading = false;
  progressValue = 0;
  myOptions: DropdownOption[] = [
    { label: 'POST.PUBLIC', value: 'PUBLIC', icon: 'sctr-icon-globe-04' },
    { label: 'POST.FRIENDS', value: 'FRIENDS', icon: 'sctr-icon-users-01' },
    { label: 'POST.PRIVATE', value: 'PRIVATE', icon: 'sctr-icon-user-01' },
  ];
  showProgress = false;
  content = '';
  isExpanded: boolean = true;
  contentHeight = 0;
  elementHeight = 24 * 3;
  isLongText: boolean = false;
  shareLink: Subscription;
  description: string;

  constructor(
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private userService: UserService,
    private ref: ElementRef,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private postService: PostService,
    private soctripTranslationService: SoctripTranslationService
  ) {
    this.postForm = this.formBuilder.group({
      content: [''],
    });
  }
  ngOnInit(): void {
    this.getUserInfor();
  }

  ngOnChanges(changes: SimpleChanges): void {}
  getUserInfor() {
    this.userService.getUserInfor().subscribe((user) => {
      if (user) this.userInfo = user;
    });
  }

  resetDataInForm(): void {
    this.postForm.reset(); // Reset the form
    this.showProgress = false;
    this.selectedOption = 'PUBLIC';
  }

  openDialog() {
    this.visible = true; // Set the value to true to open the dialog.
  }

  closeDialog() {
    this.visibleChange.emit(false);
  }

  onDialogHide(): void {
    this.visibleChange.emit({
      visible: !this.visible,
      postStatus: null,
    });
    this.resetDataInForm();
  }

  onShowDialog() {
    this.postForm.get('content')?.addValidators(Validators.required);
  }

  ngAfterViewInit(): void {
    this.handleShowViewMore();
    if(this.dataDetailHotel.property_description.length > 200) {
      this.description = this.dataDetailHotel.property_description.slice(0, 200) + '...';
      this.isLongText = true;
    } else {
      this.description = this.dataDetailHotel.property_description;
    }
    this.cdRef.detectChanges();
  }

  onExpandText() {
    this.isExpanded = !this.isExpanded;
    if(!this.isExpanded) {
      this.description = this.dataDetailHotel.property_description;
    } else {
      this.description = this.dataDetailHotel.property_description.slice(0, 200) + '...';
    }
  }

  handleShowViewMore(): void {
    if (this.hotelDescription) {
      const elementHeight = this.hotelDescription.nativeElement.offsetHeight;
      if (elementHeight > this.elementHeight) this.isLongText = true;
      else this.isLongText = false;
      this.isExpanded = false;
    }
  }

  formatBreakLine(text: string) {
    return text?.replace(/\n/g, '<br><br>');
  }

  onChangePriority(value: any) {
    this.selectedOption = value;
  }
  shouldDisableButton(): boolean {
    let check = false;
    if (
      this.postForm.get('content')?.value === '' ||
      this.postForm.get('content')?.value === null ||
      this.showProgress
    ) {
      check = true;
    }
    return check;
  }

  onSubmit() {
    let cityNameSlug = createSlug(this.dataDetailHotel.city_name);
    if (cityNameSlug == '' || !cityNameSlug) {
      cityNameSlug = 'property';
    }
    let hotelNameSlug = createSlug(this.dataDetailHotel.property_name);
    if (hotelNameSlug == '' || !hotelNameSlug) {
      hotelNameSlug = 'hotel-name';
    }

    const currentURL =
      environment.BOOKING_URL +
      '/booking/hotel/search/' +
      cityNameSlug +
      '/' +
      hotelNameSlug +
      '?destination_name=' +
      this.dataDetailHotel.city_name +
      '&property_id=' +
      this.dataDetailHotel.property_id;

    this.content = this.postForm.value.content;
    const postRequest: PostRequest = {
      content: this.content,
      post_type: 'USER',
      post_privacy: this.selectedOption,
      friend_tags: [],
      share_link: currentURL,
      share_link_type: 'HOTEL',
      dag_object_name: 'HOTEL_SHARE',
      dag_object_id: this.dataDetailHotel.property_id,
      post_feeling: '',
    };
    this.shareLink = this.postService.post(postRequest).subscribe((data) => {
      if (data.success) {
        this.visibleChange.emit({
          visible: !this.visible,
          postStatus: true,
        });
      } else {
        this.visibleChange.emit({
          visible: !this.visible,
          postStatus: false,
        });
      }
    });
  }

  replaceAvatarWithDefaultImage(event: any): void {
    event.target.src = 'assets/images/default_user_avatar.png';
  }

  replaceWithDefaultImage(event: any): void {
    event.target.src = 'assets/images/temporary/default-hotel.png';
  }

  ngOnDestroy(): void {
    if (this.shareLink) {
      this.shareLink.unsubscribe();
    }
  }
}
