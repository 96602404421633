import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchBookingIDService {
  private historySearch: any[] = [];
  private _isOpenModal: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);

  setValue(value: any) {
    const existingItemIndex = this.historySearch.findIndex(
      (item: any) => item.type_booking === value.type_booking
    );

    if (existingItemIndex === -1) {
      this.historySearch.push(value);
    } else {
      this.historySearch[existingItemIndex] = value;
    }
  }

  getValue() {
    return this.historySearch;
  }

  clearValue() {
    this.historySearch = [];
  }

  setIsOpenModal(data: boolean) {
    this._isOpenModal.next(data);
  }

  getIsOpenModal() {
    return this._isOpenModal.asObservable();
  }
}
