<div *ngIf="isShowPopup"
    class="fixed top-0 right-0 bottom-0 left-0 z-[9999] bg-palette-base-black bg-opacity-40 h-screen !overflow-hidden !overflow-y-hidden !overflow-x-hidden"
    (click)="closePopup()">
    <div class="flex items-center justify-center h-screen">
        <div class="qr-code-booking rounded-lg !w-[367px] !p-6" (click)="stopPropagationEvent($event)">
            <div>
                <p class="font-inter !text-sm !font-normal text-palette-gray-600">{{'COMMON.ORDER_ID' | translate}}: <span
                        class="!text-base !font-semibold text-palette-gray-900">{{ bookingId }}</span></p>
            </div>
            <div class="w-full flex justify-center items-center">
                <img class="!my-4 rounded-md" [src]="urlImg" />
            </div>
            <div class="flex !gap-3">
                <common-icon-text
                    class="!w-full font-inter flex items-center justify-center !gap-2 !text-sm !font-semibold !py-3 !px-5 border bg-palette-base-white rounded-lg cursor-pointer hover:border-palette-blue-light-200"
                    (click)="downloadImage(urlImg, 'download.png')" [icon]="'sctr-icon-download-01'"
                    [iconStyle]="'!text-xl !leading-[20px] !font-semibold'" [text]="'COMMON.SAVE' | translate"
                    [textStyle]="'text-palette-gray-900'"></common-icon-text>
                <common-icon-text
                    class="!w-full font-inter flex items-center justify-center !gap-2 !text-sm !font-semibold !py-3 !px-5 border bg-palette-base-white rounded-lg cursor-pointer hover:border-palette-blue-light-200"
                    (click)="copyLink()" [icon]="'sctr-icon-copy-06'"
                    [iconStyle]="'!text-xl !leading-[20px] !font-semibold'" [text]="'COMMON.COPY' | translate"
                    [textStyle]="'text-palette-gray-900'"></common-icon-text>
            </div>
        </div>
    </div>
    <app-toast></app-toast>
</div>