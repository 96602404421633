import { Routes } from '@angular/router';
import { TasksService } from '@modules/todos/services/tasks.service';
import { PermissionGuard } from './core/guards/permission/permission.guard';
import { USER_PERMISSION, MANAGER_PERMISSION } from './shared';
import { AuthGuard } from './core/guards/auth/auth.guard';
import { environment } from '@env/environment';
import { BOOKING_ROUTES } from '@modules/booking/booking.routes';
import { ViewHotelApprovalReviewComponent } from '@modules/ota/hotel-ota/components/hotel-approval-review/view-hotel-approval-review/view-hotel-approval-review.component';
import { HotelReviewApprovalRatingComponent } from '@modules/hotel-booking/components/detail/hotel-review-approval-rating/hotel-review-approval-rating.component';
import { ViewTermsAndConditionsComponent } from '@modules/ota/hotel-ota/components/terms-and-conditions/view-terms-and-conditions/view-terms-and-conditions.component';

export const APP_ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'booking',
    pathMatch: 'full',
  },
  {
    path: 'booking',
    loadChildren: () =>
      import('@modules/booking/booking.routes').then(
        ({ BOOKING_ROUTES }) => BOOKING_ROUTES
      ),
  },
  {
    path: 'common/icon',
    loadComponent: () =>
      import('./modules/common/icon/icon.component').then(
        ({ IconComponent }) => IconComponent
      ),
    title: 'Booking: Icon',
  },
  {
    path: 'common/upload-files',
    loadComponent: () =>
      import('./modules/common/upload-files/upload-files.component').then(
        ({ UploadFilesComponent }) => UploadFilesComponent
      ),
    title: 'Booking: Upload files',
  },
  {
    path: 'my-booking',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/my-booking/my-bookings.routes').then(
        ({ MY_BOOKING_ROUTES }) => MY_BOOKING_ROUTES
      ),
  },
  {
    path: 'ota',
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      roles: ['USER'],
    },
    loadChildren: () =>
      import('./modules/ota/ota.routes').then(({ OTA_ROUTES }) => OTA_ROUTES),
  },
  {
    path: 'hotel-agency/:property_id/approval-review',
    component: ViewHotelApprovalReviewComponent,
  },
  {
    path: 'ota/hotel-ota/terms-and-conditions',
    component: ViewTermsAndConditionsComponent,
  },
  {
    path: 'hotel-review/:rating_id/approval-rating',
    component: HotelReviewApprovalRatingComponent,
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./modules/users/components/sign-in/sign-in.component').then(
        ({ SignInComponent }) => SignInComponent
      ),
    title: environment.PRODUCT_NAME + ': Sign In',
  },
  {
    path: 'sign-up',
    loadComponent: () =>
      import('./modules/users/components/sign-up/sign-up.component').then(
        ({ SignUpComponent }) => SignUpComponent
      ),
    title: environment.PRODUCT_NAME + ': Sign Up',
  },
  {
    path: 'common/version',
    loadComponent: () =>
      import('./modules/common/version/version.component').then(
        ({ VersionComponent }) => VersionComponent
      ),
    title: 'checking version',
  },
  {
    path: 'server-error/:type-error',
    loadComponent: () =>
      import('./layout/server-error-page/server-error-page.component').then(
        ({ ServerErrorPageComponent }) => ServerErrorPageComponent
      ),
  },
  {
    path: '**',
    loadComponent: () =>
      import('./layout/page-not-found/page-not-found.component').then(
        ({ PageNotFoundComponent }) => PageNotFoundComponent
      ),
    title: 'Page Not Found',
  },
];
