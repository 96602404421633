import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SocchatService {
  private _isShowFloatChatBtn: ReplaySubject<any> = new ReplaySubject();
  private isShowFloatChatBtn$: Observable<any> = this._isShowFloatChatBtn.asObservable();
  private _publicPropertyID: ReplaySubject<string> = new ReplaySubject();
  private publicPropertyID$: Observable<string> = this._publicPropertyID.asObservable();
  private _reCountChatPending: ReplaySubject<any> = new ReplaySubject();
  private reCountChatPending$: Observable<any> = this._reCountChatPending.asObservable();
  private _agencyToUserSubject: ReplaySubject<any> = new ReplaySubject();
  private agencyToUserSubject$: Observable<any> = this._agencyToUserSubject.asObservable();
  
  constructor(private http: HttpClient) { }

  setFloatChatBtn(status: boolean) {
    this._isShowFloatChatBtn.next(status);
  }
  getFloatChatBtn() {
    return this.isShowFloatChatBtn$;
  }

  setID(id: string) {
    this._publicPropertyID.next(id);
  }

  getID() {
    return this.publicPropertyID$;
  }

  setAgencyToUserSubject(object: any) {
    this._agencyToUserSubject.next(object);
  }

  getAgencyToUserSubject() {
    return this.agencyToUserSubject$;
  }

  resetAgencyToUserSubject() {
    this._agencyToUserSubject = new ReplaySubject(); 
    this.agencyToUserSubject$ = this._agencyToUserSubject.asObservable();
  }

  clearID() {
    this._publicPropertyID.complete(); 
    this._publicPropertyID = new ReplaySubject();
    this.publicPropertyID$ = this._publicPropertyID.asObservable();
  }

  getAgencyChatSessionsCount(agencyId: string, status: string, module: string): Observable<any> {
    const chatApi: string = `${environment.BASE_API_URL}/chat`;
    const url = `${chatApi}/sessions/support/agency/${agencyId}/status/${status}/count?module=${module}`;
    return this.http.get(url);
  }

  submitReCountChatPending(){
    this._reCountChatPending.next(true);
  }

  reCountChatPending(){
    return this.reCountChatPending$;
  }
}
