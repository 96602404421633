import { Component, Input } from '@angular/core';
import { ToastType } from '@shared/enum/toast-type';

interface ToastMap {
  [key: string]: string;
}

const TOAST_NOTIFICATION_ICON = {
  [ToastType.ERROR]: 'sctr-icon-alert-circle',
  [ToastType.WARNING]: 'sctr-icon-alert-triangle',
  [ToastType.SUCCESS]: 'sctr-icon-check-circle',
  [ToastType.INFO]: 'sctr-icon-check-circle',
  [ToastType.PRIMARY]: 'sctr-icon-alert-circle text-palette-blue-700'
};

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent {
  @Input() keyToast: string;

  public toastIconMap: ToastMap = TOAST_NOTIFICATION_ICON;
}
