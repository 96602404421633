<div class="calendar-dashboard-container w-auto max-w-[320px] min-w-[200px]">
    <span class="w-full h-9 flex items-center pl-2 pr-5 p-input-icon-right border-1 border-palette-gray-300 rounded-lg cursor-pointer" (click)="calendarDashBoard.toggle($event)">
        <ng-container *ngIf="dateString == ALL_TIME || dateString == ''; else selectedDate">
            <p *ngIf="dateString == ALL_TIME" class="text-sm font-medium text-palette-gray-900">
                {{'REVIEW_MANAGEMENT.ALL_TIME' | translate}}
            </p>
            <p *ngIf=" dateString == ''" class="text-sm font-normal text-palette-gray-400">
                {{'REVIEW_MANAGEMENT.REVIEW_TIME' | translate}}
            </p>           
        </ng-container>
        <ng-template #selectedDate>
            <ng-container *ngIf="dateString == DAY; else rangeDate">
                <ng-container *ngIf="(publicRangeDate && publicRangeDate[0] && publicRangeDate[1]); else unknownDate">              
                    <p class="text-sm font-normal text-palette-gray-900 text-left flex items-center !px-1">
                        {{publicRangeDate[0] | dateFormat}} - {{publicRangeDate[1] | dateFormat}}
                    </p>
                </ng-container>
                <ng-template #unknownDate>
                    <p class="h-9 text-center">
                        -
                    </p>
                </ng-template>            
            </ng-container>
            <ng-template #rangeDate>
               <p class="text-sm font-semibold text-palette-gray-900">
                {{'primeng.' + dateString | translate | titlecase}}:
               </p>
               <p *ngIf="publicRangeDate && publicRangeDate[0] && publicRangeDate[1]" class="text-sm font-normal text-palette-gray-900 text-left flex items-center !px-1">
                    {{publicRangeDate[0] | dateFormat}} - {{publicRangeDate[1] | dateFormat}}
                </p>
            </ng-template>           
         </ng-template>
        <i class="sctr-icon-calendar"></i>
    </span>
    <p-overlayPanel #calendarDashBoard styleClass="calendar-multi min-w-[453px] min-[1850px]:-ml-[173px]">
        <ng-template pTemplate="body">
            <div class="flex gap-4 px-2" (click)="$event.stopPropagation()">
                <div class="my-2 gap-2">
                    <ul class="flex flex-col">
                        <li class="cursor-pointer truncate rounded p-2 text-sm font-medium hover:bg-palette-gray-100"
                            *ngFor="let item of DF1" 
                            [ngClass]="
                                DFActive === item.value
                                ? 'bg-palette-blue-50 text-palette-blue-600'
                                : 'hover:text-palette-blue-500'
                            " (click)="filterDate(item)">
                            {{ "primeng." + item.value | translate | sentenceCase }}
                        </li>
                    </ul>
                    <hr />
                </div>
                <div class="border-l calendar-multi-select flex flex-col">                    
                    <p-calendar
                        class="calendar-dashboard" panelStyleClass="!border-0 w-full h-full"
                        class="max-w-full"
                        [(ngModel)]="rangeDates"
                        [selectionMode]="'range'" 
                        [inline]="true"
                        [readonlyInput]="true"
                        [numberOfMonths]="2"
                        (ngModelChange)="onSelect($event)">
                    <ng-template pTemplate="date" let-date>
                            <span
                                class="!h-[40px] !w-[40px] flex justify-center items-center item-ca"
                                [ngClass]="{
                                    '!bg-[#1570EF] text-white border !rounded': onDetectDay(date),
                                    'bg-[#F2F4F7] text-black border-none': isRangeDay(date)
                                }">{{date.day}}</span>
                        </ng-template>
                    </p-calendar>
                    <div class="flex items-center justify-between !p-4">
                        <div class="flex !gap-2">
                            <ng-container *ngIf="(rangeDates && rangeDates[0] && rangeDates[1]); else unknownDate">
                                <p class="h-9 w-[128px] border-1 border-palette-gray-300 rounded-lg text-left flex items-center !px-3 text-palette-gray-900 font-normal text-sm">
                                    {{rangeDates[0] | dateFormat}}
                                </p>
                                <p class="flex items-center"> - </p>
                                <p class="h-9 w-[128px] border-1 border-palette-gray-300 rounded-lg text-left flex items-center !px-3 text-palette-gray-900 font-normal text-sm">
                                    {{rangeDates[1] | dateFormat}}
                                </p>
                            </ng-container>
                            <ng-template #unknownDate>
                                <p class="h-9 w-[128px] border-1 border-palette-gray-300 rounded-lg flex items-center justify-center">
                                    -
                                </p>
                                <p class="flex items-center"> - </p>
                                <p class="h-9 w-[128px] border-1 border-palette-gray-300 rounded-lg flex items-center justify-center">
                                    -
                                </p>
                            </ng-template>
                        </div>
                        <div class="flex items-center justify-end !gap-2">
                            <button (click)="handleCancel()" (click)="calendarDashBoard.toggle($event)"
                            class="!px-5 !py-3 h-9 text-palette-gray-900 bg-white border border-palette-gray-300 hover:!bg-palette-gray-100 flex items-center justify-center rounded-lg text-sm font-semibold cursor-pointer">
                            {{"COMMON.CANCEL" | translate}}
                          </button>
                          <button [disabled]="disableApplyBtn()" (click)="handleApply()" (click)="calendarDashBoard.toggle($event)"
                            class="!px-5 !py-3 h-9 text-white flex items-center justify-center rounded-lg text-sm font-semibold bg-palette-blue-600 hover:!bg-opacity-90 cursor-pointer">
                            {{ "COMMON.APPLY" | translate}}
                          </button>
                        </div>

                    </div>
                </div>
            </div>
        </ng-template>
    </p-overlayPanel>
</div>