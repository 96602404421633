export enum DateFilterType {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  THIS_WEEK = 'this-week',
  LAST_WEEK = 'last-week',
  THIS_MONTH = 'this-month',
  LAST_MONTH = 'last-month',
  PAST_SEVEN_DAYS = 'past-7-days',
  PAST_THIRTY_DAYS = 'past-30-days',
  LAST_THREE_MONTHS = 'last-3-months',
  LAST_SIX_MONTHS = 'last-6-months',
  THIS_YEAR = 'this-year',
  LAST_YEAR = 'last-year',
  ALL_TIME = 'all-time',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export const DateFilter = [
  { value: DateFilterType.TODAY },
  { value: DateFilterType.YESTERDAY },
  { value: DateFilterType.THIS_WEEK },
  { value: DateFilterType.LAST_WEEK },
  { value: DateFilterType.THIS_MONTH },
  { value: DateFilterType.LAST_MONTH },
  { value: DateFilterType.THIS_YEAR },
  { value: DateFilterType.LAST_YEAR },
  { value: DateFilterType.ALL_TIME },
];

export const DashboardDateFilterSection1 = [
  { value: DateFilterType.TODAY },
  { value: DateFilterType.YESTERDAY },
  { value: DateFilterType.LAST_THREE_MONTHS },
  { value: DateFilterType.LAST_SIX_MONTHS },
];

export const DashboardDateFilterSection2 = [
  { value: DateFilterType.DAY },
  { value: DateFilterType.WEEK },
  { value: DateFilterType.MONTH },
  { value: DateFilterType.YEAR },
];
