<button [disabled]="disabled" [type]="type || ''" [ngClass]="{
    'bg-palette-blue-600 text-white': variant === 'primary',
    'btn-secondary-color': variant === 'info',
    'bg-white text-palette-gray-900': variant === 'outline',
    'btn-destructive-primary': variant === 'danger',
    'opacity-50 hover:opacity-50':  disabled,
  }" [class]="
    'border !px-5 font-inter !text-sm font-semibold rounded-lg !py-3 ' +
    class
  " (click)="onClick.emit($event)">
  {{ label }}
</button>