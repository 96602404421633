import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { url, propertySearchRoom } from '../config/app-config';
import { UserService } from 'src/app/core/services/user/user.service';

@Injectable({
  providedIn: 'root',
})
export class ReservationService {
  private _dataResponse: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  dataResponse$: Observable<any> = this._dataResponse.asObservable();
  constructor(private http: HttpClient, private userService: UserService) {}
  private _notiBookingSingleRoomType = new Subject<void>();
  private _bookingRoomType = new Subject<void>();
  public notiBookingSingleRoomType$ = this._notiBookingSingleRoomType.asObservable();
  private infoPropertyBooked: any;

  createReservation(
    booked_room_list: any,
    paramsInput: any,
    user_id: string
  ): Observable<any> {
    const body = {
      booked_rooms_list: booked_room_list,
      user_id: user_id,
    };
    const params = new HttpParams({ fromObject: paramsInput as any });
    return this.http.post<any>(`${url}/reservation/reservations`, body, {
      params: paramsInput,
    });
  }

  setDataResponse(data: any) {
    this._dataResponse.next(data);
  }

  getDataResponse() {
    return this.dataResponse$;
  }

  clearData() {
    this._dataResponse.next(null);
  }

  notiBookingSingleRoomType(data: any) {
    this._notiBookingSingleRoomType.next(data);
  }

  getBookingSingleRoomType() {
    return this.notiBookingSingleRoomType$;
  }

  setBookingRoomType(data: any) {
    this._bookingRoomType.next(data);
  }

  getBookingRoomType() {
    return this._bookingRoomType.asObservable();
  }

  setInfoPropertyBooked(value: any) {
    this.infoPropertyBooked = value;
  }

  getInfoPropertyBooked() {
    return this.infoPropertyBooked;
  }
}
