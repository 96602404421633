import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateFilter, DateFilterType } from 'src/app/core/enum/date-filter.enum';
import { BsDatepickerDirective } from 'ngx-bootstrap/datepicker';
import { PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';
import moment from 'moment';

@Component({
  selector: 'app-calendar-multi-select',
  templateUrl: './calendar-multi-select.component.html',
  styleUrls: ['./calendar-multi-select.component.scss']
})
export class CalendarMultiSelectComponent {

  dateString: string = '';
  DFActive: DateFilterType;
  tempDFActive: DateFilterType;
  DAY = DateFilterType.DAY;
  ALL_TIME = DateFilterType.ALL_TIME;
  DF1 = DateFilter;
  selectionMode = '';
  rangeDates: (Date | undefined)[] = [];
  tmpRangeDates: (Date | undefined)[] = [];
  publicRangeDate: (Date | undefined)[] = [];
  date: Date | undefined;
  @Output() selectedDate = new EventEmitter();

  currentLanguage: string = 'en';
  constructor(
    private config: PrimeNGConfig,
    private translateService: TranslateService
  ){
  }

  ngOnInit(){
    // this.currentLanguage =
    //   localStorage.getItem('lang') || environment.support_languages[0]; // Apply current language for date picker
    //   console.log();
      
    // this.translateDatePicker(this.currentLanguage);
    // this.dateString = DateFilterType.ALL_TIME;
  }

  translateDatePicker(lang: string) {
    this.translateService.use(lang);
    this.translateService.get('primeng').subscribe((res) => {
      this.config.setTranslation(res);
    });
  }

  filterDate(item: { value: DateFilterType }){
    let date1, date2: Date | undefined;
    const currentDate = new Date();
    switch (item.value) {
      case DateFilterType.DAY:
      case DateFilterType.TODAY:
        this.selectionMode = 'single';
        date1 = date2 = new Date();
        break;

      case DateFilterType.YESTERDAY:
        const yesterday = new Date(currentDate);
        yesterday.setDate(currentDate.getDate() - 1);
        date1 = date2 = new Date(yesterday);
        break;

      case DateFilterType.THIS_WEEK:
        this.selectionMode = 'range';
        const startOfWeek = moment().startOf('week').toDate(); 
        const endOfWeek = moment().endOf('week').toDate();
        date1 = startOfWeek;
        date2 = endOfWeek;
        break;

      case DateFilterType.LAST_WEEK:
        this.selectionMode = 'range';
        const startOfLastWeek = moment().subtract(1, 'week').startOf('week').toDate();
        const endOfLastWeek = moment().subtract(1, 'week').endOf('week').toDate();
        date1 = startOfLastWeek;
        date2 = endOfLastWeek;
        break;
    
      case DateFilterType.THIS_MONTH:
        this.selectionMode = 'range';
        const startOfMonth = moment().startOf('month').toDate();
        const endOfMonth = moment().endOf('month').toDate();
        date1 = startOfMonth;
        date2 = endOfMonth;
        break;
    
      case DateFilterType.LAST_MONTH:
        this.selectionMode = 'range';
        const startOfLastMonth = moment().subtract(1, 'month').startOf('month').toDate();
        const endOfLastMonth = moment().subtract(1, 'month').endOf('month').toDate();
        date1 = startOfLastMonth;
        date2 = endOfLastMonth;
        break;
    
      case DateFilterType.THIS_YEAR:
        this.selectionMode = 'range';
        const startOfYear = moment().startOf('year').toDate();
        const endOfYear = moment().endOf('year').toDate();
        date1 = startOfYear;
        date2 = endOfYear;
        break;
    
      case DateFilterType.LAST_YEAR:
        this.selectionMode = 'range';
        const startOfLastYear = moment().subtract(1, 'year').startOf('year').toDate();
        const endOfLastYear = moment().subtract(1, 'year').endOf('year').toDate();
        date1 = startOfLastYear;
        date2 = endOfLastYear;
        break;

      case DateFilterType.ALL_TIME:
        this.selectionMode = 'range';
        const startOfAllTime = moment().toDate();
        const endOfAllTime = moment().toDate();
        this.DFActive = item.value;
        this.rangeDates = [];
        return;

      default:
        break;
    }

    this.rangeDates = [date1, date2];
    this.DFActive = item.value;
  }

  onSelect(e: any){
    this.DFActive = DateFilterType.DAY;
  }

  handleApply(){
    this.dateString = this.DFActive;
    this.tempDFActive = this.DFActive;
    this.publicRangeDate = Array.from(this.rangeDates);
    this.tmpRangeDates = Array.from(this.rangeDates);
    this.selectedDate.emit(this.rangeDates);
  }

  disableApplyBtn(){
    return this.rangeDates 
      && this.rangeDates.length > 0 
      && (this.rangeDates[0] == null || this.rangeDates[1] == null);
  }

  handleCancel(){ 
    this.DFActive = this.tempDFActive;
    this.rangeDates = Array.from(this.tmpRangeDates);
  }

  onDetectDay(date: any) {
    let startDay = undefined;
    let endDay = undefined;
    const dateMoment = moment({
      day: date.day,
      month: date.month,
      year: date.year
    });
    if(this.rangeDates) {
      startDay = this.rangeDates[0];
      endDay = this.rangeDates[1];
    }
    if(dateMoment.isSame(startDay, 'day') || dateMoment.isSame(endDay, 'day')) {
      return true
    }
    return false;
  }

  isRangeDay(date: any) {
    let startDay = undefined;
    let endDay = undefined;
    const dateMoment = moment({
      day: date.day,
      month: date.month,
      year: date.year
    });
    if(this.rangeDates) {
      startDay = this.rangeDates[0];
      endDay = this.rangeDates[1];
    }
    if(dateMoment.isBetween(startDay, endDay) && date.selectable) {
        return true;
    }
    return false;
  } 
}
