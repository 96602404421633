import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges } from '@angular/core';

interface Attachment {
  id: string,
  title: string,
  file: File | null,
  isUpload: boolean,
  isValid: boolean,
  type: string
}
@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {
  @Input() title: string;
  @Input() type: string;
  @Input() attach: Attachment;
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() upload: EventEmitter<any> = new EventEmitter();

  public nameFile: string;
  public isClose: boolean = false;

  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      if(this.attach) {
        if (this.attach.type === 'attach') this.isClose = true;
        if (this.attach.file) {
          this.nameFile = this.attach.file.name;
        }
      }
    }, 2000)
  }

  onRemove(){
    this.close.emit();
  }

  uploadFile(e: any) {
    this.upload.emit(e);
    if (this.attach.file) {
      this.nameFile = this.attach.file.name;
    }
    e.target.value = '';
  }
}
