import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FlightReservationSearchService {

  
  key = 'flightReservationSearchResult';

  setItemStorage(value: any): void {
    localStorage.setItem(this.key, JSON.stringify(value));
  }

  getItemStorage(): any {
    const storedData = localStorage.getItem(this.key);
    return storedData ? JSON.parse(storedData) : null;
  }
}
