<div class="font-inter card flex justify-content-center w-[100%]">
    <div class="flex !gap-10 flex-1"
        [ngClass]="{'flex-row-reverse': notePosition === 'left', 'flex-col': notePosition === 'bottom', 'flex-col-reverse': notePosition === 'top'}">
        <div *ngIf="!isAllZeros(dataValues)" class="w-[50%] relative"
            [ngClass]="{'mx-auto': notePosition === 'bottom' || 'top'}">
            <p-chart type="doughnut" [data]="data" [options]="options" width="250" height="250"></p-chart>
            <div class="absolute text-center top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                <p class="text-sm text-palette-gray-500">{{'DOUGHNUT_CHART.TOTAL_BOOKING' | translate}}</p>
                <p class="text-2xl font-semibold text-palette-gray-900">{{totalBooking}}</p>
            </div>
        </div>
        <div *ngIf="isAllZeros(dataValues)" class="font-inter card flex justify-content-center w-[100%]">
            <div class="relative">
                <div class="h-[230px] w-[230px] bg-palette-gray-200 rounded-full flex justify-center items-center">
                    <div class="h-[170px] w-[170px] bg-white rounded-full flex justify-center items-center"></div>
                </div>
                <div class="absolute text-center top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                    <p class="text-sm text-palette-gray-500">{{'DOUGHNUT_CHART.TOTAL_BOOKING' | translate}}</p>
                    <p class="text-2xl font-semibold text-palette-gray-900">{{totalBooking}}</p>
                </div>
            </div>
        </div>
        <div class="w-full"
            [ngClass]="{'!w-[75%] mx-auto': notePosition === 'bottom' || 'top'}">
            <div class="flex flex-col !gap-2 w-full">
                <div *ngFor="let item of dataValues; let i = index">
                    <div class="flex justify-between flex-1 w-[100%]">
                        <div class="flex items-center !gap-3">
                            <div class="h-[10px] w-[10px] rounded-full"
                                [ngStyle]="{'background-color': dataColors[i]}">
                            </div>
                            <p class="text-sm text-palette-gray-900">{{dataLabels[i]}}</p>
                        </div>
                        <div class="text-sm text-palette-gray-900 font-semibold">
                            {{dataValues[i]}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>