import { Component, ElementRef, Input, QueryList, Renderer2, SimpleChanges, ViewChildren } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TooltipPosition, TooltipTheme } from '@shared/enum/tooltip.enums';
@Component({
    selector: 'common-icon-text',
    templateUrl: './common-icon-text.component.html',
    styleUrls: ['./common-icon-text.component.scss'],
    })
export class CommonIconText {
    @Input() icon: string;
    @Input() iconStyle: string;
    @Input() text: string;
    @Input() textStyle: string;
    @Input() dataCyValue: string;
    @Input() theme: TooltipTheme = TooltipTheme.LIGHT;
    @Input() position: TooltipPosition = TooltipPosition.DEFAULT;

    constructor(
        private renderer: Renderer2, 
        private elementRef: ElementRef
    ){}
    
    ngOnInit(){
        if (this.dataCyValue) {
            this.renderer.setAttribute(this.elementRef.nativeElement.querySelector('span'), 'data-cy', this.dataCyValue);
        }
    }
}