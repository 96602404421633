import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {
  @Input() style: string = 'h-8 w-8 fill-palette-blue-600';
  @Input() color: string = 'fill-palette-blue-600'

}
