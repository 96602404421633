<div class="price-content flex flex-col space-y-3 border-y border-palette-gray-200 !p-4">
    <div class="flex flex-row justify-between items-center font-inter">
        <p class="text-palette-gray-600 text-base font-medium">{{'PAYMENT.SUBTOTAL' | translate}}</p>   
        <div class="font-inter text-base font-medium text-palette-gray-800">
            <app-price-format [price]="subTotal"></app-price-format>
        </div>
    </div>
    <div class="flex flex-row justify-between items-center font-inter">
        <div class="flex flex-row justify-start items-center space-x-[6px]">
            <p class="text-palette-gray-600 text-base font-medium">{{'COMMON.TAXES_CHARGES' | translate}}</p>   
            <!-- <i class="sctr-icon-chevron-up text-base text-palette-gray-600 cursor-pointer" (click)="toggleTaxesCharges()" 
                (click)="handleToggleDropdown()"
                [@rotateAnimation]="isShowTax ? 'open' : 'closed'"></i>             -->
        </div>       
        <div class="font-inter text-base font-medium text-palette-gray-800">
            <app-price-format [price]="bookingDetail.taxes + bookingDetail.charges"></app-price-format>
        </div>
    </div>
    <div *ngFor="let item of voucher_checked">
        <div class="flex justify-between items-center">
            <div class="flex items-center gap-1 bg-palette-emerald-50 !pr-2 !pl-[6px] !py-[2px] rounded-2xl">
                <img class="w-[12px] h-[12px]" src="assets/images/common-icon/checked-voucher.svg" alt="">
                <span class="text-system-success-700 font-inter text-xs leading-[18px] font-medium uppercase">{{item.voucher_code}}</span>
            </div>
            <div class="flex items-center">
                <span class="mr-[4px] text-xs !leading-[18px] font-semibold text-palette-gray-800"> - </span>
                <div class="font-inter text-base font-medium text-palette-gray-800">
                    <app-price-format [price]="item.voucher_value"></app-price-format>
                </div>
            </div>
        </div>
    </div>

    <!-- <div *ngIf="isShowTaxesCharges" class="flex flex-col gap-1 pl-3 mb-1 !mt-[0.25rem]" [@slideDownUp]>
        <div class="flex justify-between items-center">
            <p class="font-inter text-sm font-normal text-palette-gray-500">{{'PAYMENT.TAXES' | translate}}
                ({{bookingDetail.taxes_percent}})</p>
            <div class="font-inter text-sm font-medium text-palette-gray-500">
                <app-price-format [price]="bookingDetail.taxes"></app-price-format>
            </div>
        </div>
        <div class="flex justify-between items-center">
            <p class="font-inter text-sm font-normal text-palette-gray-500">{{'PAYMENT.CHARGES' | translate}}
                ({{bookingDetail.charge_percent}})</p>
            <div class="font-inter text-sm font-medium text-palette-gray-500">
                <app-price-format [price]="bookingDetail.charges"></app-price-format>
            </div>
        </div>
    </div> -->
    
    <!-- <span *ngIf="price.data_voucher.length > 0; then voucher"></span>
    <ng-template #voucher>
        <div *ngFor="let voucher of price.data_voucher" class="flex flex-row justify-between items-center font-inter">
            <p class="text-palette-gray-600 text-base font-medium">{{ voucher.code }}</p>   
            <div class="flex items-center">
                <span class="text-sm font-medium text-palette-gray-800 !mr-1">-</span>
                <span class="text-base font-medium text-palette-gray-800">$</span>
                <span class="font-inter text-base font-medium text-palette-gray-800">{{ formatDollar(voucher.value_discount) }}</span>
            </div>
        </div>
    </ng-template>

    <div class="flex flex-row justify-between items-center font-inter">
        <p class="text-palette-gray-600 text-base font-medium">{{ price.useCoins.coins }} coins</p>   
        <div class="flex items-center">
            <span class="text-sm font-medium text-palette-gray-800 !mr-1">-</span>
            <span class="text-base font-medium text-palette-gray-800">$</span>
            <span class="font-inter text-base font-medium text-palette-gray-800">{{ formatDollar(price.useCoins.value) }}</span>
        </div>
    </div> -->
    <!-- <div class="flex justify-between items-center">
        <div class="flex items-center gap-1 bg-palette-orange-dark-50 !w-auto !pr-2 !pl-[6px] !py-[2px] rounded-2xl cursor-pointer">
            <img class="w-[12px] h-[12px]" src="assets/icons/voucher-payment/coins.svg" alt="">
            <span class="text-palette-orange-700 font-inter text-xs leading-[18px] font-medium">{{'PAYMENT.EARN' | translate}} 230 {{'PAYMENT.VOUCHER.COINS' | translate}}</span>
        </div>
    </div> -->
</div>
<div class="!p-4">
    <div class="flex flex-row justify-between items-center font-inter !mb-2">
        <p class="text-palette-gray-800 text-base font-semibold">{{'PAYMENT.TOTAL' | translate}}</p>   
        <div class="font-inter text-lg font-semibold total-price-details">
            <app-price-format [price]="this.totalPrice"></app-price-format>
        </div>
    </div>
    <span class="font-inter text-sm font-normal text-palette-gray-700 italic">
        {{'PAYMENT.PRICE_DESCRIPTION' | translate}}
    </span>
</div>
