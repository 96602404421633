import { Injectable, Pipe, PipeTransform, inject } from '@angular/core';

@Pipe({
  name: 'sentenceCase',
})
@Injectable({
  providedIn: 'root',
})
export class SentenceCasePipe implements PipeTransform {
  transform(value: string): string {
    return value.replace(value[0], value[0]?.toUpperCase());
  }
}
