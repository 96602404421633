import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable, catchError, map } from 'rxjs';
import { ApiClientConfig } from '../api-client.config';
import { TranslateParamModel } from './api.translate.model';

export class TranslateApi {
  private apiUrl: string = environment.baseURL;
  TRANSLATION = '/translation';
  TRANSLATE = '/translate';

  constructor(
    public readonly http: HttpClient,
    public config: ApiClientConfig
  ) {}

  translateText(param: TranslateParamModel): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}${this.TRANSLATION}${this.TRANSLATE}`, param).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }
}
