<div class="sub-contact gap-4 !w-full !pt-0 bg-white  border-palette-gray-200">
  <form [formGroup]="subContact" class="bg-palette-gray-25">
    <div class="!w-[534x] !p-4 border border-system-gray-300 rounded-lg">
      <div class="flex flex-col !gap-4 justify-center items-center">
        <div class="flex justify-between items-center w-full">
          <div
            class="flex w-full h-[24px] justify-start items-center text-base font-inter font-semibold text-palette-gray-900">
            {{'OTA.CONTACT.SUB_CONTACT' | translate}}</div>
          <i *ngIf="!subContact.disabled" class="sctr-icon-x-close flex items-center justify-center text-palette-gray-900 cursor-pointer !w-5 h-5 !text-lg"
            (click)="onClose()"></i>
        </div>
        <div class="flex flex-input flex-col w-full gap-1 custom-input">
          <label for="full-name-input" class="form-title">{{'OTA.CONTACT.FULL_NAME' | translate}}</label>
          <input pInputText class="w-full !h-[44px] rounded-md !min-h-[44px] text-[14px]" id="full-name-input"
            type="text" name="full_name" formControlName="full_name"
            [placeholder]="'OTA.CONTACT.ENTER_FULL_NAME' | translate" class="form-input"
            [ngClass]="{ 'is-invalid': f['full_name'].touched && f['full_name'].errors}" />

          <div *ngIf="f['full_name'].touched && f['full_name'].errors" class="invalid-feedback">
            <div *ngIf="f['full_name'].errors['required']" class="flex items-center gap-1">
              <span class="flex items-center"><i class="sctr-icon-alert-circle"></i></span>
              {{'OTA.CONTACT.FULL_NAME' | translate}} {{'OTA.CONTACT.IS_REQUIRED' | translate}}
            </div>
          </div>
        </div>
        <div class="flex flex-input flex-col w-full gap-1 custom-input">
          <label for="email-input" class="form-title">Email</label>
          <input pInputText class="w-full !h-[44px] rounded-md !min-h-[44px] text-[14px]" id="email-input" type="text"
            name="email" formControlName="email" [placeholder]="'OTA.CONTACT.ENTER_EMAIL' | translate"
            class="form-input" [ngClass]="{ 'is-invalid': f['email'].touched && f['email'].errors,
           }" />
          <div *ngIf="f['email'].touched && f['email'].errors" class="invalid-feedback">
            <div *ngIf="f['email'].errors['required']" class="flex items-center gap-1">
              <span class="flex items-center"><i class="sctr-icon-alert-circle"></i></span>
              Email {{'OTA.CONTACT.IS_REQUIRED' | translate}}
            </div>
            <div *ngIf="f['email'].errors['pattern']" class="flex items-center gap-1">
              <span class="flex items-center"><i class="sctr-icon-alert-circle"></i></span>
              Email {{'OTA.CONTACT.IS_INVALID' | translate}}
            </div>
          </div>
        </div>
        <div class="flex flex-col !w-full gap-1 custom-input">
          <label for="phone_number" class="form-title">{{'OTA.CONTACT.PHONE_NUMBER' | translate}} <span
              class="form-require">*</span></label>
          <div class="flex items-center container-phone-input"
            [ngClass]="{ 'is-invalid': f['phone_number'].touched && f['phone_number'].errors }">
            <p-dropdown [options]="countries" optionLabel="code" [filter]="true" filterBy="name"
              formControlName="phone_number_code">
              <ng-template pTemplate="selectedItem">
                <div *ngIf="f['phone_number_code'].value" class="flex gap-2 justify-center items-center">
                  <img [src]="getIcon(f['phone_number_code'].value)" style="width: 18px" />
                  <div>{{ f['phone_number_code'].value.code }}</div>
                </div>
              </ng-template>
              <ng-template let-country pTemplate="item">
                <div class="flex gap-2 items-center z-1000">
                  <img [src]="getIcon(country)" style="width: 18px" />
                  <span>{{ country.name }}</span>
                  <span>({{ country.code }})</span>
                </div>
              </ng-template>
            </p-dropdown>
            <input pKeyFilter="int" id="phone_number" name="phone_number" formControlName="phone_number"
              [placeholder]="'OTA.CONTACT.ENTER_PHONE' | translate" class="phone-input w-full rounded-lg"
              maxlength="15" />
          </div>
          <div *ngIf="f['phone_number'].touched && f['phone_number'].errors" class="invalid-feedback">
            <div *ngIf="f['phone_number'].errors['required']" class="flex items-center gap-1">
              <span class="flex items-center"><i class="sctr-icon-alert-circle"></i></span>
              {{'OTA.CONTACT.PHONE_NUMBER' | translate}} {{'OTA.CONTACT.IS_REQUIRED' | translate}}
            </div>
            <div *ngIf="f['phone_number'].errors['pattern']" class="flex items-center gap-1">
              <span class="flex items-center"><i class="sctr-icon-alert-circle"></i></span>
              {{'OTA.CONTACT.PHONE_NUMBER' | translate}} {{'OTA.CONTACT.IS_INVALID' | translate}}
            </div>
          </div>
        </div>
        <div class="flex flex-input flex-col !w-full gap-1 custom-input">
          <label for="office_phone_number" class="form-title">{{'OTA.CONTACT.OFFICE_PHONE' | translate}}</label>
          <div class="flex items-center container-phone-input"
            [ngClass]="{ 'is-invalid': f['office_phone_number'].touched && f['office_phone_number'].errors }">
            <p-dropdown [options]="countries" optionLabel="code" [filter]="true" filterBy="name"
              formControlName="office_phone_number_code">
              <ng-template pTemplate="selectedItem">
                <div *ngIf="f['office_phone_number_code'].value" class="flex gap-2 justify-center items-center">
                  <img [src]="getIcon(f['office_phone_number_code'].value)" style="width: 18px" />
                  <div>{{ f['office_phone_number_code'].value.code }}</div>
                </div>
              </ng-template>
              <ng-template let-country pTemplate="item">
                <div class="flex gap-2 items-start">
                  <img class="text-[14px] leading-5 flex items-start" [src]="getIcon(country)" style="width: 18px" />
                  <span class="text-[14px] leading-5 flex items-start">{{ country.name }}</span>
                  <span class="text-[14px] leading-5 flex">{{ country.code }}</span>
                </div>
              </ng-template>
            </p-dropdown>
            <input pKeyFilter="int" id="office_phone_number" name="office_phone_number"
              formControlName="office_phone_number" [placeholder]="'OTA.CONTACT.ENTER_OFFICE_PHONE' | translate"
              class="phone-input w-full rounded-lg" maxlength=15 />
          </div>
          <div *ngIf="f['office_phone_number'].touched && f['office_phone_number'].errors" class="invalid-feedback">
            <div *ngIf="f['office_phone_number'].errors['pattern']" class="flex items-center gap-1">
              <span class="flex items-center"><i class="sctr-icon-alert-circle"></i></span>
              {{'OTA.CONTACT.OFFICE_PHONE' | translate}} {{'OTA.CONTACT.IS_INVALID' | translate}}
            </div>
          </div>
        </div>
        <div class="flex flex-input flex-col w-full gap-1 custom-input">
          <label for="position-input" class="form-title">{{'OTA.CONTACT.POSITION' | translate}}</label>
          <input pInputText class="w-full !h-[44px] rounded-md !min-h-[44px] !max-h-[44px] text-[14px] border"
            id="position-input" type="text" name="position"
            formControlName="position" [placeholder]="'OTA.CONTACT.ENTER_DEPARTMENT' | translate" class="form-input" />
        </div>
        <div class="flex flex-input w-full h-[20px] gap-2">
          <p-checkbox [binary]="true" inputId="binary" formControlName="contactable_24h"></p-checkbox>
          <span
            class="flex items-center text-[14px] leading-5 text-palette-gray-700 font-medium h-[20px]">{{'OTA.CONTACT.CONTACTABLE'
            | translate}}</span>
        </div>
      </div>
    </div>
  </form>
</div>