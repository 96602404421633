import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ReservationService } from '@modules/hotel-booking/services/reservation-service/reservation.service';
import { PaymentData, BookedRoomsList } from '@modules/hotel-booking/models/payment-data.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-price-details-checkout',
  templateUrl: './price-details-checkout.component.html',
  styleUrls: ['./price-details-checkout.component.scss'],
  animations: [
    trigger('slideDownUp', [
      state('void', style({ height: '0', opacity: '0' })),
      state('*', style({ height: '*', opacity: '1' })),
      transition(':enter, :leave', [animate('0.3s ease-out')]),
    ]),
    trigger('rotateAnimation', [
      state('open', style({ transform: 'rotate(0deg)' })),
      state('closed', style({ transform: 'rotate(180deg)' })),
      transition('open <=> closed', animate('0.3s ease-out')),
    ]),
  ],
})
export class PriceDetailsCheckoutComponent {
  @Input() bookingDetail: any;
  isShowTax: boolean = false;
  totalPrice: number;
  subTotal: number;
  isShowTaxesCharges: boolean = true;
  voucher_checked: any = [];
  subscription: Subscription;

  constructor(private reservationService: ReservationService) {
    // this.subscription = this.reservationService.getDataResponse().subscribe((data: PaymentData) => {
    //   this.bookingDetail = data;
    //   this.voucher_checked = this.bookingDetail?.voucher_checked;
    //   this.totalPrice = this.bookingDetail?.end_price != null ? this.bookingDetail?.end_price : this.bookingDetail?.final_price; 
    // });
  }

  ngOnInit() {
    this.voucher_checked = this.bookingDetail?.voucher;
    this.totalPrice = this.bookingDetail?.end_price != null ? this.bookingDetail?.end_price : this.bookingDetail?.final_price; 
    this.subTotal = this.bookingDetail?.booked_rooms_list?.reduce(
      (totalPrice: any, room: any) => {
        return totalPrice + room.final_price_room;
      },
      0
    );
  }

  public formatCurrency(price: number) {
    return price.toLocaleString('en-US');
  }

  handleToggleDropdown(): void {
    this.isShowTax = !this.isShowTax;
  }

  formatDollar(price: number) {
    return new Intl.NumberFormat('en-US', {
      style: 'decimal',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(price);
  }

  toggleTaxesCharges() {
    this.isShowTaxesCharges = !this.isShowTaxesCharges;
  }
}
