import { Component, Input } from '@angular/core';

@Component({
  selector: 'ota-in-review-alert',
  templateUrl: './ota-in-review-alert.component.html',
  styleUrls: ['./ota-in-review-alert.component.scss'],
})
export class OtaInReviewAlertComponent {
  @Input() header: string =
    'OTA_MANAGEMENT.PROPERTY_INFORMATION.UNDER_REVIEW_HEADER';
  @Input() message: string =
    'OTA_MANAGEMENT.PROPERTY_INFORMATION.UNDER_REVIEW_MESSAGE';
  @Input() containerStyle: string;
  @Input() contentStyle: string;
}
