import { 
  Component,
  Output,
  EventEmitter,
  Input,
  HostListener,
  SimpleChanges,
  OnChanges,
  OnInit
} from '@angular/core';
import { DetailHotel } from '@modules/hotel-booking/models/view-details';
import { HotelDetailsService } from '@modules/hotel-booking/services/hotel-detail/hotel-detail.service';

interface Tab {
  title: string;
  short_name: string
}

@Component({
  selector: 'app-tab-menu',
  templateUrl: './tab-menu.component.html',
  styleUrls: ['./tab-menu.component.scss']
})

export class TabMenuComponent implements OnInit, OnChanges {
  activeTab: number | null = 0;
  animateBorder: boolean = false;
  @Output() indexSelectNumber = new EventEmitter();
  @Input() numberIndexSub:number;
  @Input() dataDetailHotel: DetailHotel;

  tabs: Tab[] = [
    { title: 'DETAIL.GENERAL', short_name: 'general'},
    { title: 'DETAIL.ABOUT', short_name: 'about' },
    { title: 'DETAIL.FACILITIES', short_name: 'amenities' },
    { title: 'DETAIL.POLICIES', short_name: 'policies' },
    { title: 'DETAIL.REVIEWS', short_name: 'reviews' }
  ];

  constructor(private hotelDetailsService: HotelDetailsService) {}

  ngOnInit(){
    this.activeTab = this.numberIndexSub;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataDetailHotel']) {
      if(this.dataDetailHotel) {
        if(!this.hotelDetailsService.isFacilities(this.dataDetailHotel)) this.removeTabByShortName('amenities');
        if(!this.hotelDetailsService.isDescription(this.dataDetailHotel)) this.removeTabByShortName('about');
      }
    }
  }

  removeTabByShortName(shortName: string): void {
    this.tabs = this.tabs.filter(tab => tab.short_name !== shortName);
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event) {
    this.activeTab = this.numberIndexSub;

  }

  setActiveTab(index: number): void {
    this.indexSelectNumber.emit(index);
  }
  }

