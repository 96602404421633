import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { PostRequest } from '@shared/models/post.model';
import { catchError, map, retry, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PostService {
  basePath = environment.baseURL;
  constructor(private http: HttpClient) {
  }

  post(body: any) {
    return this.http.post<PostRequest>(`${this.basePath}/posts/posts/link`, body).pipe(
      retry(1),
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }
  
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(() => {
      return errorMessage;
    });
  }


}
