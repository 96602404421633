import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { environment } from '@env/environment';
// import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-show-image-modal',
  templateUrl: './show-image-modal.component.html',
  styleUrls: ['./show-image-modal.component.scss'],
})
export class ShowImageModalComponent {
  selectedImage: any;
  hotel_id: any;
  image_urls: any[] = [];
  all_image_urls: any[] = [];
  _activeIndex: number = 0;
  pageIndex = 1;
  pageSize = 20;
  isCheckImageId: boolean;
  multiMedia: boolean = false;
  baseImageId = environment.STORAGE_SERVICE_API_BASE_PATH_ID_IMAGE;
  baseStreamVideo = environment.STORAGE_SERVICE_API_BASE_PATH_STREAM_VIDEO;
  baseUrl = environment.BASE_PATH;
  get activeIndex(): number {
    return this._activeIndex;
  }

  set activeIndex(newValue) {
    if (
      this.image_urls &&
      0 <= newValue &&
      newValue <= this.image_urls.length - 1
    ) {
      this._activeIndex = newValue;
    }
  }

  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 12,
    },
    {
      breakpoint: '768px',
      numVisible: 7,
    },
    {
      breakpoint: '370px',
      numVisible: 1,
    },
  ];

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef
  ) {
    if (this.config.data) {
      this._activeIndex = this.config.data.image_id;
      this.all_image_urls = this.config.data.image_urls;
      this.isCheckImageId = this.config.data.isCheckImageId;
      this.multiMedia = this.config.data.multiMedia || false;      
    }
  }
  ngOnInit() {
    const page = Math.ceil(this._activeIndex / this.pageSize);
    for (let i = 1; i <= page; i++) {
      this.getImages(this.pageIndex, this.pageSize);
      this.pageIndex++;
    }
    this.loadMorePictures();
  }

  ngAfterViewInit() {
    this.scrollImage();
  }

  onclose() {
    this.ref.close();
  }
  next() {
    this.activeIndex++;
    this.scrollImage();
    this.loadMorePictures();
  }
  prev() {
    this.activeIndex--;
    this.scrollImage();
  }
  onMouseWheelImage(event: WheelEvent) {
    const container = document.querySelector('.image-item');
    if (container) {
      const delta = event.deltaY || event.detail;
      if (delta > 0) this.next();
      else if (delta < 0) this.prev();
      event.preventDefault();
    }
  }
  scrollImage() {
    const tixPos = document.querySelector(`#image-` + this.activeIndex);
    if (tixPos) {
      tixPos.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }
  handleImageScroll(index: number): void {
    this.activeIndex = index;
    this.scrollImage();
    this.loadMorePictures();
  }
  getImages(pageNum: number, pageSize: number) {
    let value = this.all_image_urls.slice(
      (pageNum - 1) * pageSize,
      pageNum * pageSize
    );
    this.image_urls = [...this.image_urls, ...value];
  }
  loadMorePictures() {
    if (
      this.activeIndex >= this.image_urls.length - 15 &&
      this.image_urls.length < this.all_image_urls.length
    ) {
      this.getImages(this.pageIndex, this.pageSize);
      this.pageIndex += 1;
    }
  }

  isFileVideo(type: string){
    return type.includes('video');
  }

  replaceWithDefaultImage(event: any): void {
    event.target.src = 'assets/images/temporary/default-hotel.png';
  }
}
