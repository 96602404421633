<div class="h-[auto] mt-5 bg-white py-[30px] footer-section" *ngIf="isShowFooter">
    <div class="flex flex-col !gap-2 mx-auto">
        <div class="lg:mx-[12.5%] md:mx-[8%] mx-[2%] flex justify-center border-b border-dashed">
            <div class="w-full pt-5 pb-5 flex gap-[10px] justify-between max-w-[1200px]">
                <div class="flex-1 space-y-1">
                    <p class="text-palette-gray-900 text-base font-semibold overflow-hidden whitespace-nowrap text-ellipsis mb-3">
                        {{'FOOTER.ABOUT_SOCTRIP' | translate}}
                    </p>
                    <div *ngFor="let item of aboutUs.aboutSoctrip; let i = index" class="flex">
                        <p class="text-detail hover:text-blue-500 cursor-pointer {{i == 0 ? '' : '!mt-2'}}"
                        >{{item.name | translate}} </p>
                    </div>
                </div>
                <div class="flex-1 space-y-1 max-[1500px]:pl-0">
                    <p class="text-palette-gray-900 text-base font-semibold mb-3">{{'HEADER.MENU.BOOKING' | translate}}</p>
                    <div *ngFor="let item of aboutUs.services; let i = index" class="flex">
                        <p class="text-detail hover:text-blue-500 cursor-pointer {{i == 0 ? '' : '!mt-2'}}"
                            (click)="handleLinkBooking(item)">{{item.name | translate}} </p>
                    </div>
                </div>
                <div class="flex-1">
                    <p class="text-palette-gray-900 text-base font-semibold mb-3">{{'FOOTER.OTHER_SERVICES' | translate}}</p>
                    <div *ngFor="let item of aboutUs.otherServie; let i = index" class="flex">
                        <p class="text-detail hover:text-blue-500 cursor-pointer {{i == 0 ? '' : '!mt-2'}}"
                            (click)="handleLinkAbout(item)">{{item.name | translate}} </p>
                    </div>
                </div>
                <div class="flex-1 xl:pl-6 max-[1200px]:pl-0">
                    <p class="text-palette-gray-900 text-base font-semibold mb-3">{{'FOOTER.SUBSCRIPTION' | translate}}</p>
                    <p class="text-detail">{{'FOOTER.SUBSCRIPTION_DESCRIPTION' | translate}}</p>
                    <form [formGroup]="subscribeForm" (ngSubmit)="onSubmitSubscribe()">
                        <div class="flex mt-3 mb-2">
                            <input type="text" formControlName="email" pInputText
                                class="h-[44px] max-[1400px]:w-[100%] mr-2 w-[65%] font-inter font-normal 
                                        text-base text-palette-gray-500 border
                                        {{submittedSubscribe && (subscribeForm.get('email')?.hasError('pattern') || subscribeForm.get('email')?.hasError('invalidEmail')) && subscribeForm.get('email')?.touched ? 'border-red-500' : 'border-[#ced4da]'}}
                                        {{submittedSubscribe && subscribeForm.get('email')?.hasError('required') && subscribeForm.get('email')?.touched ? 'border-red-500' : 'border-[#ced4da]'}}"
                                #textInput
                                placeholder="{{'FOOTER.PLACEHOLDER.YOUR_EMAIL' | translate}}"
                                (input)="onInputChange(textInput.value)" />
                            <p-button type="submit"
                                class="register-btn text-white rounded-lg whitespace-nowrap !font-inter !font-semibold"
                                ><i class="sctr-icon-send-01 text-[20px] font-semibold"></i></p-button>
                        </div>
                    </form>
                    <div>
                        <div class="text-sm py-2 text-red-600 italic"
                            *ngIf="submittedSubscribe && subscribeForm.get('email')?.hasError('required') && subscribeForm.get('email')?.touched">
                            {{'FOOTER.VALIDATION.EMAIL_REQUIRED' | translate}}
                        </div>
                        <div class="text-sm py-2 text-red-600 italic"
                            *ngIf="submittedSubscribe && (subscribeForm.get('email')?.hasError('pattern') || subscribeForm.get('email')?.hasError('invalidEmail')) && subscribeForm.get('email')?.touched">
                            {{'FOOTER.VALIDATION.EMAIL_INVALID' | translate}}
                        </div>
                        <div class="text-sm py-2 text-green-600 italic" *ngIf="submittedSubscribe && isRegisterSuccess">
                            {{'FOOTER.VALIDATION.THANK_YOU' | translate}}
                        </div>
                    </div>
                    <div>
                        <div class="flex space-x-4 pt-3">
                            <div class="cursor-pointer" (click)="handleLinkSubscription('Google Play')">
                                <img src="assets/images/home/affiliate-brand/google-play.png" alt="">
                            </div>
                            <div class="cursor-pointer" (click)="handleLinkSubscription('App Store')">
                                <img src="assets/images/home/affiliate-brand/app-store.png" alt="">
                            </div>
                        </div>
                        <div class="flex space-x-4 pt-3">
                            <div class="cursor-pointer" (click)="handleLinkSubscription('Registered')">
                                <img src="assets/images/home/affiliate-brand/gov-vn-registered.png" alt="">
                            </div>
                            <div class="cursor-pointer" (click)="handleLinkSubscription('Announced')">
                                <img src="assets/images/home/affiliate-brand/gov-vn.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        
        <div class="lg:mx-[12.5%] md:mx-[8%] mx-[2%] flex flex-wrap justify-center">
            <div class="w-full flex flex-wrap pt-3 pb-5 gap-[10px] justify-between max-w-[1200px]">
                <!-- <div class="w-2/12 ml-2">
                        <div class="mt-1 min-h-[30px] min-w-[30px] lg:min-w-[124px] !flex justify-start items-center cursor-pointer">
                            <img class="min-w-[34px] max-w-[34px] lg:hidden" 
                                src="assets/images/logo/blue-logo-soctrip.svg" alt="">
                            <img class="min-h-[34px] max-h-[34px] hidden lg:block"
                                src="assets/images/logo/blue-soctrip-logo.svg" alt="">
                        </div>
                    </div> -->
                <div class="flex-1 flex flex-col items-start justify-start !gap-1 min-w-[260px]">
                    <p class="text-palette-gray-900 text-base font-semibold overflow-hidden text- line-clamp-2">
                        {{'FOOTER.US' | translate}} </p>
                    <common-icon-text class="flex items-center !gap-1" [icon]="'sctr-icon-phone-call-01'"
                        [iconStyle]="'text-palette-orange-dark-400 text-xl font-normal font-inter mt-0.5 mr-1 flex items-center'" 
                        [text]="aboutUs.contactUS.phoneNumber"
                        [textStyle]="'text-sm font-normal font-inter'"></common-icon-text>
                    <common-icon-text class="flex items-center !gap-1" [icon]="'sctr-icon-mail-01'"
                        [iconStyle]="'text-palette-orange-dark-400 text-xl font-normal font-inter mt-0.5 mr-1 flex items-center'"
                        [text]="aboutUs.contactUS.email"
                        [textStyle]="'text-sm font-normal font-inter overflow-hidden whitespace-nowrap text-ellipsis'"></common-icon-text>
                    <a href="{{aboutUs.contactUS.url}}" target="_blank">
                        <common-icon-text class="flex items-start !gap-1 mt-1" [icon]="'sctr-icon-marker-pin-01'"
                            [iconStyle]="'text-palette-orange-dark-400 text-xl font-normal font-inter mt-0.5 mr-1 flex items-start'" [text]=" aboutUs.contactUS.address"
                            [textStyle]="'text-sm font-normal font-inter line-clamp-4'"></common-icon-text>
                    </a>
                </div>
                <div class="flex-1 flex flex-col items-start justify-start !gap-1 min-w-[260px]">
                    <p class="text-palette-gray-900 text-base font-semibold overflow-hidden whitespace-nowrap text-ellipsis">
                        {{'FOOTER.VIETNAM_OFFICE' | translate}}</p>
                    <common-icon-text class="flex items-center !gap-1" [icon]="'sctr-icon-phone-call-01'"
                        [iconStyle]="'text-palette-orange-dark-400 text-xl font-normal font-inter mt-0.5 mr-1 flex items-center'" 
                        [text]="aboutUs.contactVietNam.phoneNumber"
                        [textStyle]="'text-sm font-normal font-inter'"></common-icon-text>
                    <common-icon-text class="flex items-center !gap-1" [icon]="'sctr-icon-mail-01'"
                        [iconStyle]="'text-palette-orange-dark-400 text-xl font-normal font-inter mt-0.5 mr-1 flex items-center'"
                        [text]="aboutUs.contactVietNam.email"
                        [textStyle]="'text-sm font-normal font-inter overflow-hidden whitespace-nowrap text-ellipsis'"></common-icon-text> 
                    <a href="{{aboutUs.contactVietNam.url}}" target="_blank">
                        <common-icon-text class="flex items-start !gap-1 mt-1" [icon]="'sctr-icon-marker-pin-01'"
                            [iconStyle]="'text-palette-orange-dark-400 text-xl font-normal font-inter mt-0.5 mr-1 flex items-start'" [text]=" aboutUs.contactVietNam.address"
                            [textStyle]="'text-sm font-normal font-inter line-clamp-4'"></common-icon-text>
                    </a>                    
                </div>
                <div class="flex-1 flex flex-col items-start justify-start !gap-1 min-w-[260px]">
                    <p class="text-palette-gray-900 text-base font-semibold overflow-hidden whitespace-nowrap text-ellipsis">
                        {{'FOOTER.INDIA_OFFICE' | translate}}</p>
                    <common-icon-text class="flex items-center !gap-1" [icon]="'sctr-icon-phone-call-01'"
                        [iconStyle]="'text-palette-orange-dark-400 text-xl mt-0.5 mr-1 flex items-center'"
                        [text]="aboutUs.contactIndia.phoneNumber" [textStyle]="'text-sm font-normal font-inter text-sm font-normal font-inter'"></common-icon-text>
                    <common-icon-text class="flex items-center !gap-1" [icon]="'sctr-icon-mail-01'"
                        [iconStyle]="'text-palette-orange-dark-400 text-xl mt-0.5 mr-1 flex items-center'" [text]="aboutUs.contactIndia.email"
                        [textStyle]="'text-sm font-normal font-inter overflow-hidden whitespace-nowrap text-ellipsis'"></common-icon-text>
                    <a href="{{aboutUs.contactIndia.url}}" target="_blank">
                        <common-icon-text class="flex items-start !gap-1" [icon]="'sctr-icon-marker-pin-01'"
                            [iconStyle]="'text-palette-orange-dark-400 text-xl font-normal font-inter mt-0.5 mr-1 flex items-start'" [text]=" aboutUs.contactIndia.address"
                            [textStyle]="'text-sm font-normal font-inter line-clamp-4'"></common-icon-text>
                    </a>
                </div>
                
                <div class="max-[1418px]:hidden flex-1 min-w-[240px]">
                    <span class="text-palette-gray-700 text-sm font-normal font-inter"> {{'SIGN_IN.COPYRIGHT' | translate: { syntax: currentYear } }}</span>
                </div>
            </div>
            <div class="min-[1419px]:!hidden flex justify-center items-center !pb-3">
                <span class="text-palette-gray-700 text-sm font-normal font-inter"> {{'SIGN_IN.COPYRIGHT' | translate: { syntax: currentYear } }}</span>
            </div>
        </div>
    </div>
</div>

<div >
    <p-dialog header="{{'FOOTER.LANGUAGE_SETTING.TITLE' | translate}}" [(visible)]="visiblePopupChangeLanguage" [modal]="true" [style]="{ width: '480px', height: '419px', position: 'absolute' }"
      [draggable]="false" (onHide)="onCloseModal()" [blockScroll]="true" >
      <div class="flex flex-col px-4 pb-0  bg-white h-full relative">
        <div class="space-y-3">
            <div >
                <div class="text-palette-gray-700 text-sm font-medium mb-[6px]">{{'FOOTER.LANGUAGE_SETTING.REGION' | translate}}</div>
                <p-dropdown [options]="countries" [(ngModel)]="selectedCountry" optionLabel="name" styleClass="!h-[44px] w-full" 
                (onChange)="onChangeCountry($event)"></p-dropdown>
            </div>
            <div >
                <div class="text-palette-gray-700 text-sm font-medium mb-[6px]">{{'FOOTER.LANGUAGE_SETTING.LANGUAGE' | translate}}</div>
                <p-dropdown [options]="languages" [(ngModel)]="selectedLanguage" optionLabel="name" 
                styleClass="!h-[44px] w-full" (onChange)="onChangeLanguage($event)">
                <ng-template let-language pTemplate="item">
                    <div class="flex align-items-center gap-2">
                        <img src='{{language.icon_name}}' style="width: 18px"/>
                        <div>{{ language.name }}</div>
                    </div>
                </ng-template>
            </p-dropdown>
            </div>
            <div >
                <div class="text-palette-gray-700 text-sm font-medium mb-[6px]">{{'FOOTER.LANGUAGE_SETTING.CURRENCY' | translate}}</div>
                <p-dropdown [options]="currencies" [(ngModel)]="selectedCurrency" optionLabel="value"
                 styleClass="!h-[44px] w-full" (onChange)="onChangeCurrency($event)">
                 <ng-template let-currency pTemplate="item">
                    <div class="flex align-items-center operatingSystems-item">
                      <div>{{currency.code}} {{currency.symbol}}</div>
          
                    </div>
                  </ng-template></p-dropdown>
            </div>
        </div>
      
        <div class="space-x-2 flex h-[44px] justify-end absolute bottom-4 right-4"> 
            <div
            class="
              text-base font-semibold p-button-text w-[100px] h-full border px-[18px] border-palette-gray-700
               text-palette-gray-700 py-2.5 justify-center cursor-pointer rounded-lg flex 
            "
            (click)="onCloseModal()"
            >{{'COMMON.CANCEL' | translate}}</div>
            <div (click)="handleClickApply()" 
            class="w-[100px] h-[44px] bg-branding-primary-600 cursor-pointer rounded-lg font-inter text-lg font-semibold text-white flex justify-center items-center ">
            {{'COMMON.APPLY' | translate}}
          </div>
        </div>
      </div>
    </p-dialog>
  </div>
  
