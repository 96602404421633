<div #selectedCardRef class="!mb-3 !p-4 flex !gap-4 border border-palette-gray-200 rounded-lg"
    id="map-{{item.property_id}}" (click)="childClick($event)">
    <div *ngIf="item" class="md:relative md:!w-[31.41%]">
        <img *ngIf="item.property_image_ids?.length" class="!h-[96px] !w-[96px] rounded-md object-cover"
            src="{{baseImageId}}/{{ item.property_image_ids[0]}}.webp" (error)="replaceWithDefaultImage($event)" />
        <img 
            *ngIf="!item.property_image_ids?.length"
            class="!h-[96px] !w-[96px] rounded-md object-cover"
            src="{{item.property_image_overview_url}}"
            (error)="replaceWithDefaultImage($event)"
        />
        
    </div>
    <div class="md:!w-[64.39%] flex flex-col justify-between">
        <div>
            <app-star [count]="item.star" starStyle="!gap-0.5"
                iconStyle="sctr-icon-solid-star-01 text-palette-yellow-400 text-xs !h-3 !w-3">
            </app-star>
            <h1 #propertyName [tooltipDisabled]="!isDisplayToolTip"
                class="seo-title font-inter !text-sm !font-semibold text-palette-gray-900 max-w-full whitespace-nowrap overflow-hidden text-ellipsis"
                pTooltip="{{item.property_name}}" tooltipStyleClass="tooltipCustom">{{ item.property_name }}</h1>
            <div class="hotel-address flex gap-1">
                <img src="assets/images/home/icon/marker-pin-01.svg" width="16px" height="16px" alt="">
                <span
                    class="text-sm font-medium font-inter text-palette-gray-500 max-w-full whitespace-nowrap overflow-hidden text-ellipsis"
                    pTooltip="{{item.address}}" tooltipStyleClass="tooltipCustom">{{ item.address }}</span>
            </div>
        </div>
        <span *ngIf="isLoading; then loading; else showPriceCache"></span>
        <ng-template #loading>
            <app-loading></app-loading>
        </ng-template>
        <ng-template #showPriceCache>
            <span *ngIf="isLoadingAPI; then succes; else fail"></span>
            <ng-template #fail>
                <div *ngIf="item.net_price" class="hotel-price flex font-inter items-center ">
                    <span class="text-palette-gray-700 text-xs font-inter font-medium">{{ 'PAYMENT.FROM' | translate }}</span>
                    <span class="seo-price text-red-500 text-sm font-semibold ml-1 flex justify-start">
                            <app-price-format [price]="item.net_price"></app-price-format>
                    </span>
                </div>
                <div *ngIf="!item.net_price">
                    <div class="flex items-center !gap-x-1 !mt-1">
                        <img class="!w-[25px] !h-[25px]" src="assets/icons/lists_of_hotel/book-fully.svg">
                        <p class="font-inter !text-sm !font-normal text-palette-gray-700">{{ 'SEARCH_RESULT.FULLY_BOOKED' | translate }}</p>
                    </div>
                </div>
            </ng-template>
            <ng-template #succes>
                <app-loading></app-loading>
            </ng-template>
            <!-- <div *ngIf="!price.net_price">
                <div class="flex items-center !gap-x-1 !mt-1">
                    <img class="!w-[25px] !h-[25px]" src="assets/icons/lists_of_hotel/book-fully.svg">
                    <p class="font-inter !text-sm !font-normal text-palette-gray-700">{{ 'SEARCH_RESULT.FULLY_BOOKED' | translate }}</p>
                </div>
            </div> -->
        </ng-template>
    </div>
</div>