<div class="container-tab-menu">
  <div class="tab-menu gap-0 md:gap-3">
    <div class="item-tab-menu truncate text-ellipsis overflow-hidden text-[13px] md:text-base px-[6px] md:px-3"
      *ngFor="let tab of tabs; let i = index"
      [ngClass]="{ 'active': activeTab === i }"
      (click)="setActiveTab(i)"
    >
      {{ tab.title | translate}}
    </div>
  </div>
</div>

