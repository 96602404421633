import {
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import {
  BsDatepickerDirective,
  BsDaterangepickerContainerComponent,
  BsLocaleService,
} from 'ngx-bootstrap/datepicker';
import {
  Subject,
  Subscription,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  takeUntil,
  tap,
  timeout,
} from 'rxjs';
import { OverlayPanel } from 'primeng/overlaypanel';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AgeLimitChildren } from '@modules/hotel-booking/models/age-limit-child.model';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { SearchBarService } from '@modules/hotel-booking/services/search-bar.service';
import { scrollToTop } from '@shared/utils/helper';
import { SoctripTranslationService } from 'src/app/services/soctrip-translation/soctrip-translation.service';
import {
  RecommendPlace,
  RecommendPlaceRequest,
  RecommendPlaceResponse,
} from '@modules/hotel-booking/models/recommendPlaces.model';
import { SearchBarRoomService } from '@modules/hotel-booking/services/search-bar-room.service';
import { TotalPaymentService } from '@modules/hotel-booking/services/total-payment.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DEFAULT_SEARCH_PARAMS, TIME_OUT_REQUEST } from '@shared/constant';
import { BookingFamousPlacesControllerService } from '@booking/angular-booking-famous-spot-service';
import {
  ISearchBarData,
  SearchBarDataService,
} from '@modules/hotel-booking/services/search-bar-data/search-bar-data.service';
// import { any } from 'cypress/types/bluebird';
import { PlaceType } from '@shared/enum/recommend-place.enum';
import { UserService } from 'src/app/core/services/user/user.service';
import { FooterService } from '@modules/hotel-booking/services/footer/footer.service';
import moment from 'moment';
import { CurrencyService } from '@modules/users/services/currency.service';
import { UserInfo } from '@modules/users/models/user';
import { viLocale } from 'src/assets/i18n/vi';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { environment } from '@env/environment';
import { CountryCodeService } from '@modules/users/services/country-code.service';
import { GeolocationService } from 'src/app/services/geolocation/geolocation.service';

defineLocale('vi', viLocale);
export type DropdownValue = string | number;

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  providers: [MessageService],
})
export class SearchBarComponent implements OnChanges, OnDestroy {
  @ViewChild('inputRef') inputElement: ElementRef;
  @ViewChild('addressPanel') addressPanel: OverlayPanel;
  @ViewChild('filterAmountPeoplePanel') filterAmountPeoplePanel: OverlayPanel;
  @ViewChild('rangeCalendarPanel') rangeCalendarPanel: OverlayPanel;
  @ViewChild('selectOptionRef') selectOptionRef: ElementRef;
  @ViewChild('selectDateCheckIn') selectDateCheckIn!: ElementRef;
  @ViewChild('selectDateCheckout') selectDateCheckout!: ElementRef;
  @ViewChild('rangeCalendarCheckIn') rangeCalendarCheckIn: ElementRef;
  @ViewChild('rangeCalendarCheckOut') rangeCalendarCheckOut: ElementRef;
  @ViewChild('daterangepicker', { static: false })
  daterangepicker: BsDatepickerDirective;
  @ViewChild('bsDaterangepicker', { read: ElementRef })
  bsDaterangepicker: ElementRef;

  @Input() receivedData: any;
  @Input() isMap: boolean;
  @Input() isShowCalendar: boolean = false;

  @Output() searchBarUpdateData = new EventEmitter<Event>();
  @Output() ageDropdownData = new EventEmitter<Event>();
  @Output() selectDateValue = new EventEmitter<Event>();

  private searchModelChangeSubscription: Subscription;
  private searchHotelSubscription: Subscription;

  pipe = new DatePipe('en-US');
  destination: string;
  dummyEvent: any = {};
  currentDate: Date = new Date(Date.now());
  maxDate: Date | null;
  control = new FormControl(null);
  startDate: Date;
  tempDateCheckIn: string | null;
  tempDateCheckOut: string | null;
  searchText: string = '';
  searchModelChanged: Subject<string> = new Subject<string>();
  myFormDropdownAge: FormGroup;
  ageChildren: AgeLimitChildren[] = [];
  selectedDates: (Date | undefined)[] | undefined;
  valueCheckIn: string | null;
  valueCheckOut: string | null;
  valueSelectDate: Date[];
  isFocused: boolean = false;
  ageDropdownList: any;
  placeholderText: string = 'Enter place or hotel';
  languageSubscription: Subscription;
  yourLocationText: string;
  isSearchByLocation = false;
  addressList: RecommendPlace[] = [];
  isLoading: boolean = false;
  onDestroy$: Subject<void> = new Subject();
  dataSearch: ISearchBarData;
  isFocusCheckAvailableRoom = false;
  userProfile: UserInfo | null;
  isButtonClearIcon: boolean = false;
  formatDateVN: string = 'dd/MM/YYYY';
  formatDateUS: string = 'MM/dd/YYYY';
  isLanguageUS: boolean = true;
  countryCode: string = 'US';
  visibleAllowLocation: boolean = false;
 

  get dropdowns() {
    return this.myFormDropdownAge.get('dropdowns') as FormArray<
      FormControl<DropdownValue>
    >;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private messageService: MessageService,
    private searchResultService: SearchBarService,
    private soctripTranslationService: SoctripTranslationService,
    private translateService: TranslateService,
    private searchBarRoomService: SearchBarRoomService,
    private totalPaymentService: TotalPaymentService,
    private bookingFamousPlacesControllerService: BookingFamousPlacesControllerService,
    private searchBarDataService: SearchBarDataService,
    private userService: UserService,
    private localeService: BsLocaleService,
    private currencyService: CurrencyService,
    private cdRef: ChangeDetectorRef,
    private countryCodeService: CountryCodeService,
    private geolocationService: GeolocationService
  ) {
    this.getAgeChild();
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.isLanguageUS = event?.lang?.toUpperCase() === 'EN' ? true : false;
      this.updateLocaleCalendar();
      if(!this.isLanguageUS) {
        this.valueCheckIn = this.pipe.transform(
          moment(this.valueCheckIn).toDate(),
          this.isLanguageUS ? this.formatDateUS : this.formatDateVN
        );
        this.valueCheckOut = this.pipe.transform(
          moment(this.valueCheckOut).toDate(),
          this.isLanguageUS ? this.formatDateUS : this.formatDateVN
        );
      }
      else {
        this.valueCheckIn = this.pipe.transform(
          moment(this.convertDateTime(this.valueCheckIn!)).toDate(),
          this.isLanguageUS ? this.formatDateUS : this.formatDateVN
        );
        this.valueCheckOut = this.pipe.transform(
          moment(this.convertDateTime(this.valueCheckOut!)).toDate(),
          this.isLanguageUS ? this.formatDateUS : this.formatDateVN
        );
      }
      this.yourLocationText = this.translateService.instant(
        'SEARCH.YOUR_LOCATION'
      );
      if(this.receivedData.destination_name === 'Your location' || this.receivedData.destination_name === 'Vị trí của bạn') {
        this.isSearchByLocation = true;
        this.receivedData.destination_name = this.yourLocationText;
      }
      this.cdRef.detectChanges();
    });
  }

  convertDateTime(dateTime: string) {
    let arrDateTime = dateTime.split('/');
    let temp = arrDateTime[0];
    arrDateTime[0] = arrDateTime[1];
    arrDateTime[1] = temp;
    return arrDateTime.join('/');
  }

  ngOnInit(): void {
    this.countryCodeService.getCountryCode().subscribe({
      next: (countryCode: string | null) => {
        if(countryCode) {
          this.countryCode = countryCode;
        }
      },
    });
    this._OnInit();
    this.handleSearchPlaces();
    this.placeholderText = 'SEARCH.PLACEHOLDER.PLACE';
    this.yourLocationText = this.translateService.instant(
      'SEARCH.YOUR_LOCATION'
    );
    this.userService.getUserInfor().subscribe((res) => {
      this.userProfile = res;
    });
    this.isButtonClearIcon = this.receivedData.destination_name ? true : false;
    this.isLanguageUS = this.translateService?.currentLang?.toUpperCase() === 'EN' ? true : false;
    this.updateLocaleCalendar();
  }
  updateLocaleCalendar(){
    let selectedLanguage = localStorage.getItem(environment.USER_PROFILE);
    if (selectedLanguage !== null) {
      const userProfile = JSON.parse(selectedLanguage);
      if (
        typeof userProfile === 'object' &&
        'language' in userProfile &&
        (userProfile.language as string).toLocaleLowerCase() === 'vi'
      ) {
        this.localeService.use('vi');
      } else {
        this.localeService.use('en');
      }
    } else {
      this.localeService.use('en');
    }

  }

  _OnInit() {
    this.receivedData.currency = 'USD';
    this.dataSearch = {
      destination: this.destination,
      destination_name: this.receivedData.destination_name,
      adults: this.receivedData.numberOfAdults,
      children: this.receivedData.numberOfChildren,
      child_ages_list: this.ageDropdownList,
      rooms: this.receivedData.numberOfRooms,
      check_in: this.receivedData.initCheckInDate!,
      check_out: this.receivedData.initCheckOutDate,
      currency: this.receivedData.currency,
      country_code: this.receivedData.country_code,
    };
    this.yourLocationText = this.translateService.instant(
      'SEARCH.YOUR_LOCATION'
    );
    if(this.receivedData.destination_name === 'Your location' || this.receivedData.destination_name === 'Vị trí của bạn') {
      // this.dataSearch.destination_name = this.yourLocationText;
      this.receivedData.destination_name = this.translateService.instant(
        'SEARCH.YOUR_LOCATION'
      );
    }
    this.searchBarDataService.setSearchData(this.dataSearch);
    this.myFormDropdownAge = this.fb.group({
      dropdowns: this.fb.array([]) as FormArray<FormControl<DropdownValue>>,
    });

    this.languageSubscription =
      this.soctripTranslationService.language$.subscribe(() => {
        this.ageChildren = [];
        this.getAgeChild();
      });

    this.setValueAgesDropdown();
    this.selectedDates = [
      this.receivedData.initCheckInDate!,
      this.receivedData.initCheckOutDate,
    ];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['receivedData']) {
      this._OnInit();
    }
  }

  onInputChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.searchModelChanged.next(inputElement.value)
    this.isButtonClearIcon = this.receivedData.destination_name ? true : false;
    if (!this.addressPanel.overlayVisible) {
      this.addressPanel?.show(event);
    }
  }

  handleSearchPlaces() {
    this.searchModelChangeSubscription = this.searchModelChanged
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((searchText: string) => {
        const params: RecommendPlaceRequest = {
          keyword: searchText,
        };
        this.searchText = searchText;
        this.getRecommendPlaces(params);
      });
  }

  getRecommendPlaces(paramsSearch?: RecommendPlaceRequest) {
    this.searchHotelSubscription && this.searchHotelSubscription.unsubscribe(); // Cancel previous request
    this.isLoading = true;
    this.isSearchByLocation = false;
    const body = {
      user_id: this.userService.getUserUuid(),
    };
    if (this.addressPanel.overlayVisible) {
      this.searchHotelSubscription = this.bookingFamousPlacesControllerService
        .suggestLocations(
          body,
          DEFAULT_SEARCH_PARAMS.page_num,
          DEFAULT_SEARCH_PARAMS.page_size,
          this.countryCode,
          paramsSearch?.keyword
        )
        .pipe(
          takeUntil(this.onDestroy$),
          timeout(TIME_OUT_REQUEST),
          tap((res: RecommendPlaceResponse) => {
            if (res.success) {
              this.addressList = this.getPlaceType(res.data!);
            } else if (!res.success && paramsSearch) {
              this.addressList = [];
            }
            this.searchHotelSubscription.unsubscribe();
          }),
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe();
    }
  }

  getPlaceType(places: RecommendPlace[]) {
    return places?.map((address: RecommendPlace) => {
      let icon_class = '';
      let bg_color = '';
      switch (address.object_type.toLocaleLowerCase()) {
        case PlaceType.CITY:
          icon_class =
            'sctr-icon-marker-pin-02 text-xl leading-5 text-palette-blue-600';
          bg_color = 'bg-palette-blue-100';
          break;
        case PlaceType.PROPERTY:
          icon_class =
            'sctr-icon-building-01 text-xl leading-5 text-palette-blue-600';
          bg_color = 'bg-palette-blue-100';
          break;
        case PlaceType.HISTORY:
          icon_class =
            'sctr-icon-clock-fast-forward text-xl leading-5 text-palette-gray-500';
          bg_color = 'bg-palette-gray-200';
          break;
        default:
          icon_class =
            'sctr-icon-marker-pin-02 text-xl leading-5 text-palette-blue-600';
          bg_color = 'bg-palette-blue-100';
          break;
      }
      return {
        ...address,
        icon_class,
        bg_color,
      };
    });
  }

  getLocation(): void {
    this.geolocationService.requestLocation()
      .then((location) => {
        const { longitude, latitude } = location;
        this.receivedData.destination_name = this.translateService.instant(
          'SEARCH.YOUR_LOCATION'
        );
        this.dataSearch = {
          ...this.dataSearch,
          destination_name: this.yourLocationText,
          destination: this.yourLocationText,
        };
        this.searchBarDataService.setSearchData({
          ...this.dataSearch,
          destination_name: this.yourLocationText,
          destination: this.yourLocationText,
        });
        this.receivedData.latitude = latitude;
        this.receivedData.longitude = longitude;
        // Hardcode for getting data
        // this.receivedData.latitude = 37.3329224;
        // this.receivedData.longitude = -121.88931009999999;
        this.addressPanel.hide();
        this.isSearchByLocation = true;

      });
  }

  handleHotelNearU() {
    this.geolocationService.checkGeolocationPermission()
      .then((status) => {
        if (status === 'granted') {
          this.getLocation();
        } else if (status === 'prompt') {
          this.visibleAllowLocation = true;
        } else if (status === 'denied') {
          this.visibleAllowLocation = true;
        }
      })
  }

  getAgeChild() {
    for (let i = 0; i < 18; i++) {
      if (i == 0) {
        this.ageChildren.push({
          id: 1,
          age_word:
            `<${i + 1} ` +
            this.soctripTranslationService.getTranslation('SEARCH.YEAR_OLD'),
          age_number: `${i}`,
        });
      } else if (i == 1) {
        this.ageChildren.push({
          id: 2,
          age_word:
            `${i} ` +
            this.soctripTranslationService.getTranslation('SEARCH.YEAR_OLD'),
          age_number: `${i}`,
        });
      } else {
        this.ageChildren.push({
          id: i + 1,
          age_word:
            `${i} ` +
            this.soctripTranslationService.getTranslation('SEARCH.YEARS_OLD'),
          age_number: `${i}`,
        });
      }
    }
  }

  setValueAgesDropdown() {
    const newDropdownValues: DropdownValue[] =
      this.receivedData.numberAgeChildren?.map((item: string) => {
        return item.toString();
      });
    const dropdownsFormArray = this.myFormDropdownAge.get(
      'dropdowns'
    ) as FormArray<FormControl<DropdownValue>>;
    newDropdownValues?.forEach((value) => {
      const control: any = new FormControl(value);
      dropdownsFormArray.push(control);
    });
  }

  ngOnDestroy(): void {
    this.searchModelChangeSubscription.unsubscribe();
    this.languageSubscription.unsubscribe();
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.hideOverlayAddress();
    this.hideOverlaySelectPeople();
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;
    const dateRangePickerInputs =
      document.querySelectorAll('.date-range-picker');
    let clickedInsidePicker = false;

    const dateRangePickerArray = Array.from(dateRangePickerInputs);
    for (const input of dateRangePickerArray) {
      if (input.contains(targetElement)) {
        clickedInsidePicker = true;
        break;
      }
    }
    if (!clickedInsidePicker) {
      const element = document.querySelector('.bs-datepicker') as HTMLElement;
      this.daterangepicker.hide();
    }
  }

  onClickInputPlace(event: Event) {
    if (this.addressPanel.overlayVisible) return; // IF already visible => return
    this.addressPanel.show(event);
    if (this.receivedData.selectedHotel === this.yourLocationText) {
      this.receivedData.selectedHotel = '';
    }
    if(
      this.receivedData.destination_name === 'Your location'
      || this.receivedData.destination_name === 'Vị trí của bạn'
    ) {
      this.getRecommendPlaces({ keyword: '' });
      return;
    }
    if (!(this.addressList.length > 0)) {
      this.getRecommendPlaces({ keyword: this.receivedData.destination_name });
    }
  }

  // Handle change address
  handleInputChange() {
    this.addressPanel.show(this.dummyEvent);
  }

  hideOverlayAddress() {
    this.addressPanel.hide();
  }

  onInputPlaceFocus() {
    this.placeholderText = 'SEARCH.PLACEHOLDER.PLACE';
    this.isFocused = true;
  }

  onHideAddressOverlay() {
    this.searchBarDataService.setPanelStatus(false);
    this.searchHotelSubscription?.unsubscribe();
    this.receivedData.selectedHotel =
      this.dataSearch.destination ||
      this.route.snapshot.queryParamMap.get('destination');
  }

  onShowAddressOverlay() {
    this.searchBarDataService.setPanelStatus(true);
  }

  onInputPlaceBlur() {
    this.isFocused = false;
    // if not have data and click outside
    if(
      !(this.receivedData.destination_name === 'Your location'
      || this.receivedData.destination_name === 'Vị trí của bạn')
    ) {
      if (!this.addressList?.length) {
        this.searchText = '';
        this.receivedData.selectedHotel = null;
        this.receivedData.destination_name = null;
        return;
      }
      if (!this.receivedData.destination_name) {
        this.placeholderText = 'SEARCH.PLACEHOLDER.PLACE';
        return;
      }
      if (this.isSearchByLocation) {
        this.dataSearch = {
          ...this.dataSearch,
          destination: this.yourLocationText,
          destination_name: this.yourLocationText,
        };
        this.searchBarDataService.setSearchData({
          ...this.dataSearch,
          destination: this.yourLocationText,
          destination_name: this.yourLocationText,
        });
        this.receivedData.selectedHotel = this.yourLocationText;
        this.receivedData.destination_name = this.yourLocationText;
        return;
      }
      this.dataSearch = {
        ...this.dataSearch,
        destination_name: this.addressList[0].object_name,
        destination: this.addressList[0].property_id
          ? this.addressList[0].property_id
          : this.addressList[0].id,
        country_code: this.addressList[0].country_code,
      };
      this.searchBarDataService.setSearchData({
        ...this.dataSearch,
        destination_name: this.addressList[0].object_name,
        destination: this.addressList[0].property_id
          ? this.addressList[0].property_id
          : this.addressList[0].id,
        country_code: this.addressList[0].country_code,
      });
      this.receivedData.country_code = this.addressList[0].country_code;
      this.receivedData.property_id = this.addressList[0].property_id;
      this.receivedData.destination_name = this.addressList[0].object_name;
      this.receivedData.dest_type = this.addressList[0].property_id
        ? PlaceType.PROPERTY.toUpperCase()
        : PlaceType.PLACES.toUpperCase();
    }
  }

  //Handle change people
  handleSelectPeople(event: Event) {
    this.filterAmountPeoplePanel.show(event);
    this.isFocusCheckAvailableRoom = true;
  }

  hideOverlaySelectPeople() {
    !this.isMap && this.filterAmountPeoplePanel.hide();
  }

  onFocusDropdownAge() {
    this.selectOptionRef.nativeElement.focus();
    this.filterAmountPeoplePanel.show(this.dummyEvent);
    this.convertAgeListDefault();
  }

  plusNumberOfRooms() {
    if (this.receivedData.numberOfRooms < 8) {
      this.receivedData.numberOfRooms =
        parseInt(this.receivedData.numberOfRooms) + 1;
      if (this.receivedData.numberOfAdults <= this.receivedData.numberOfRooms) {
        this.receivedData.numberOfAdults = this.receivedData.numberOfRooms;
        this.dataSearch = {
          ...this.dataSearch,
          rooms: this.receivedData.numberOfRooms,
          adults: this.receivedData.numberOfAdults,
        };
        this.searchBarDataService.setSearchData({
          ...this.dataSearch,
          rooms: this.receivedData.numberOfRooms,
          adults: this.receivedData.numberOfAdults,
        });
      }
      if (this.isFocusCheckAvailableRoom) {
        if (this.receivedData.titleSearchButton == 'DETAIL.CHANGE_SEARCH') {
          this.receivedData = {
            ...this.receivedData,
            isDisableSearchBtn: false,
            titleSearchButton: 'DETAIL.APPLY_SEARCH',
          };
        } else {
          this.receivedData = {
            ...this.receivedData,
            isDisableSearchBtn: false,
          };
        }
      }
    }
  }

  subtractNumberOfRooms() {
    if (this.receivedData.numberOfRooms > 1) {
      this.receivedData.numberOfRooms -= 1;
      this.dataSearch = {
        ...this.dataSearch,
        rooms: this.receivedData.numberOfRooms,
      };
      this.searchBarDataService.setSearchData({
        ...this.dataSearch,
        rooms: this.receivedData.numberOfRooms,
      });
      if (this.isFocusCheckAvailableRoom) {
        if (this.receivedData.titleSearchButton == 'DETAIL.CHANGE_SEARCH') {
          this.receivedData = {
            ...this.receivedData,
            isDisableSearchBtn: false,
            titleSearchButton: 'DETAIL.APPLY_SEARCH',
          };
        } else {
          this.receivedData = {
            ...this.receivedData,
            isDisableSearchBtn: false,
          };
        }
      }
    }
  }

  plusNumberOfAdults() {
    if (this.receivedData.numberOfAdults < 36) {
      this.receivedData.numberOfAdults =
        parseInt(this.receivedData.numberOfAdults) + 1;
      this.dataSearch = {
        ...this.dataSearch,
        adults: this.receivedData.numberOfAdults,
      };
      this.searchBarDataService.setSearchData({
        ...this.dataSearch,
        adults: this.receivedData.numberOfAdults,
      });

      if (this.isFocusCheckAvailableRoom) {
        if (this.receivedData.titleSearchButton == 'DETAIL.CHANGE_SEARCH') {
          this.receivedData = {
            ...this.receivedData,
            isDisableSearchBtn: false,
            titleSearchButton: 'DETAIL.APPLY_SEARCH',
          };
        } else {
          this.receivedData = {
            ...this.receivedData,
            isDisableSearchBtn: false,
          };
        }
      }
    }
  }

  subtractNumberOfAdults() {
    if (this.receivedData.numberOfAdults > this.receivedData.numberOfRooms) {
      this.receivedData.numberOfAdults -= 1;
      this.dataSearch = {
        ...this.dataSearch,
        adults: this.receivedData.numberOfAdults,
      };
      this.searchBarDataService.setSearchData({
        ...this.dataSearch,
        adults: this.receivedData.numberOfAdults,
      });
      if (this.isFocusCheckAvailableRoom) {
        if (this.receivedData.titleSearchButton == 'DETAIL.CHANGE_SEARCH') {
          this.receivedData = {
            ...this.receivedData,
            isDisableSearchBtn: false,
            titleSearchButton: 'DETAIL.APPLY_SEARCH',
          };
        } else {
          this.receivedData = {
            ...this.receivedData,
            isDisableSearchBtn: false,
          };
        }
      }
    }
  }

  plusNumberOfChildren() {
    if (this.receivedData.numberOfChildren < 8) {
      this.receivedData.numberOfChildren =
        parseInt(this.receivedData.numberOfChildren) + 1;
      if (this.dropdowns.length < 9) {
        this.dropdowns.push(this.fb.control('') as FormControl<DropdownValue>);
      }
      this.convertAgeListDefault();
      this.dataSearch = {
        ...this.dataSearch,
        children: this.receivedData.numberOfChildren,
      };
      this.searchBarDataService.setSearchData({
        ...this.dataSearch,
        children: this.receivedData.numberOfChildren,
      });
      if (this.isFocusCheckAvailableRoom) {
        if (this.receivedData.titleSearchButton == 'DETAIL.CHANGE_SEARCH') {
          this.receivedData = {
            ...this.receivedData,
            isDisableSearchBtn: false,
            titleSearchButton: 'DETAIL.APPLY_SEARCH',
          };
        } else {
          this.receivedData = {
            ...this.receivedData,
            isDisableSearchBtn: false,
          };
        }
      }
    }
  }

  subtractNumberOfChildren(index: number) {
    if (this.receivedData.numberOfChildren > 1) {
      this.receivedData.numberOfChildren -= 1;
      if (this.isFocusCheckAvailableRoom) {
        if (this.receivedData.titleSearchButton == 'DETAIL.CHANGE_SEARCH') {
          this.receivedData = {
            ...this.receivedData,
            isDisableSearchBtn: false,
            titleSearchButton: 'DETAIL.APPLY_SEARCH',
          };
        } else {
          this.receivedData = {
            ...this.receivedData,
            isDisableSearchBtn: false,
          };
        }
      }
    } else {
      if (
        this.receivedData.numberOfAdults > 0 &&
        this.receivedData.numberOfChildren > 0
      ) {
        this.receivedData.numberOfChildren -= 1;
        if (this.isFocusCheckAvailableRoom) {
          if (this.receivedData.titleSearchButton == 'DETAIL.CHANGE_SEARCH') {
            this.receivedData = {
              ...this.receivedData,
              isDisableSearchBtn: false,
              titleSearchButton: 'DETAIL.APPLY_SEARCH',
            };
          } else {
            this.receivedData = {
              ...this.receivedData,
              isDisableSearchBtn: false,
            };
          }
        }
      }
    }
    if (this.receivedData.numberOfChildren < 8) {
      this.dropdowns.removeAt(index);
    }
    this.convertAgeListDefault();
    this.dataSearch = {
      ...this.dataSearch,
      children: this.receivedData.numberOfChildren,
    };
    this.searchBarDataService.setSearchData({
      ...this.dataSearch,
      children: this.receivedData.numberOfChildren,
    });
  }

  //#region-change-color-icon-substract
  isSubtractionAllowedRoom() {
    if (this.receivedData.numberOfRooms > 1) {
      return true;
    } else if (this.receivedData.numberOfRooms < 2) {
      return false;
    }
    return false;
  }

  isSubtractionAllowedAdult() {
    if (
      this.receivedData.numberOfAdults > 1 &&
      this.receivedData.numberOfAdults > this.receivedData.numberOfRooms
    ) {
      return true;
    }
    if (this.receivedData.numberOfAdults <= this.receivedData.numberOfRooms) {
      return false;
    }
    if (this.receivedData.numberOfRooms < this.receivedData.numberOfAdults) {
      return true;
    } else return false;
  }

  isSubtractionAllowedChild() {
    if (this.receivedData.numberOfChildren > 0) {
      return true;
    }
    if (this.receivedData.numberOfChildren < 2) {
      return false;
    } else return false;
  }

  isPlusNotAllowedRoom() {
    if (this.receivedData.numberOfRooms < 8) {
      return true;
    } else {
      return false;
    }
  }

  isPlusNotAllowedAdult() {
    if (this.receivedData.numberOfAdults < 36) {
      return true;
    } else {
      return false;
    }
  }

  isPlusNotAllowedChild() {
    if (this.receivedData.numberOfChildren < 8) {
      return true;
    } else {
      return false;
    }
  }

  //#endregion
  formattedDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }).format();
  // Handle select datetime

  onDateRangeChange(event: any) {
    if (
      event 
      && moment(event[0]).isValid()
      && moment(event[1]).isValid()
      && !(moment(event[0]).isSame(moment(event[1]), 'day'))
    ) {
      this.valueSelectDate = event;
      this.startDate = event[0];
      this.selectDateValue.emit(event);
      this.valueCheckIn = this.pipe.transform(event[0], this.isLanguageUS ? this.formatDateUS : this.formatDateVN);
      this.valueCheckOut = this.pipe.transform(event[1], this.isLanguageUS ? this.formatDateUS : this.formatDateVN);
      this.tempDateCheckIn = this.pipe.transform(event[0], this.isLanguageUS ? this.formatDateUS : this.formatDateVN);
      this.tempDateCheckOut = this.pipe.transform(event[1], this.isLanguageUS ? this.formatDateUS : this.formatDateVN);
      this.maxDate = null;
      this.dataSearch = {
        ...this.dataSearch,
        check_in: this.pipe.transform(event[0], 'MMM d, y'),
        check_out: this.pipe.transform(event[1], 'MMM d, y'),
      };
      this.searchBarDataService.setSearchData({
        ...this.dataSearch,
        check_in: this.pipe.transform(event[0], 'MMM d, y'),
        check_out: this.pipe.transform(event[1], 'MMM d, y'),
      });
      if (this.isFocusCheckAvailableRoom) {
        if (this.receivedData.titleSearchButton == 'DETAIL.CHANGE_SEARCH') {
          this.receivedData = {
            ...this.receivedData,
            isDisableSearchBtn: false,
            titleSearchButton: 'DETAIL.APPLY_SEARCH',
          };
        } else {
          this.receivedData = {
            ...this.receivedData,
            isDisableSearchBtn: false,
          };
        }
      }
    } else {
      this.valueCheckIn = this.pipe.transform(event[0], this.isLanguageUS ? this.formatDateUS : this.formatDateVN);
      let checkout = moment(event[0]).add(1, 'day')
      this.valueCheckOut = this.pipe.transform(checkout.toDate(), this.isLanguageUS ? this.formatDateUS : this.formatDateVN);
      this.selectedDates = [event[0], checkout.toDate()];
    }
    this.cdRef.detectChanges();
  }

  showCalendarDayCheckIn(event: Event) {
    this.isFocusCheckAvailableRoom = true;
    this.daterangepicker.hide();
    this.daterangepicker.minDate = new Date(Date.now());
    this.daterangepicker.show();
    this.valueCheckIn = null;
    const element = document.querySelector('.bs-datepicker') as HTMLElement;
    element.style.display = 'block';
    const elements = document.querySelector(
      'bs-daterangepicker-container'
    ) as HTMLElement;
    elements.style.zIndex = this.receivedData.zIndexCalendar;
  }

  showCalendarDayCheckOut(event: Event) {
    this.isFocusCheckAvailableRoom = true;
    this.daterangepicker.show();
    const element = document.querySelector('.bs-datepicker') as HTMLElement;
    element.style.display = 'block';
    const elements = document.querySelector(
      'bs-daterangepicker-container'
    ) as HTMLElement;
    elements.style.zIndex = this.receivedData.zIndexCalendar;
  }

  onDatepickerHide() {
    this.valueCheckIn = this.tempDateCheckIn;
    this.valueCheckOut = this.tempDateCheckOut;
    this.maxDate = null;
    this.currentDate = new Date(Date.now());
  }

  onDatepickerShown(): void {
    this.isFocusCheckAvailableRoom = true;
    this.selectDateCheckIn.nativeElement.focus();
    const datePickerInstance: BsDaterangepickerContainerComponent =
      this.daterangepicker['_datepicker'].instance;
    datePickerInstance.valueChange
      .pipe(filter((value) => value && value[0] && !value[1]))
      .subscribe((value: Date[]) => {
        if (value) {
          const [startDate] = value;
          this.valueCheckIn = this.pipe.transform(startDate, this.isLanguageUS ? this.formatDateUS : this.formatDateVN);
          this.currentDate = startDate;
          this.maxDate = new Date(startDate);
          this.maxDate.setDate(this.maxDate.getDate() + 90);
          this.selectDateCheckout.nativeElement.focus();
          this.valueCheckOut = null;
        }
      });
  }

  onHotelSelected(address: RecommendPlace) {
    this.receivedData.dest_type = address.property_id
      ? PlaceType.PROPERTY.toUpperCase()
      : PlaceType.PLACES.toUpperCase();
    this.receivedData.property_id = address.property_id;
    this.receivedData.country_code = address.country_code;
    this.receivedData.destination_name = address.object_name;
    this.dataSearch = {
      ...this.dataSearch,
      destination:
        address?.object_type?.toLowerCase() === PlaceType.PROPERTY.toLowerCase()
          ? address.property_id
          : address.id,
      country_code: address.country_code,
      destination_name: address.object_name,
    };
    this.searchBarDataService.setSearchData({
      ...this.dataSearch,
      destination:
        address?.object_type?.toLowerCase() === PlaceType.PROPERTY.toLowerCase()
          ? address.property_id
          : address.id,
      country_code: address.country_code,
      destination_name: address.object_name,
    });
    this.isButtonClearIcon = this.receivedData.destination_name ? true : false;
    this.hideOverlayAddress();
  }

  convertAgeListDefault() {
    this.ageDropdownList = this.myFormDropdownAge?.value?.dropdowns?.map(
      (item: any) => {
        if (item.length <= 0) {
          return (item = '8');
        }
        return item;
      }
    );
    this.dataSearch = {
      ...this.dataSearch,
      child_ages_list: this.ageDropdownList,
    };
    this.searchBarDataService.setSearchData({
      ...this.dataSearch,
      child_ages_list: this.ageDropdownList,
    });
  }

  onClearTextSearch() {
    if(this.receivedData.destination_name) {
      this.receivedData.destination_name = null;
      if(this.inputElement) this.inputElement.nativeElement.focus();
      this.isButtonClearIcon = false;
    }
  }

  convertDateDefault(date: any) {
    const originalDate = new Date(date);

    const year = originalDate.getFullYear();
    const month = String(originalDate.getMonth() + 1).padStart(2, '0');
    const day = String(originalDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  renderArray(number: number): any {
    return [...Array(number)];
  }

  // Handle search
  onClickSearchHotel() {
    if (this.receivedData.titleSearchButton === 'DETAIL.APPLY_SEARCH') {
      this.convertAgeListDefault();
      const value = {
        adults: this.receivedData.numberOfAdults,
        children: this.receivedData.numberOfChildren,
        child_ages_list: this.ageDropdownList,
        rooms: this.receivedData.numberOfRooms,
        check_in: this.valueSelectDate[0],
        check_out: this.valueSelectDate[1],
      };

      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          checkin: this.convertDateDefault(value.check_in),
          checkout: this.convertDateDefault(value.check_out),
          num_of_rooms: value.rooms,
          num_of_children: value.children,
          num_of_adults: value.adults,
          child_ages_list: JSON.stringify(this.ageDropdownList),
        },
        queryParamsHandling: 'merge',
      });

      const queryParams = {
        page_num: 1,
        page_size: 10,
        checkin: this.convertDateDefault(value.check_in),
        checkout: this.convertDateDefault(value.check_out),
        num_of_rooms: value.rooms,
        num_of_children: value.children,
        num_of_adults: value.adults,
        currency: this.receivedData.currency,
        ...(value.child_ages_list?.length !== 0
          ? { child_ages_list: value.child_ages_list }
          : ''),
      };
      this.isFocusCheckAvailableRoom = false;
      this.receivedData = {
        ...this.receivedData,
        isDisableSearchBtn: true,
        titleSearchButton: 'DETAIL.CHANGE_SEARCH',
      };
      // this.searchBarRoomService.setQueryParamData(queryParams);
      this.totalPaymentService.setdataPayment({
        totalPrice: 0,
        totalRoom: 0,
        hotelResult: [],
      });
    } else {
      if (!this.receivedData.destination_name) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: this.soctripTranslationService.getTranslation(
            'MESSAGE.ENTER_PLACE_OR_HOTEL'
          ),
        });
      } else {
        this.convertAgeListDefault();
        scrollToTop('smooth');
        const checkInDate = this.pipe.transform(
          this?.valueSelectDate[0],
          'yyyy-MM-dd'
        );
        const checkOutDate = this.pipe.transform(
          this.valueSelectDate[1],
          'yyyy-MM-dd'
        );
        if(this.isSearchByLocation) {
          this.receivedData.destination_name = this.translateService.instant(
            'SEARCH.YOUR_LOCATION'
          );
          this.dataSearch.destination_name = 'Your location';
          this.dataSearch.destination = 'Your location';
        }
        let value: any = {
          destination_name: this.dataSearch.destination_name,
          destination: this.receivedData.property_id
            ? this.receivedData.property_id
            : this.dataSearch.destination || this.receivedData.selectedHotel,
          dest_type: this.receivedData.property_id
            ? PlaceType.PROPERTY.toLocaleUpperCase()
            : PlaceType.PLACES.toLocaleUpperCase(),
          country_code: this.receivedData.country_code,
          checkin: checkInDate!,
          checkout: checkOutDate!,
          num_of_rooms: this.receivedData.numberOfRooms,
          num_of_children: this.receivedData.numberOfChildren,
          num_of_adults: this.receivedData.numberOfAdults,
          currency: this.receivedData.currency,
          child_ages_list: this.ageDropdownList,
          property_id: this.receivedData.property_id,
          page_num: 1,
        };
        if (
          this.isSearchByLocation ||
          this.receivedData.selectedHotel ===
            this.translateService.instant('SEARCH.YOUR_LOCATION')
        ) {
          value = Object.assign(
            {
              latitude: this.receivedData?.latitude,
              longitude: this.receivedData?.longitude,
            },
            value
          );
        }
        this.searchResultService.setQueryParamData(value);
        this.ageDropdownData.emit(this.ageDropdownList);
        this.searchBarUpdateData.emit(value);
      }
    }
  }
}
