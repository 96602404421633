<ng-container *ngIf="advertisementImage">
    <iframe
      *ngIf="bannerSvgExtention"
      [src]="advertisementImage | safeUrl"
      class="h-full w-full object-cover mx-auto rounded-lg {{ iframeClass }} cursor-pointer"
    ></iframe>
    <img
      *ngIf="!bannerSvgExtention"
      src="{{ advertisementImage }}"
      class="rounded-lg object-cover {{ imgClass }} aspect-[21/10] cursor-pointer"
      (click)="openInNewTab(advertisementImageUrl)"
      (error)="replaceWithDefaultImage($event)" 
    />
</ng-container>