import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { countryCode } from '@modules/users/mock/country-code';
import {
  AbstractControl,
  FormGroup,
  Validators,
  FormBuilder,
  ControlContainer,
  FormGroupDirective,
  FormArray,
} from '@angular/forms';
import { GeneralInfoDTO } from '@booking/angular-booking-user-data-service';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ContactComponent implements OnInit, OnChanges {
  @Input('generalInfo') generalInfo: GeneralInfoDTO;

  countries: any[];
  emailRegex: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  phoneRegex: RegExp = /^\d{5,15}$/;
  contactForm: FormGroup;
  subContactData: any;

  constructor(
    private formBuilder: FormBuilder,
    private readonly formGroupDirective: FormGroupDirective
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isDiscardChange'] || changes['generalInfo'] && this.generalInfo) {
      const subContacts = this.generalInfo?.contacts?.slice(1);
      this.contactForm?.patchValue({
        full_name: this.generalInfo?.contacts?.[0].name,
        email: this.generalInfo?.contacts?.[0].email,
        phone_number:
          this.generalInfo?.contacts?.[0].personal_phone.phone_number,
        phone_number_code: this.countries.find(
          (c) =>
            c.code ===
            this.generalInfo?.contacts?.[0].personal_phone.phone_area_code
        ),
        office_phone_number:
          this.generalInfo?.contacts?.[0].office_phone?.phone_number ?? "",
        office_phone_number_code: this.countries.find(
          (c) =>
            c.code ===
            this.generalInfo?.contacts?.[0].office_phone?.phone_area_code
        ) ?? this.countries[0],
        position: this.generalInfo?.contacts?.[0].role,
        contactable_24h: this.generalInfo?.contacts?.[0].contactable_24h,
      });

      subContacts?.map((contact) => {
        const subContactsForm = this.formBuilder.group({
          full_name: contact.name,
          email: contact.email,
          phone_number: contact.personal_phone.phone_number,
          phone_number_code: this.countries.find(
            (c) => c.code === contact.personal_phone.phone_area_code
          ),
          office_phone_number: contact.office_phone?.phone_number,
          office_phone_number_code:
          this.countries.find(
            (c) => c.code === contact.office_phone?.phone_area_code
          ) || this.countries[0], 
          position: contact.role,
          contactable_24h: contact.contactable_24h,
        });
        this.subContacts.push(subContactsForm);
      });
      this.subContactData = subContacts;
      // this.subContacts?.disable()
    }
  }

  ngOnInit() {
    this.countries = countryCode;
    this.contactForm = this.formBuilder.group({
      full_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(this.emailRegex)]],
      phone_number: [
        '',
        [Validators.required, Validators.pattern(this.phoneRegex)],
      ],
      phone_number_code: [this.countries[0], Validators.required],
      office_phone_number: [
        '',
        [Validators.pattern(this.phoneRegex)],
      ],
      office_phone_number_code: [this.countries[0]],
      position: [''],
      contactable_24h: [false],
      subContacts: this.formBuilder.array([]),
    });
    this.formGroupDirective?.form?.addControl('contact', this.contactForm);
  }

  createSubcontactForm(): FormGroup {
    return this.formBuilder.group({
      full_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(this.emailRegex)]],
      phone_number: [
        '',
        [Validators.required, Validators.pattern(this.phoneRegex)],
      ],
      phone_number_code: [this.countries[0], Validators.required],
      office_phone_number: [
        '',
        [Validators.pattern(this.phoneRegex)],
      ],
      office_phone_number_code: [this.countries[0]],
      position: [''],
      contactable_24h: [false],
    });
  }

  get subContacts() {
    return this.contactForm?.get('subContacts') as FormArray;
  }

  get f(): { [key: string]: AbstractControl } {
    return this.contactForm?.controls;
  }

  getIcon(country: any): string {
    return country.icon;
  }

  addContact() {
    this.subContacts.push(this.createSubcontactForm());
  }

  close(index: number) {
    this.subContacts.removeAt(index);
  }
}
