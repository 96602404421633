<div
    class="fixed top-0 right-0 bottom-0 left-0 z-[99999] bg-palette-base-black bg-opacity-40 h-screen !overflow-hidden !overflow-y-hidden !overflow-x-hidden">
    <div class="popup-container flex flex-col items-start rounded-xl w-[486px] h-[338px] relative"
        (click)="stopPropagationEvent($event)">
        <img src="assets/icons/popup_recommend_signin/Stars1.svg" alt="Stars1"
            class="w-[93px] h-[59px] left-6 top-2 absolute" />
        <img src="assets/icons/popup_recommend_signin/Stars2.svg" alt="Stars2"
            class="w-[43px] h-[58px] right-[91px] top-[7px] absolute" />
        <img src="assets/icons/popup_recommend_signin/Circle.svg" alt="Circle"
            class="circle w-[48px] h-[49px] right-[11px] top-[106px] absolute" />
        <div class="flex flex-col h-[44px] items-center self-stretch">
            <button class="flex !p-2 justify-center items-center absolute !top-[6px] right-[6px] rounded-lg !text-3xl hover:bg-palette-blue-200"
                (click)="closePopup()">
                <img src="assets/icons/popup_recommend_signin/Close.svg" alt="Close"
                    class="!w-6 h-6 text-palette-blue-900" />
            </button>
        </div>
        <div class="!p-6 !pb-8 !pt-0 !gap-4 flex flex-col items-center self-stretch rounded-[0px] w-full !h-[294px]">
            <p class="text-palette-blue-900 !text-center font-inter !font-semibold !text-2xl sm:!text-3xl !leading-[38px] px-2 sm:px-0">
                {{'POPUP_BECOME_OUR_AGENCY.TITLE' | translate}}</p>
            <div
                class="flex !p-6 flex-col items-center !gap-4 self-stretch rounded-lg bg-palette-base-white w-[438px] h-[184px] z-[10]">
                <p class="self-stretch text-center text-palette-gray-700 font-inter font-medium text-sm sm:text-base">
                    {{'POPUP_BECOME_OUR_AGENCY.CONTENT' | translate}}</p>
                <button
                    class="flex !h-12 !py-3 !px-5 items-center justify-center gap-2 self-stretch rounded-lg border border-palette-blue-600 bg-palette-blue-600"
                    (click)="onSignup()">
                    <span class="text-palette-base-white font-inter text-base font-semibold">
                        {{'POPUP_BECOME_OUR_AGENCY.BUTTON' | translate}}
                    </span>
                </button>
            </div>
        </div>
    </div>
</div>