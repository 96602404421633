import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Provider } from '@angular/core';
import { Router } from '@angular/router';
import { TokenStorageService } from '@modules/users/services/token-storage.service';
import { Observable, of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private tokenStorageService: TokenStorageService,
    private router: Router
  ) {}
  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const token = this.tokenStorageService.getToken();
    if (!token) {
      return next.handle(req);
    }
    const headers = req.headers.set('Authorization', `Bearer ${token}`);
    return next
      .handle(
        req.clone({
          headers,
        })
      )
      .pipe(
        tap(
          () => {},
          (err) => {
            if (err instanceof HttpErrorResponse) {
              // if (err.status === 403) {
              //   // this.router.navigate(['booking/forbidden']);
              // }
            }
            console.log(err);

            return err;
          }
        )
      );
    // return of('BEARER_TOKEN').pipe(
    //   take(1),
    //   switchMap((token) => {
    //     if (!token) {
    //       return next.handle(req);
    //     }

    //     const headers = req.headers.set('Authorization', `Bearer ${token}`);
    //     return next.handle(req.clone({
    //       headers
    //     }));
    //   })
    // );
  }
}

export const AUTH_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true,
} as const;
