<div *ngIf="amenitiesList.property_amenity_category_name" class="amenities-section w-full h-auto border rounded-lg !px-6 bg-white">
    <div class="h-[68px] flex items-center justify-between w-full font-inter">
        <p class="text-base font-semibold text-palette-gray-900">{{amenitiesList.property_amenity_category_name}}</p>
        <div class="flex !gap-2 items-center">
            <p-checkbox *ngIf="isShowAmenitiesSection && typeAccess !== 'view-only'" [(ngModel)]="selectAll" [binary]="true" (click)="handleSelectAll()"></p-checkbox>
            <p *ngIf="isShowAmenitiesSection && typeAccess !== 'view-only'" class="text-sm font-medium text-palette-gray-700">{{'OTA.PROPERTY_AMENITIES.SELECT_ALL' | translate}}</p>
            <div  *ngIf="!isShowAmenitiesSection && numberSelectedAmenities !== 0 && numberSelectedAmenities" class="text-xs font-medium text-palette-gray-700">
                {{numberSelectedAmenities}} {{numberSelectedAmenities === 1 ? 'amenity' : 'amenities' }} selected
            </div>
            <div (click)="handleCollapse()" class="w-[28px] h-[28px] flex items-center justify-center cursor-pointer hover:bg-palette-gray-100 !ml-2 rounded-lg">
                <i [@rotateAnimation]="isShowAmenitiesSection ? 'open' : 'closed'" class="sctr-icon-chevron-up text-[16px] text-palette-gray-700"></i>
            </div>
        </div>
    </div>
    <div *ngIf="!isTopAmenities">
        <div *ngIf="isShowAmenitiesSection" [@slideDownUp] class="{{typeAccess !== 'view-only' ? '' : ''}}">
            <div class="grid grid-cols-3 justify-between !gap-y-4 !m-0 !gap-x-2 font-inter !pb-4 max-[1024px]:grid-cols-2">
                <div *ngFor="let amenity of amenitiesList.property_amenities">
                    <div class="flex items-center !gap-2">
                        <div class="flex place-items-start !gap-2 {{typeAccess === 'view-only' && page === 'hotel-management'&& page === 'hotel-management' ? 'pointer-events-none' : ''}}">
                            <p-checkbox [(ngModel)]="selectedAmenities[amenity.property_amenity_category_id]" value={{amenity.property_amenity_id}} (click)="handleClickCheckbox(amenity)"></p-checkbox>
                            <p class="text-sm font-medium text-palette-gray-700">{{amenity.property_amenity_name}}</p>
                        </div>
                        <i *ngIf="amenity.is_top"
                            class="sctr-icon-solid-star-07 text-[12px] text-palette-orange-dark-400"></i>
                    </div>
                    <div *ngIf="amenity.is_allow_charge && checkMoreOption(selectedAmenities, amenity)">
                        <div class="card pl-4 pt-2">
                            <div class="flex flex-wrap gap-3 {{typeAccess === 'view-only' && page === 'hotel-management' ? 'pointer-events-none' : ''}}">
                                <div *ngFor="let category of categories;">
                                    <p-radioButton name="category" [value]="category.value" (click)="handleClickRadioButton(amenity)"
                                        [(ngModel)]="selectedCategory[amenity.property_amenity_id]"></p-radioButton>
                                    <label [for]="category.value" class="ml-2 text-sm font-medium text-palette-gray-700">{{ category.name }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isTopAmenities">
        <div *ngIf="isShowAmenitiesSection" class="{{typeAccess !== 'view-only' ? '' : ''}}">
            <div class="grid grid-cols-3 justify-between !gap-y-4 !m-0 !gap-x-2 font-inter !pb-4 max-[1024px]:grid-cols-2">
                <div *ngFor="let amenity of amenitiesList.property_amenities">
                    <div class="flex items-center !gap-2">
                        <div
                            class="flex place-items-start !gap-2 {{typeAccess === 'view-only' && page === 'hotel-management'&& page === 'hotel-management' ? 'pointer-events-none' : ''}}">
                            <p-checkbox [(ngModel)]="selectedAmenities[amenity.property_amenity_category_id]"
                                value={{amenity.property_amenity_id}} (click)="handleClickCheckbox(amenity)"></p-checkbox>
                            <p class="text-sm font-medium text-palette-gray-700">{{amenity.property_amenity_name}}</p>
                        </div>
                        <i *ngIf="amenity.is_top"
                            class="sctr-icon-solid-star-07 text-[12px] text-palette-orange-dark-400"></i>
                    </div>
                    <div *ngIf="amenity.is_allow_charge && checkMoreOption(selectedAmenities, amenity)">
                        <div class="card pl-4 pt-2">
                            <div
                                class="flex flex-wrap gap-3 {{typeAccess === 'view-only' && page === 'hotel-management' ? 'pointer-events-none' : ''}}">
                                <div *ngFor="let category of categories;">
                                    <p-radioButton name="category" [value]="category.value"
                                        (click)="handleClickRadioButton(amenity)"
                                        [(ngModel)]="selectedCategory[amenity.property_amenity_id]"></p-radioButton>
                                    <label [for]="category.value" class="ml-2 text-sm font-medium text-palette-gray-700">{{
                                        category.name }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>