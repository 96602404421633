import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchBarService {
  private _queryParams: ReplaySubject<any> = new ReplaySubject();
  private queryParams$: Observable<any> = this._queryParams.asObservable();
  private historySearch: any[];
  constructor() { }

  setItemStorage(key: string, value: any): void {
    this.historySearch = JSON.parse(localStorage.getItem(key) || '[]');
    if (!Array.isArray(this.historySearch)) {
      localStorage.removeItem(key);
      this.historySearch = [];
    }
    if (this.historySearch.length < 6) {
      this.historySearch.push(value);
    } else {
      this.historySearch.shift();
      this.historySearch.push(value);
    }
    localStorage.setItem(key, JSON.stringify(this.historySearch));
  }
  getItemStorage(key: string): any {
    const storedData = localStorage.getItem(key);
    return storedData ? JSON.parse(storedData) : null;
  }
  setQueryParamData(data: any) {
    this._queryParams.next(data);
  }
  getQueryParamData() {
    return this.queryParams$;
  }
  
}
