import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserService } from 'src/app/core/services/user/user.service';
import { CopyService } from '@modules/hotel-booking/services/copy/copy.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { DetailHotel } from '@modules/hotel-booking/models/view-details';
import { ToastType } from '@shared/enum/toast-type';
import { SoctripTranslationService } from 'src/app/services/soctrip-translation/soctrip-translation.service';
import { createSlug } from '@shared/utils/helper';
import { environment } from '@env/environment';

@Component({
  selector: 'app-share-hotel',
  templateUrl: './share-hotel.component.html',
  styleUrls: ['./share-hotel.component.scss'],
})
export class ShareHotelComponent {
  @Input() dataDetailHotel: DetailHotel;

  overlayVisible = false;
  overlayVisibleShareToFeed = false;
  visiblePleaseSignIn = false;
  isLoggedIn: boolean | null = false;

  constructor(
    public dialogService: DialogService,
    private messageService: MessageService,
    private router: Router,
    private copyService: CopyService,
    private soctripTranslationService: SoctripTranslationService,
    private userService: UserService
  ) {}
  ngOnInit() {
    this.userService.getLoginStatus().subscribe((data) => {
      if (data !== null) {
        this.isLoggedIn = data;
      }
    });
  }

  onClickIconShare() {
    this.overlayVisible = !this.overlayVisible;
  }
  onCopyLink() {
    let cityNameSlug = createSlug(this.dataDetailHotel.city_name);
    if (cityNameSlug == '' || !cityNameSlug) {
      cityNameSlug = 'property';
    }
    let hotelNameSlug = createSlug(this.dataDetailHotel.property_name);
    if (hotelNameSlug == '' || !hotelNameSlug) {
      hotelNameSlug = 'hotel-name';
    }

    const currentURL =
      environment.BOOKING_URL +
      '/booking/hotel/search/' +
      cityNameSlug +
      '/' +
      hotelNameSlug +
      '?destination_name=' +
      this.dataDetailHotel.city_name +
      '&property_id=' +
      this.dataDetailHotel.property_id;

    const successful = this.copyService.copyToClipboard(currentURL);
    if (successful) {
      this.messageService.add({
        key: 'cp',
        severity: ToastType.INFO,
        detail: this.soctripTranslationService.getTranslation(
          'MESSAGE.LINK_COPIED'
        ),
      });
    }
  }
  onClickShareToFeed() {
    if (this.isLoggedIn) {
      this.overlayVisibleShareToFeed = !this.overlayVisibleShareToFeed;
    } else {
      this.visiblePleaseSignIn = !this.visiblePleaseSignIn;
    }
  }

  visibleShareToFeedModuleChange(event: any) {
    this.overlayVisibleShareToFeed = !this.overlayVisibleShareToFeed;
    if (event.postStatus) {
      if (event.postStatus) {
        this.messageService.add({
          severity: ToastType.SUCCESS,
          detail: this.soctripTranslationService.getTranslation(
            'POST.POST_SUCCESSFUL'
          ),
        });
      } else {
        this.messageService.add({
          severity: ToastType.ERROR,
          detail:
            this.soctripTranslationService.getTranslation('POST.POST_FAILED'),
        });
      }
    }
  }

  visiblePleaseSignInChange() {
    this.visiblePleaseSignIn = false;
  }
}
