<div class="relative">
    <div (click)="onClickIconShare()"
    class="cursor-pointer w-fit space-x-2 max-w-[115px] h-9 min-h-[44px] min-w-[96px] px-3 flex justify-center items-center text-palette-gray-700 rounded-lg border border-palette-gray-300">
    <i class="sctr-icon-share-06 text-xl "></i>
    <p class="text-base text-palette-gray-600 font-medium whitespace-nowrap">{{ 'DETAIL.SHARE' | translate}}</p>
    
</div>
    <p-overlay [(visible)]="overlayVisible" [style]="{'margin-left': '-105px', }">
        <ng-template pTemplate="content">
            <div
                class="min-w-[215px] max-w-[215px] lg:min-h-fit font-inter bg-white mt-2 p-1 rounded-lg border border-palette-gray-300">
                <common-icon-text class="flex items-center space-x-2 rounded-lg h-10 text-palette-gray-700 p-2
                        cursor-pointer hover:bg-black hover:bg-opacity-[0.1]"
                        (click)="onClickShareToFeed()"
                    [icon]="'sctr-icon-home-line'"
                    [iconStyle]="'text-xl'"
                    [text]="'DETAIL.SHARE_TO_FEED'"
                    [textStyle]="'whitespace-nowrap'"></common-icon-text>
                <common-icon-text class="flex items-center space-x-2 rounded-lg h-10 text-palette-gray-700 p-2
                        cursor-pointer hover:bg-black hover:bg-opacity-[0.1]"
                        (click)="onCopyLink()"
                    [icon]="'sctr-icon-copy-06'"
                    [iconStyle]="'text-xl'"
                    [text]="'DETAIL.COPY_LINK'"
                    [textStyle]="'whitespace-nowrap'"></common-icon-text>
            </div>
        </ng-template>
    </p-overlay>
</div>



<app-toast keyToast="cp"></app-toast>
<div *ngIf="overlayVisibleShareToFeed">
    <soctrip-post-dialog [visible]="overlayVisibleShareToFeed" (visibleChange)="visibleShareToFeedModuleChange($event)"
        [dataDetailHotel]="dataDetailHotel"></soctrip-post-dialog>
</div>

<app-sign-in-request-dialog [visiblePleaseSignIn]="visiblePleaseSignIn"
    (visiblePleaseSignInChange)="visiblePleaseSignInChange()"></app-sign-in-request-dialog>