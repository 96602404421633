<div class="lg:mx-[12.5%] md:mx-[8%] mx-[2%] min-[1560px]:flex min-[1560px]:justify-center">
    <div class="max-w-[1200px] w-full py-3">
        <div *ngIf="item" class="bg-white rounded-lg border disable-react">
            <div class="!px-4 !pt-3 !pb-2 font-inter">
                <div class="info flex flex-row justify-between items-center">
                    <div class="flex flex-row items-center">
                        <span *ngIf="item.created_by.avatar_thumbnail_url then user; else guest"></span>
                        <ng-template #user>
                            <img class="!h-10 !w-10 rounded-full"
                                src="{{baseUrl}}/storage/files/web/{{item.created_by.avatar_thumbnail_url}}.webp"
                                (error)="replaceAvatarWithDefaultImage($event)">
                        </ng-template>
                        <ng-template #guest>
                            <div class="flex items-center flex-col">
                                <i class="sctr-icon-solid-user-02 !w-10 !h-10 !leading-10 text-2xl text-center rounded-full bg-palette-gray-200 text-palette-gray-700"></i>
                            </div>
                        </ng-template>
                        <div class="!ml-3">
                            <p class="text-xs sm:text-base font-medium text-palette-gray-700">{{ item.created_by ? item.created_by.full_name : 'DETAIL.GUEST' | translate }}</p>
                            <p class="text-xs sm:text-sm font-normal text-palette-gray-500">{{ item.created_at | date: 'dd/MM/yyyy'}}</p>
                        </div>
                    </div>
                    <div class="flex flex-row items-center !gap-2">
                            <p *ngIf="item.rating >= 9" class="!ml-2 text-base font-medium text-palette-gray-700 first-letter:uppercase">{{ 'DETAIL.SUPERB' | translate }}</p>
                            <p *ngIf="item.rating >= 8 && item.rating < 9" class="!ml-2 text-base font-medium text-palette-gray-700 first-letter:uppercase">{{ 'DETAIL.EXCELLENT' | translate}}</p>
                            <p *ngIf="item.rating >= 7 && item.rating < 8" class="!ml-2 text-base font-medium text-palette-gray-700 first-letter:uppercase">{{ 'DETAIL.VERY_GOOD' | translate }}</p>
                            <p *ngIf="item.rating >= 6 && item.rating < 7"class="!ml-2 text-base font-medium text-palette-gray-700 first-letter:uppercase">{{ 'DETAIL.PLEASANT' | translate}}</p>
                            <p *ngIf="item.rating < 6"class="!ml-2 text-base font-medium text-palette-gray-700 first-letter:uppercase">{{ 'DETAIL.UNCOMFORTABLE' | translate }}</p>
                        <div class="box-border py-[2px] !px-2 rounded border border-palette-blue-600 w-fit h-[22px] text-xs leading-[18px] font-medium text-palette-blue-600">{{ item.rating }}</div>
                    </div>
                </div>
                <div class="flex flex-col 2xl:flex-row 2xl:items-center !my-2 text-palette-gray-700 flex-wrap mb-[2px]">
                    <div class="flex flex-row items-center gap-2 text-xs sm:text-sm font-medium border-r-palette-gray-200 border-r pr-2">
                        <p class="">{{ 'DETAIL.ROOM' | translate }}: {{ item.factors[0].type }}</p>
                        <div class="relative" *ngIf="item.factors.length > 1">
                            <p [tooltip]="listTypeRoomToolTip" [theme]="TooltipTheme.LIGHT"
                                [position]="TooltipPosition.ABOVE"
                                class="cursor-pointer line-clamp-1 !text-palette-gray-700 bg-palette-gray-200 !font-inter !text-xs !leading-[18px] !font-medium flex justify-center items-center rounded-2xl">
                                <span class="!px-2 !py-[2px]">+{{item.factors.length - 1}}</span>
                            </p>
                        </div>
                    </div>
                    <div #hotelPoints class="flex flex-row flex-wrap sm:items-center text-xs font-medium !leading-[18px] ml-2">
                        <p *ngFor="let note of item.notes" class="!mt-2 !mr-1 2xl:!mt-0 !py-[2px] !px-2 rounded-2xl bg-palette-gray-200">{{ note.content | translate }} ({{ note.score }})</p>
                    </div>
                </div>
                
                <p #reviewContent class="text-sm sm:text-sm text-palette-gray-700 leading-6 font-normal {{ isExpanded ? 'line-clamp-none':'line-clamp-3'}}">{{ item.content }}</p>
                
                <button *ngIf="isLongText" (click)="toggleContent()" class="text-palette-blue-700 text-sm font-semibold !mt-1">{{ isExpanded ? ('COMMON.SHOW_LESS' | translate ) : ('COMMON.VIEW_MORE' | translate) }}</button> 
                
                <div *ngIf="item.medias && item.medias.length > 0 && item.medias.length <= 5" class="list-images flex flex-row flex-wrap">
                    <div *ngFor="let image of item.medias;let i = index" class="!mr-2 !mt-2 cursor-pointer" (click)="onClickImage(item.medias, i)">
                        <img class="!w-20 h-20 max-w-[80px] max-h-20 rounded-lg" src="{{baseUrl}}/storage/files/web/{{ image.id }}" (error)="replaceWithDefaultImage($event)" alt="">
                    </div>          
                </div>
            
                <div *ngIf="item.medias && item.medias.length > 5" class="list-images flex flex-row flex-wrap">
                    <div *ngFor="let image of item.medias; let i = index" (click)="onClickImage(item.medias, i)"
                     class="relative cursor-pointer !mr-2 !mt-2">
                        <ng-container *ngIf="i < 5">
                            <img class="!w-20 h-20 max-w-[80px] max-h-20 rounded-lg" src="{{baseUrl}}/storage/files/web/{{ image.id }}" (error)="replaceWithDefaultImage($event)" alt="">
                            <div *ngIf="i==4" class="bg-[#10182866] bg-opacity-40 !w-20 h-20 max-w-[80px] max-h-20 rounded-lg absolute top-0 left-0 z-1 text-white text-sm flex flex-row items-center justify-center">View more</div>
                        </ng-container>            
                    </div>  
                </div>
            </div>
        </div>
    </div>
</div>