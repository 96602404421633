import config from './config.json';

interface IPropertyData {
  countryCode: string;
  pageNum: string;
  pageSize: string;
  featureProperty: string;
}

const url: string = config.apiUrl;
const bookingFamousPlaces: string = config.API.famousSpot.bookingFamousPlaces;
const propertyData: IPropertyData = config.API.propertyData;
const propertySearch = config.API.propertySearch;
const propertySearchRoom = config.API.propertySearchRoom;

export {
  url,
  bookingFamousPlaces,
  propertyData,
  propertySearch,
  propertySearchRoom,
};
