export const countryCode = [
  { id: 'VN', name: 'Viet Nam', code: '+84', icon: 'assets/icons/countries_flag/VN.svg' },
  { id: 'CN', name: 'China', code: '+86', icon: 'assets/icons/countries_flag/CN.svg' },
  { id: 'FR', name: 'France', code: '+33', icon: 'assets/icons/countries_flag/FR.svg' },
  { id: 'JP', name: 'Japan', code: '+81', icon: 'assets/icons/countries_flag/JP.svg' },
  { id: 'KR', name: 'Korea', code: '+82', icon: 'assets/icons/countries_flag/KR.svg' },
  { id: 'RU', name: 'Russia', code: '+7', icon: 'assets/icons/countries_flag/RU.svg' },
  { id: 'GB', name: 'United Kingdom', code: '+44', icon: 'assets/icons/countries_flag/GB.svg' },
  { id: 'US', name: 'United States', code: '+1', icon: 'assets/icons/countries_flag/US.svg' },
];
