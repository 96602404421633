import { Component, Input, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  Validators,
  FormBuilder,
  FormControl,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  IContract,
  IPropertyInfoEvent,
  PhoneNumber,
} from '@modules/ota/become-agency/models/property-info.model';
import { ContractAgreementEventsService } from '@modules/ota/become-agency/services/contract-agreement-events/contract-agreement-events.service';
import { PropertyInfoEventsService } from '@modules/ota/become-agency/services/property-info-events/property-info-events.service';
import { RoomInfoEventsService } from '@modules/ota/become-agency/services/room-info-events/room-info-events.service';
import { InputPhoneNumberComponent } from '@shared/components/input-phone-number/input-phone-number.component';
import {
  Observable,
  Subject,
  Subscription,
  catchError,
  of,
  takeUntil,
  timeout,
} from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { MediaService } from '@modules/my-booking/services/media/media.service';
import { MessageService } from 'primeng/api';
import { AgencyPropertyInfoControllerService } from '@booking/angular-booking-user-data-service';
import { TIME_OUT_REQUEST } from '@shared/constant';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { PropertyType } from '@shared/enum/property-step.enum';
import { ImagesService } from '@modules/my-booking/services/image/media.service';
import { PropertyManagementService } from '@modules/ota/hotel-ota/services/property-management/property-management.service';

interface Rule {
  name: string;
  key: string;
  icon: string;
}

interface Attachment {
  id: string;
  title: string;
  file: any;
  isUpload: boolean;
  isValid: boolean;
  type: string;
}
@Component({
  selector: 'app-contract-info',
  templateUrl: './contract-info.component.html',
  styleUrls: ['./contract-info.component.scss'],
  providers: [MessageService, PropertyManagementService]
})
export class ContractInfoComponent {
  @ViewChild('phoneNumberRef') phoneNumberChild: InputPhoneNumberComponent;

  @Input() data: any;
  @Input() isFormDisabled: boolean;

  // Regex
  public emailRegex: RegExp;
  public phoneRegex: RegExp;
  public contractAgreementForm: FormGroup;
  public rules: Rule[];
  public uploadAttachBusiness: Attachment[];
  public uploadAttachPersonal: Attachment[];
  public maximumAttachBusiness: number;
  public maximumAttachPersonal: number;
  public initAttachCommon: Attachment[];
  public initAttachPersonal: Attachment[];
  public contract: any;
  public initIDCard: Attachment[];
  public isWarningRule: boolean;
  public warningText: string;
  public contractAgreementEvents: IPropertyInfoEvent[];
  public contractAgreementEventSub: Subscription;
  propertyId: string = '';
  propertyInfoEvents: IPropertyInfoEvent[];
  propertyInfoEventSub: Subscription;
  roomInfoEvents: any[];
  roomInfoEventSub: Subscription;
  public media: any = [];
  isCallAPI: boolean;
  isLoading: boolean;
  phoneNumber: PhoneNumber;
  onDestroy$: Subject<void> = new Subject();
  nameFile: string;
  confirmSubject = new Subject<boolean>();
  confirm$ = this.confirmSubject.asObservable();
  isCheckBoxDirty = false;
  isCancel = false;
  querySubscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private agencyPropertyInfoControllerService: AgencyPropertyInfoControllerService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    private imagesService: ImagesService, 
    private messageService: MessageService,
    private propertyManagementService: PropertyManagementService,
  ) {}

  ngOnInit(): void {
    this.querySubscription = this.activatedRoute.params.subscribe((params) => {
      this.propertyId = params['property_id'] || '';
    });
    this.initRegex();
    this.initDisplayData();
    this.initFormBuilder();
    this.contract = this.contractAgreementForm.value;
    this.detectFormChanges();
    this.initFormData();
    this.contractAgreementForm.markAsPristine();
    this.propertyManagementService.getSignalResetDataContract().subscribe((value: any) => {
      if(value) {
        this.initDisplayData();
        this.renderValueContractForm(value);
        this.initSelectRule(this.contractAgreementForm.value.selectedRule);
        this.phoneNumber = value.phone;
        this.phoneNumberChild.initUiData();
        this.renderValueFiles(value);
      }
    })
    
  }

  ngAfterViewInit() {
    this.contractAgreementForm.markAsPristine();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      if(changes['data'].currentValue) {
        this.renderValueContractForm(this.data);
        this.phoneNumber = this.data.phone;
        this.renderValueFiles(this.data);
      }
    }
  }
  downloadImage(url: string, filename: string) {
    this.imagesService.downloadImage(url, filename);
  }

  getSubstringAfterFirstDotFromEnd(inputString: string): string {
    const index = inputString.lastIndexOf('.');
    if (index === -1) return inputString;
    return inputString.substring(index + 1);
  }

  previewImg(file: any) {
    if(this.isFormDisabled) {
      let baseURL = environment.BASE_PATH;
      let url = this.getSubstringAfterFirstDotFromEnd(file.original_name);
      let downloadFile = `${baseURL}/storage/files/download/${file.id + '.' + url}`
      this.imagesService.downloadImage(downloadFile, file.original_name);
    }
  }

  isFileTypeAllowed(fileType: string): boolean {
    const allowedMimeTypes = [
      'application/pdf',
      'image/jpeg',
      'image/png',
      'image/svg+xml',
      "image/webp"
    ];
    return allowedMimeTypes.includes(fileType);
  }

  showError(detail: string) {
    this.messageService.add({
      key: 'FILE_ERROR',
      severity: 'error',
      detail: detail
    });
  }

  uploadFile(e: any, type: string, index: number) {
    this.isCheckBoxDirty = true
    const file: File = e.target.files[0];
    if(!this.isFileTypeAllowed(file.type)){
      this.showError(this.translateService.instant('OTA.CONTRACT_AGREEMENT.FILE_TYPE_INVALID'));
      return;
    }
    if (type === 'personal') {
      this.uploadAttachPersonal[index].file = file;
      this.uploadAttachPersonal[index].isUpload = false;
    } else if (type === 'business') {
      this.uploadAttachBusiness[index].file = file;
      this.uploadAttachBusiness[index].isUpload = false;
    }
  }

  removeUploadCpn(index: number, type: string) {
    this.isCheckBoxDirty = true
    if (type === 'personal') {
      this.uploadAttachPersonal.splice(index, 1);
      this.maximumAttachPersonal += 1;
    } else if (type === 'business') {
      this.uploadAttachBusiness.splice(index, 1);
      this.maximumAttachBusiness += 1;
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.propertyInfoEventSub?.unsubscribe();
    this.roomInfoEventSub?.unsubscribe();
    this.contractAgreementEventSub?.unsubscribe();
    this.querySubscription?.unsubscribe();
  }

  initFormData(): void {
    this.callGetContractInfo();
  }

  checkboxAgreeValidator = (control: FormControl) => {
    const value = control.value;
    if (Array.isArray(value) && value.includes(true)) {
      return null;
    }
    return { shouldBeTrue: true };
  };

  initFormBuilder() {
    this.contractAgreementForm = this.formBuilder.group({
      fullName: ['', Validators.required],
      selectedRule: [this.rules[0], Validators.required],
      email: ['', [Validators.required, Validators.pattern(this.emailRegex)]],
      checkboxAgree: [null, this.checkboxAgreeValidator],
    });
  }

  initRegex() {
    this.emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    this.phoneRegex = /^\d{5,15}$/;
  }

  initDisplayData() {
    this.initAttachPersonal = [
      {
        id: uuidv4(),
        title: 'OTA.CONTRACT_AGREEMENT.B_ID_CARD',
        file: null,
        isUpload: false,
        isValid: false,
        type: 'person',
      },
      {
        id: uuidv4(),
        title: 'OTA.CONTRACT_AGREEMENT.F_ID_CARD',
        file: null,
        isUpload: false,
        isValid: false,
        type: 'person',
      },
    ];
    this.rules = [
      {
        name: 'OTA.CONTRACT_AGREEMENT.BUSINESS',
        key: '0',
        icon: 'sctr-icon-building-03',
      },
      {
        name: 'OTA.CONTRACT_AGREEMENT.PERSONAL',
        key: '1',
        icon: 'sctr-icon-user-01',
      },
    ];
    this.warningText = 'OTA.CONTRACT_AGREEMENT.BUSINESS_REQUIRED';
    this.uploadAttachBusiness = [
      {
        id: uuidv4(),
        title: 'OTA.CONTRACT_AGREEMENT.BUSINESS_LICENSE',
        file: null,
        isUpload: false,
        isValid: false,
        type: 'default',
      },
    ];
    this.uploadAttachPersonal = [
      {
        id: uuidv4(),
        title: 'OTA.CONTRACT_AGREEMENT.BUSINESS_LICENSE',
        file: null,
        isUpload: false,
        isValid: false,
        type: 'default',
      },
    ];
    this.maximumAttachBusiness = 9;
    this.maximumAttachPersonal = 9;
  }

  addClassActiveRule(rule: any, contract: any) {
    return {
      '!text-palette-blue-600': rule === contract.selectedRule,
      [rule.icon]: true,
    };
  }

  handleSelectRuleClick(rule: Rule) {
    this.isCheckBoxDirty = true;
    this.contractAgreementForm.controls['selectedRule'].setValue(rule);
    this.handleTextWarningRule(rule.key);
    this.initSelectRule(rule);
  }

  detectFormChanges() {
    this.contractAgreementForm.valueChanges.subscribe((value) => {
      if (JSON.stringify(value) !== JSON.stringify(this.contract)) {
        this.contract = value;
        this.handleTextWarningRule(this.contract.selectedRule.key);
        this.initSelectRule(this.contract.selectedRule);
      }
    });
  }

  initSelectRule(rule: Rule) {
    switch (rule.key) {
      case '0':
        break;
      case '1':
        for (let index = 0; index < this.initAttachPersonal.length; index++) {
          if (!this.componentsExists(this.initAttachPersonal[index].id)) {
            this.uploadAttachPersonal = [
              this.initAttachPersonal[index],
              ...this.uploadAttachPersonal,
            ];
          }
        }
        break;
    }
  }

  componentsExists(id: string) {
    let foundItem = this.uploadAttachPersonal.find(
      (item: Attachment) => item.id === id
    );
    return foundItem;
  }

  handleTextWarningRule(key: string) {
    this.warningText =
      key === '1'
        ? 'OTA.CONTRACT_AGREEMENT.PERSONAL_REQUIRED'
        : 'OTA.CONTRACT_AGREEMENT.BUSINESS_REQUIRED';
  }

  addUploadCpn(title: string, isUpload: false, isValid: false, type: string) {
    this.isCheckBoxDirty = true;
    let upload = {
      id: uuidv4(),
      title: title,
      file: null,
      isUpload: isUpload,
      isValid: isValid,
      type: 'attach',
    };
    if (type === 'personal') {
      this.uploadAttachPersonal.push(upload);
      this.maximumAttachPersonal -= 1;
    } else if (type === 'business') {
      this.uploadAttachBusiness.push(upload);
      this.maximumAttachBusiness -= 1;
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.contractAgreementForm.controls;
  }

  checkAllValid(): boolean {
    if (
      this.contractAgreementForm.valid &&
      this.phoneNumberChild.childFormControl.valid
    ) {
      return this.checkFilesValid();
    }
    return false;
  }
  checkFilesValid(): boolean {
    let filesToCheck =
      this.contractAgreementForm.value.selectedRule.key === '0'
        ? this.uploadAttachBusiness
        : this.uploadAttachPersonal;
    const valid = filesToCheck.every((item) => item.file !== null);
    return valid;
  }

  createBusinessRole() {
    const businessRole: any = {
      business_license: {
        file_id: this.media[0].id,
        file_extension: this.media[0].extension,
        file_name: this.media[0].original_name,
      },
      addition_attachments: [],
    };

    if (this.uploadAttachBusiness.length > 1) {
      for (let i = 1; i < this.uploadAttachBusiness.length; i++) {
        businessRole.addition_attachments.push({
          file_id: this.media[i].id,
          file_extension: this.media[i].extension,
          file_name: this.media[i].original_name,
        });
      }
    }
    return businessRole;
  }

  createPersonalRole() {
    const personalRole: any = {
      card_front_view: {
        file_id: this.media[0].id,
        file_extension: this.media[0].extension,
        file_name: this.media[0].original_name,
      },
      card_back_view: {
        file_id: this.media[1].id,
        file_extension: this.media[1].extension,
        file_name: this.media[1].original_name,
      },
      business_license: {
        file_id: this.media[2].id,
        file_extension: this.media[2].extension,
        file_name: this.media[2].original_name,
      },
      addition_attachments: [],
    };

    if (this.uploadAttachPersonal.length > 3) {
      for (let i = 3; i < this.uploadAttachPersonal.length; i++) {
        personalRole.addition_attachments.push({
          file_id: this.media[i].id,
          file_extension: this.media[i].extension,
          file_name: this.media[i].original_name,
        });
      }
    }
    return personalRole;
  }

  callGetContractInfo() {
    // this.isLoading = true;
    // const sessionId = localStorage.getItem(environment.SESSION_ID) ?? '';

    // this.agencyPropertyInfoControllerService
    //   .getContractInfoBySessionId(sessionId)
    //   .pipe(
    //     timeout(TIME_OUT_REQUEST),
    //     takeUntil(this.onDestroy$),
    //     catchError((error) => {
    //       return of(null);
    //     })
    //   )
    //   .subscribe((res: any) => {
    //     this.isLoading = false;
    //     if (res) {
    //       if (res.success && res.data) {
    //         console.log(res);
    //         this.renderValueContractForm(res.data);
    //         this.phoneNumber = res.data.phone;
    //         this.renderValueFiles(res.data);
    //       } else {
    //         const translatedText = this.translateService.instant(
    //           'COMMON.API_ERROR_MESSAGE'
    //         );
    //       }
    //     }
    //   });
  }

  renderValueContractForm(data: IContract) {
    this.contractAgreementForm?.patchValue({
      fullName: data.name,
      selectedRule: data.business_role ? this.rules[0] : this.rules[1],
      email: data.email,
      checkboxAgree: [true],
    });
  }

  renderValueFiles(data: IContract) {
    if (data.business_role) {
      if (this.uploadAttachBusiness?.length) {
        this.uploadAttachBusiness[0].file = {
          name: data.business_role.business_license.file_name,
        };
      }
      this.media.push({
        id: data.business_role.business_license.file_id,
        extension: data.business_role.business_license.file_extension,
        original_name: data.business_role.business_license.file_name,
      });

      if (data.business_role.addition_attachments) {
        data.business_role.addition_attachments.forEach((item, index) => {
          this.addUploadCpn(
            'OTA.CONTRACT_AGREEMENT.ADDITION_ATTACHMENT',
            false,
            false,
            'business'
          );
          this.uploadAttachBusiness[1 + index].file = { name: item.file_name };
          this.media.push({
            id: item.file_id,
            extension: item.file_extension,
            original_name: item.file_name,
          });
        });
      }
      this.uploadAttachBusiness.forEach((item) => {
        item.isUpload = true;
      });
    } else if (data.personal_role) {
      if (this.uploadAttachPersonal?.length) {
        this.uploadAttachPersonal[0].file = {
          name: data.personal_role.card_front_view.file_name,
        };
      }
      this.media.push({
        id: data.personal_role.card_front_view.file_id,
        extension: data.personal_role.card_front_view.file_extension,
        original_name: data.personal_role.card_front_view.file_name,
      });

      this.uploadAttachPersonal[1].file = {
        name: data.personal_role.card_back_view.file_name,
      };
      this.media.push({
        id: data.personal_role.card_back_view.file_id,
        extension: data.personal_role.card_back_view.file_extension,
        original_name: data.personal_role.card_back_view.file_name,
      });

      this.uploadAttachPersonal[2].file = {
        name: data.personal_role.business_license.file_name,
      };
      this.media.push({
        id: data.personal_role.business_license.file_id,
        extension: data.personal_role.business_license.file_extension,
        original_name: data.personal_role.business_license.file_name,
      });

      if (data.personal_role.addition_attachments) {
        data.personal_role.addition_attachments.forEach((item, index) => {
          this.addUploadCpn(
            'OTA.CONTRACT_AGREEMENT.ADDITION_ATTACHMENT',
            false,
            false,
            'personal'
          );
          this.uploadAttachPersonal[3 + index].file = { name: item.file_name };
          this.media.push({
            id: item.file_id,
            extension: item.file_extension,
            original_name: item.file_name,
          });
        });
      }
      this.uploadAttachPersonal.forEach((item) => {
        item.isUpload = true;
      });
    }
  }

  // showError(detail: string) {
  //   this.messageService.add({
  //     key: 'FILE_ERROR',
  //     severity: 'error',
  //     detail: detail
  //   });
  // }

  showValidationErrors(form: FormGroup) {
    for (const controlName in form.controls) {
      if (form.controls.hasOwnProperty(controlName)) {
        form.get(controlName)!.markAsTouched();
      }
    }
  }
}
