import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';


export interface Currency {
  code: string;
}

export interface FooterSetting {
  region: any;
  lang: any;
  currency: string;
}

@Injectable({
  providedIn: 'root'
})
export class FooterService {
  private _showFooter: ReplaySubject<Boolean> = new ReplaySubject();
  private showFooter$: Observable<Boolean> = this._showFooter.asObservable();
  private currency: BehaviorSubject<Currency | null> = new BehaviorSubject<Currency | null>({ code: "USD" },);
  private footerSetting: BehaviorSubject<any> = new BehaviorSubject({});

  constructor() { }
  setIsShowFooter(data: boolean) {
    this._showFooter.next(data);
  }
  getIsShowFooter() {
    return this.showFooter$;
  }

  getCurrency(): Observable<Currency | null> {
    return this.currency.asObservable();
  }
  setCurrency(currency: Currency) {
    this.currency.next(currency);
  }

  getFooterSetting(): Observable<FooterSetting> {
    return this.footerSetting.asObservable();
  }
  setFooterSetting(setting: FooterSetting) {
    this.footerSetting.next(setting);
  }

}
