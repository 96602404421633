import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CalendarDashboardService {
  private _calendarValue = new BehaviorSubject<any>({});
  constructor() {}

  setCalendarValue(data: any) {
    this._calendarValue.next(data);
  }

  getCalendarValue() {
    return this._calendarValue.asObservable();
  }
}
