import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';
import { CtaSigninService } from '@modules/hotel-booking/services/cta-signin/cta-signin.service';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/core/services/user/user.service';

@Component({
  selector: 'app-popup-cta',
  templateUrl: './popup-cta.component.html',
  styleUrls: ['./popup-cta.component.scss']
})
export class PopupCtaComponent implements OnInit {
  isShowPopup: boolean = true;
  isShowCTA: boolean| null = null;
  isLogin: boolean | null = false;
  ctaSubscription: Subscription;
  loginSubscription: Subscription;

  constructor(
    private router: Router, 
    private route: ActivatedRoute,
    private userService: UserService,
    private cdr: ChangeDetectorRef,
    private CtaSigninService: CtaSigninService
  ) { }
  ngOnInit(): void {
    this.ctaSubscription = this.CtaSigninService.isShowCTA$.subscribe((isShowCTA) => {
      this.isShowCTA = isShowCTA;
    });
    this.loginSubscription = this.userService.getLoginStatus().subscribe((data) => {
      if (data !== null) {
        this.isLogin = data;
      }
      this.cdr.detectChanges()
    });
    setTimeout(() => {
      if (this.isShowCTA === null) {
        this.isShowCTA = true;
      }
    }, 6000);
  }
  ngOnDestroy(): void {
    this.ctaSubscription.unsubscribe();
    this.loginSubscription.unsubscribe();
  }
  handleClickClose(): void {
    this.isShowPopup = false;
  }
  handleClickSignInOrSignUp(): void {
    window.location.href = environment.SOCIAL_APP_URL + '/login?r='+ encodeURIComponent(window.location.href);
  }

}
