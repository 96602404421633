<div class="relative show-image-modal bg-black h-full">
    <div class="absolute w-full px-[32px] top-[40px] flex justify-between">
        <div class="w-1/3"></div>
        <div *ngIf="image_urls.length>0" class="text-[18px] text-center w-1/3 text-white font-normal">{{activeIndex +
            1}}/{{all_image_urls.length}}</div>
        <div class="w-1/3 flex justify-end">
            <div class="cursor-pointer  max-h-[56px] max-w-[36px] min-w-[56px] z-[10000] text-white"
                (click)="onclose()">
                <i class="sctr-icon-x-close text-[32px] text-white font-normal"></i>
            </div>
        </div>
    </div>
    <div class="card md:flex md:justify-content-center pt-[100px]">
        <div class="  absolute w-full justify-between item-navigator px-[32px] items-center z-10 flex text-[44px] pointer-events-none">
            <div [ngClass]="activeIndex == 0 ? 'text-palette-gray-400 opacity-40 cursor-default': 'text-white cursor-pointer'"
                class="sctr-icon-chevron-left pointer-events-auto" (click)="prev()" styleClass="galleria-prev"></div>
            <div [ngClass]="activeIndex == image_urls.length - 1 ? 'text-palette-gray-400 opacity-40 cursor-default': 'text-white cursor-pointer'"
                class="sctr-icon-chevron-right pointer-events-auto" (click)="next()" styleClass="galleria-next"></div>
        </div>
        <div>
            <div class="image-item flex justify-center items-center content-center image-box overflow-x-scroll" (wheel)="onMouseWheelImage($event)">
                <!-- <img *ngIf="!isCheckImageId" class="max-w-[calc(100vw-100px)] object-center object-contain h-full " src="{{image_urls[activeIndex]}}" (error)="replaceWithDefaultImage($event)" alt="">
                <img *ngIf="isCheckImageId" class="max-w-[calc(100vw-100px)] object-center object-contain h-full " src="{{baseImageId}}/{{image_urls[activeIndex]}}.webp" (error)="replaceWithDefaultImage($event)" alt=""> -->
                <ng-container *ngIf="multiMedia; else onlyImage">
                    <ng-container *ngIf="isFileVideo(image_urls[activeIndex].type); else image">
                        <video class="max-w-[850px] max-h-[850px]"
                            [attr.src]="baseStreamVideo + '/' + image_urls[activeIndex].id" controls>
                        </video>    
                    </ng-container>
                    <ng-template #image>
                        <img class="max-w-[calc(100vw-100px)] object-center object-contain h-full" 
                            src="{{baseImageId}}/{{image_urls[activeIndex].id}}" 
                            (error)="replaceWithDefaultImage($event)" />
                    </ng-template>
                </ng-container>
                <ng-template #onlyImage>
                    <img *ngIf="!isCheckImageId" class="max-w-[calc(100vw-100px)] object-center object-contain h-full " src="{{image_urls[activeIndex]}}" (error)="replaceWithDefaultImage($event)" alt="">
                    <img *ngIf="isCheckImageId" class="max-w-[calc(100vw-100px)] object-center object-contain h-full " src="{{baseImageId}}/{{image_urls[activeIndex]}}.webp" (error)="replaceWithDefaultImage($event)" alt="">
                </ng-template>
            </div>
            <div class="flex justify-center">
                <div class="max-w-[100vw] flex space-x-1 mt-[90px] overflow-hidden">
                    <div *ngFor="let item of image_urls; index as idx">
                        <div id="image-{{idx}}" (click)="handleImageScroll(idx)" class="rounded-sm cursor-pointer"
                            [ngClass]="idx == activeIndex? 'p-galleria-thumbnail-item-current':''">
                            <!-- <img *ngIf="!isCheckImageId" class="image-thumbnail-item rounded-sm" [src]="item" (error)="replaceWithDefaultImage($event)" />
                            <img *ngIf="isCheckImageId" class="image-thumbnail-item rounded-sm" [src]="baseImageId + '/' + item + '.webp'" (error)="replaceWithDefaultImage($event)" /> -->
                            <ng-container *ngIf="multiMedia; else onlyImages">
                                <ng-container *ngIf="isFileVideo(item.type); else image">
                                    <img class="image-thumbnail-item rounded-sm object-cover border-white border" src="assets/images/send-review/video_default_thumb.jpg"> 
                                </ng-container>
                                <ng-template #image>
                                    <img class="image-thumbnail-item rounded-sm"
                                        src="{{baseImageId}}/{{item.id}}" 
                                        (error)="replaceWithDefaultImage($event)" />
                                </ng-template>
                            </ng-container>
                            <ng-template #onlyImages>
                                <img *ngIf="!isCheckImageId" class="image-thumbnail-item rounded-sm" [src]="item" (error)="replaceWithDefaultImage($event)" />
                                <img *ngIf="isCheckImageId" class="image-thumbnail-item rounded-sm" [src]="baseImageId + '/' + item + '.webp'" (error)="replaceWithDefaultImage($event)" />
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>